<template>
  <div class="binds-card" :class="[$bindsActiveTheme, cardClasses]">
    <slot/>
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsCard',
  props: {
    bindsWithHover: Boolean
  },
  data: () => ({
    BindsCard: {
      expand: false
    }
  }),
  provide () {
    return {
      BindsCard: this.BindsCard
    }
  },
  computed: {
    cardClasses () {
      return {
        'binds-with-hover': this.bindsWithHover,
        'binds-expand-active': this.BindsCard.expand
      }
    }
  }
})
</script>

<style lang="scss" scoped>
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";
  @import "./base";

  $binds-card-radius: 2px;

  .binds-card {
    @include binds-card;
    &.binds-with-hover {
      cursor: pointer;
      transition: background-color .3s $binds-transition-default-timing,
                  box-shadow .4s $binds-transition-stand-timing;
      will-change: background-color, box-shadow;
      &:hover {
        z-index: 2;
        @include binds-elevation(8);
      }
    }
    &.binds-expand-active {
      .binds-card-expand-trigger.binds-icon-button {
        transform: rotate(180deg);
      }
    }
    .binds-subhead,
    .binds-title,
    .binds-subheading {
      margin: 0;
      font-weight: 400;
    }
    .binds-subhead {
      opacity: .54;
      font-size: 14px;
      letter-spacing: .01em;
      line-height: 20px;
      + .binds-title {
        margin-top: 4px;
      }
    }
    .binds-title {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
</style>
