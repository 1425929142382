<template>
  <div>
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">
      <num5or10 :data='data' v-if="!~['singleOther', 'single', 'multiple', 'multipleOther'].indexOf(data.question.ui)"></num5or10>
      <div class="content-single  animated-faster zoomIn" v-if="data.question.ui === 'single' ||  data.question.ui === 'singleOther'">
        <ul>
          <li v-for="(i,index) in data.question.options"  v-bind:key="index">
            <div class="item-single hvr-grow ">
               <binds-radio
               v-model="singleOption"
               :value="i.label"
               v-on:change="postResponse(i.label, null)"
               class="binds-primary">
               {{i.label}}
               </binds-radio>
            </div>
          </li>
          <li>
            <div class="item-single hvr-grow ">
              <binds-radio
                v-model="otherModel"
                v-if="data.question.ui === 'singleOther'"
                :value="data.question.otherLabel"
                v-on:change="otherLabel = true"
                class="binds-primary">
                {{data.question.otherLabel}}
              </binds-radio>
            </div>
          </li>
        </ul>
        <div  v-if="otherLabel">
          <div class="content-input-text" v-if="data.question.mask !== null">
            <form v-on:submit.prevent="postResponse(data.question.otherLabel, message)">
              <binds-field>
                <label>{{data.question.placeholder}}</label>
                <binds-input v-model="message" required></binds-input>
              </binds-field>
              <binds-button
              v-if="!data.question.required || (data.question.required && message)"
                class="binds-raised binds-primary"
                type="submit"
                :style="{'background-color': data.background, 'color': data.texts}"
              >
              {{data.question.nextLabel}}
              </binds-button>
            </form>
          </div>
        </div>
      </div>

      <div class="content-single content-multiple animated-faster zoomIn"  v-if="data.question.ui === 'multiple' || data.question.ui === 'multipleOther'">
        <ul>
          <li v-for="(i,index) in data.question.options" v-bind:key="index">
            <div class="item-single item-multiple hvr-grow">
                <input
                type="checkbox"
                :id="i._id"
                v-model="multiples"
                :value="i.label"
                class="custom-checkbox-widget"
                v-on:change="check()" >
                <label :for="i._id">{{i.label}}</label>
            </div>
          </li>
          <li v-if="data.question.ui === 'multipleOther'">
            <div class="item-single item-multiple hvr-grow">
              <input
                v-model="otherModel"
                :id="data.question._id"
                :value="data.question.otherLabel"
                type="checkbox"
                v-on:change="otherLabel = !otherLabel, buttonEnabled = (!otherLabel && multiples.length > 0) ? true: false, check()"
                class="custom-checkbox-widget">
                <label :for="data.question._id">{{data.question.otherLabel}}</label>
            </div>
          </li>
          <div  v-if="otherLabel">
          <div class="content-input-text animated-faster zoomIn" v-if="data.question.mask !== null">
            <form v-on:submit.prevent="postResponse(multiples, message)">
              <binds-field>
                <label>{{data.question.placeholder}}</label>
                <binds-input v-model="message" required></binds-input>
              </binds-field>
              <binds-button
                v-if="buttonEnabled"
                class="animated-faster zoomIn binds-raised binds-primary"
                type="submit"
                :style="{'background-color': data.background, 'color': data.texts}"
              >
              {{data.question.nextLabel}}
              </binds-button>
            </form>
          </div>
        </div>
        </ul>
      </div>
      <div class="submit-options-matrix animated-faster zoomIn"  v-if="buttonEnabled && !otherLabel">
        <binds-button
          class="binds-raised binds-primary"
          :style="{'background-color': data.background, 'color': data.texts}"
          v-on:click="postResponse(multiples)">{{data.question.nextLabel}}
        </binds-button>
      </div>
    </binds-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsContent, BindsTabs, BindsButton, BindsCard, BindsRadio, BindsCheckbox } from '../bindsMaterial/components'
import num5or10 from '../num5or10.vue'
import { widgetService } from '@/_services'

Vue.use(BindsContent)
Vue.use(BindsTabs)
Vue.use(BindsButton)
Vue.use(BindsCard)
Vue.use(BindsRadio)
Vue.use(BindsCheckbox)

export default {
  props: ['data'],
  components: { num5or10 },
  data () {
    return {
      singleOption: '',
      otherModel: '',
      multiples: [],
      buttonEnabled: true,
      otherLabel: false,
      message: ''
    }
  },
  methods: {
    async postResponse (item, text) {
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let send = { value: item, text: '' }
        if (this.otherLabel && !this.singleOption) {
          send.text = text
          send.isOther = true
          if (this.data.question.ui === 'multipleOther') {
            const itemsCheck = JSON.parse(JSON.stringify(item))
            itemsCheck.push(this.data.question.otherLabel)
            send.value = itemsCheck
          }
        }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, send)
        this.$root.$emit('questionUpdated', questionUpdated, item)
      }
    },
    check () {
      const minRange = this.data.question.minLength
      const maxRange = this.data.question.maxLength
      const total = this.otherLabel ? this.multiples.length + 1 : this.multiples.length
      let passOnMinRule = true
      let passOnMaxRule = true
      if (this.data.question.limitAnswer) {
        if ([undefined, null].indexOf(minRange) < 0) {
          if (total >= minRange) {
            passOnMinRule = true
          }
          if (total < minRange) {
            passOnMinRule = false
          }
        }
        if (passOnMinRule && [undefined, null].indexOf(maxRange) < 0) {
          if (total <= maxRange) {
            passOnMaxRule = true
          }
          if (total > maxRange) {
            passOnMaxRule = false
          }
        }
        this.buttonEnabled = passOnMinRule && passOnMaxRule
      } else {
        if (this.data.question.required && total > 0) {
          this.buttonEnabled = true
        }
      }
      if (this.data.question.required && total <= 0) {
        this.buttonEnabled = false
      }
    }
  },
  created () {
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
    this.$root.$emit('setLoading', false)
  }
}
</script>
<style>
.content-single ul{
  margin: 0;
  padding: 0;
}
.content-single{
  text-align: center;
  max-height: 340px;
  overflow: auto;
}
.item-single{
  cursor: pointer;
  display: block;
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  text-align: left;
}
.item-multiple {
  margin-bottom: 20px;
  margin-left: 14px;
  text-align: left;
}
.content-multiple {
  padding:20px 0;
}

.item-multiple input[type="checkbox"] {
    opacity: 0;
}
.binds-button {
  min-width: 27px;
}
.item-multiple label {
    position: relative;
    display: inline-block;
    padding-left: 22px;
    max-width: 90%;
    cursor: pointer;
}

.item-multiple label::before,
.item-multiple label::after {
    position: absolute;
    content: "";
    display: inline-block;
}

.item-multiple label::before{
    height: 16px;
    width: 16px;
    border: 1px solid;
    left: 0px;
    top: 3px;
}
/*Checkmark of the fake checkbox*/
.item-multiple label::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
      -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari 3-8 */
    transform: rotate(-45deg);
    left: 4px;
    top: 7px;
}
/*Hide the checkmark by default*/
.item-multiple input[type="checkbox"] + label::after {
    content: none;
}
/*Unhide on the checked state*/
.item-multiple input[type="checkbox"]:checked + label::after {
    content: "";
}

</style>
