<template>
  <div class="binds-speed-dial-content">
    <slot />
  </div>
</template>

<script>
function getChildIndex (direction, index, count) {
  if (direction === 'top') {
    return count - index - 1
  }

  return index
}

export default {
  name: 'BindsSpeedDialContent',
  inject: ['BindsSpeedDial'],
  methods: {
    setChildrenIndexes () {
      this.$nextTick().then(() => {
        const countChild = this.$children.length

        this.$children.forEach((child, index) => {
          if (child._vnode.tag === 'button') {
            const childIndex = getChildIndex(this.BindsSpeedDial.direction, index, countChild)

            child.$el.setAttribute('binds-button-index', childIndex)
            child.$el.classList.add('binds-raised')
          }
        })
      })
    }
  },
  mounted () {
    this.setChildrenIndexes()
  },
  updated () {
    this.setChildrenIndexes()
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-speed-dial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    transition: .3s $binds-transition-default-timing;
  }
</style>
