<template>
  <transition name="binds-chip" appear>
    <div class="binds-chip" :class="[$bindsActiveTheme, chipClasses]" tabindex="0" v-on="$listeners">
      <binds-ripple :binds-disabled="bindsDisabled" v-if="bindsClickable || !bindsRipple">
        <slot />
      </binds-ripple>

      <slot v-else />

      <transition name="binds-input-action" appear>
        <binds-button tabindex="-1" class="binds-icon-button binds-dense binds-input-action binds-clear" v-if="bindsDeletable" @click="$emit('binds-delete', $event)">
          <binds-clear-icon />
        </binds-button>
      </transition>
    </div>
  </transition>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsFocused from '../../core/mixins/BindsFocused/BindsFocused'
import BindsRipple from '../../core/mixins/BindsRipple/BindsRipple'
import BindsClearIcon from '../../core/icons/BindsClearIcon'
import BindsButton from '../BindsButton/BindsButton'

export default new BindsComponent({
  name: 'BindsChip',
  components: {
    BindsButton,
    BindsClearIcon
  },
  mixins: [
    BindsFocused,
    BindsRipple
  ],
  props: {
    bindsDisabled: Boolean,
    bindsDeletable: Boolean,
    bindsClickable: Boolean,
    bindsDuplicated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chipClasses () {
      return {
        'binds-disabled': this.bindsDisabled,
        'binds-deletable': this.bindsDeletable,
        'binds-clickable': this.bindsClickable,
        'binds-focused': this.bindsHasFocus,
        'binds-duplicated': this.bindsDuplicated
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";

  $binds-chips-height: 32px;

  .binds-chip {
    height: $binds-chips-height;
    padding: 0 12px;
    display: inline-block;
    cursor: default;
    border-radius: $binds-chips-height;
    transition: .3s $binds-transition-stand-timing;
    transition-property: background-color, color, opacity, transform, box-shadow;
    will-change: background-color, color, opacity, transform, box-shadow;
    font-size: 13px;
    line-height: $binds-chips-height;
    vertical-align: middle;
    white-space: nowrap;

    &:focus {
      outline: none;
    }

    &.binds-chip-enter-active,
    &.binds-chip-leave-active {
      opacity: 0;
      transform: transformZ(0) scale(.8);
    }

    &.binds-chip-enter-to {
      opacity: 1;
      transform: transformZ(0) scale(1);
    }

    &.binds-clickable,
    &.binds-deletable {
      &:not(.binds-disabled):active {
        @include binds-elevation(3);
      }
    }

    &.binds-focused {
      @include binds-elevation(3);
    }

    &.binds-clickable {
      padding: 0;
      cursor: pointer;

      > .binds-ripple {
        padding: 0 12px;
      }
    }

    &.binds-deletable {
      padding-right: 32px;
      position: relative;

      &.binds-clickable {
        padding-right: 0;

        > .binds-ripple {
          padding-right: 32px;
        }
      }
    }

    &.binds-disabled {
      cursor: default;
    }

    + .binds-chip {
      margin-left: 4px;
    }

    .binds-button.binds-input-action {
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 7px;
      z-index: 11;
      transform: translate3D(0, -50%, 0);
      transition-duration: .3s;
      transition-timing-function: $binds-transition-stand-timing;
      font-size: 18px;

      .binds-ripple {
        padding: 0;
      }

      .binds-button-content {
        height: 14px;
      }

      .binds-icon {
        width: 14px;
        min-width: 14px;
        height: 14px;
        font-size: 14px !important;
        vertical-align: top;

        svg {
          transition-duration: .3s;
          transition-timing-function: $binds-transition-stand-timing;
        }
      }
    }
  }
</style>
