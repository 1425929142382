<template>
  <binds-ripple :binds-disabled="!bindsRipple || disabled" :binds-event-trigger="false" :binds-active="bindsRippleActive" @update:bindsActive="active => $emit('update:bindsRippleActive', active)">
    <div class="binds-button-content">
      <slot />
    </div>
  </binds-ripple>
</template>

<script>
import BindsRipple from '../BindsRipple/BindsRipple'

export default {
  name: 'BindsButtonContent',
  components: {
    BindsRipple
  },
  props: {
    bindsRipple: Boolean,
    bindsRippleActive: null,
    disabled: Boolean
  }
}
</script>

<style lang="scss">
  .binds-button-content {
    position: relative;
    z-index: 2;
  }
</style>
