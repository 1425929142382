<template>
  <span class="binds-dialog-title binds-title">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BindsDialogTitle'
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-dialog-title {
    margin-bottom: 20px;
    padding: 24px 24px 0;
  }
</style>
