import material from '../../material.js'
import BindsCard from './BindsCard'
import BindsCardArea from './BindsCardArea/BindsCardArea'
import BindsCardHeader from './BindsCardHeader/BindsCardHeader'
import BindsCardHeaderText from './BindsCardHeader/BindsCardHeaderText'
import BindsCardMedia from './BindsCardMedia/BindsCardMedia'
import BindsCardMediaActions from './BindsCardMedia/BindsCardMediaActions'
import BindsCardMediaCover from './BindsCardMedia/BindsCardMediaCover'
import BindsCardContent from './BindsCardContent/BindsCardContent'
import BindsCardExpand from './BindsCardExpand/BindsCardExpand'
import BindsCardExpandTrigger from './BindsCardExpand/BindsCardExpandTrigger'
import BindsCardExpandContent from './BindsCardExpand/BindsCardExpandContent'
import BindsCardActions from './BindsCardActions/BindsCardActions'

export default Vue => {
  material(Vue)
  Vue.component(BindsCard.name, BindsCard)
  Vue.component(BindsCardArea.name, BindsCardArea)
  Vue.component(BindsCardHeader.name, BindsCardHeader)
  Vue.component(BindsCardHeaderText.name, BindsCardHeaderText)
  Vue.component(BindsCardMedia.name, BindsCardMedia)
  Vue.component(BindsCardMediaActions.name, BindsCardMediaActions)
  Vue.component(BindsCardMediaCover.name, BindsCardMediaCover)
  Vue.component(BindsCardContent.name, BindsCardContent)
  Vue.component(BindsCardExpand.name, BindsCardExpand)
  Vue.component(BindsCardExpandTrigger.name, BindsCardExpandTrigger)
  Vue.component(BindsCardExpandContent.name, BindsCardExpandContent)
  Vue.component(BindsCardActions.name, BindsCardActions)
}
