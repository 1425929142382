<template>
  <binds-toolbar class="binds-table-toolbar binds-transparent" :binds-elevation="0">
    <slot />
  </binds-toolbar>
</template>

<script>
  import BindsToolbar from '../BindsToolbar/BindsToolbar'

  export default {
    name: 'BindsTableToolbar',
    components: {
      BindsToolbar
    },
    inject: ['BindsTable']
  }
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-table-toolbar {
    padding-left: 24px;

    .binds-title {
      flex: 1;
      font-size: 20px;
    }
  }
</style>
