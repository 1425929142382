<template>
  <div class="binds-badge-content" v-if="hasDefaultSlot">
    <slot />
    <binds-badge-standalone :class="badgeClasses" :style="styles">
      <div>
        {{ bindsContent }}
      </div>
    </binds-badge-standalone>
  </div>
  <binds-badge-standalone :class="badgeClasses" :style="styles" v-else>
    {{ bindsContent }}
  </binds-badge-standalone>
</template>

<script>
  import BindsComponent from '../../core/BindsComponent'
  import BindsPropValidator from '../../core/utils/BindsPropValidator'
  import BindsBadgeStandalone from './BindsBadgeStandalone'

  export default new BindsComponent({
    name: 'BindsBadge',
    components: {
      BindsBadgeStandalone
    },
    props: {
      bindsContent: [String, Number],
      bindsPosition: {
        type: String,
        default: 'top',
        ...BindsPropValidator('binds-position', [
          'top',
          'bottom'
        ])
      },
      bindsDense: Boolean
    },
    computed: {
      hasDefaultSlot () {
        return !!this.$slots.default
      },
      badgeClasses () {
        const staticClass = this.getStaticClass()
        const dynamicClass = this.$vnode.data.class

        return {
          ['binds-position-' + this.bindsPosition]: true,
          'binds-dense': this.bindsDense,
          ...staticClass,
          ...dynamicClass
        }
      },
      styles () {
        const staticStyle = this.$vnode.data.staticStyle
        const style = this.$vnode.data.style
        return {
          ...staticStyle,
          ...style
        }
      }
    },
    methods: {
      getStaticClass () {
        const staticClass = this.$vnode.data.staticClass

        function filterClasses () {
          return staticClass.split(' ').filter(val => val).reduce((result, key) => {
            result[key] = true
            return result
          }, {})
        }

        return staticClass ? filterClasses() : {}
      }
    }
  })
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-badge-content {
    position: relative;
    display: inline-block;
    .binds-position {
      &-top {
        top: -4px;
      }
      &-bottom {
        bottom: -4px;
      }
    }
  }
</style>
