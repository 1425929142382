<template>
  <binds-portal v-if="bindsPersistent && bindsDuration !== Infinity">
    <keep-alive>
      <binds-snackbar-content :binds-classes="[snackbarClasses, $bindsActiveTheme]" v-if="bindsActive">
        <slot />
      </binds-snackbar-content>
    </keep-alive>
  </binds-portal>

  <binds-portal v-else>
    <binds-snackbar-content :binds-classes="[snackbarClasses, $bindsActiveTheme]" v-if="bindsActive">
      <slot />
    </binds-snackbar-content>
  </binds-portal>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsPropValidator from '../../core/utils/BindsPropValidator'
import BindsPortal from '../BindsPortal/BindsPortal'
import BindsSnackbarContent from './BindsSnackbarContent'
import { createSnackbar, destroySnackbar } from './BindsSnackbarQueue'

export default new BindsComponent({
  name: 'BindsSnackbar',
  components: {
    BindsPortal,
    BindsSnackbarContent
  },
  props: {
    bindsActive: Boolean,
    bindsPersistent: Boolean,
    bindsDuration: {
      type: Number,
      default: 4000
    },
    bindsPosition: {
      type: String,
      default: 'center',
      ...BindsPropValidator('binds-position', ['center', 'left'])
    }
  },
  computed: {
    snackbarClasses () {
      return {
        ['binds-position-' + this.bindsPosition]: true
      }
    }
  },
  watch: {
    bindsActive (isActive) {
      if (isActive) {
        createSnackbar(this.bindsDuration, this.bindsPersistent, this).then(() => {
          this.$emit('update:bindsActive', false)
          this.$emit('binds-opened')
        })
      } else {
        destroySnackbar()
        this.$emit('binds-closed')
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsLayout/mixins";
  @import "../BindsElevation/mixins";

  .binds-snackbar {
    @include binds-elevation(6);
    min-width: 288px;
    max-width: 568px;
    min-height: 48px;
    max-height: 80px;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    border-radius: 2px;
    transition: .4s $binds-transition-default-timing;
    will-change: background-color, color, opacity, transform;

    &.binds-position-center {
      margin: 0 auto;
      right: 0;
      bottom: 0;
      left: 0;

      &.binds-snackbar-enter,
      &.binds-snackbar-leave-active {
        transform: translate3D(0, calc(100% + 8px), 0);
      }
    }

    &.binds-position-left {
      bottom: 24px;
      left: 24px;

      &.binds-snackbar-enter,
      &.binds-snackbar-leave-active {
        transform: translate3D(0, calc(100% + 32px), 0);
      }
    }

    @include binds-layout-xsmall {
      left: 0;
      transform: none;
      border-radius: 0;
    }
  }

  .binds-snackbar-enter,
  .binds-snackbar-leave-active {
    opacity: 0;

    .binds-snackbar-content {
      opacity: 0;
    }
  }

  .binds-snackbar-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: opacity .38s $binds-transition-drop-timing;

    .binds-button {
      min-width: 0;
      margin: -8px -8px -8px 36px;

      @include binds-layout-xsmall {
        margin-left: 12px;
      }

      + .binds-button {
        margin-left: 16px;
      }
    }
  }
</style>
