<template>
  <div class="matrix-component">
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric,
      }" style="border-style: solid; border-width: 4px;" class="responsive-widget">

      <binds-card-content class="scroll-height ">
        <div v-for="(item,index) in data.question.subjects" v-bind:key="index" class="matrix-options animated-faster zoomIn">
            <span class="label-option">{{item.label}}</span>
            <ul>
                <li  v-for="(i,key) in data.question.options" v-bind:key="key">
                <span>{{i.label}}</span>
                <binds-radio
                    :style="{'border-color': data.texts}"
                    v-model="item.rating"
                    :value="i.rating"
                    class="binds-primary"
                    v-on:change="dataMatrix(item.rating, item._id, index)">
                </binds-radio>
                </li>
            </ul>

        </div>
      </binds-card-content>
    <div class="submit-options-matrix">
       <binds-button
       :binds-ripple="false"
       class="binds-raised binds-primary"
       :class="{'disableButton' : buttonDisabled === true}"
       :style="{'background-color': data.background, 'color': data.texts}"
       :disabled="buttonDisabled"
       v-on:click="postResponse()">{{data.question.nextLabel}}</binds-button>
    </div>
    </binds-card>

  </div>
</template>

<script>
import Vue from 'vue'
import { BindsRadio, BindsCard, BindsContent, BindsButton } from '../bindsMaterial/components'
import { widgetService } from '@/_services'

Vue.use(BindsRadio)
Vue.use(BindsCard)
Vue.use(BindsContent)
Vue.use(BindsButton)

export default {
  props: ['data'],
  data () {
    return {
      matrix: [],
      send: [],
      buttonDisabled: false
    }
  },
  methods: {
    dataMatrix (rating, id, index) {
      this.matrix[index]['rating'] = rating
      this.matrix[index]['_id'] = id

      let dataSend = this.matrix.filter(function (item) {
        return item.rating !== undefined
      })
      if (dataSend.length === this.data.question.subjects.length) {
        this.buttonDisabled = false
      }
      this.send = dataSend
    },
    async postResponse () {
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, { 'value': this.send })
        this.$root.$emit('questionUpdated', questionUpdated)
      }
    }
  },
  mounted () {
    this.matrix = this.data.question.subjects
    if (this.data.question.required === true) {
      this.buttonDisabled = true
    }
    this.$root.$emit('setLoading', false)
  }
}
</script>

<style>
li{list-style: none;}
.disableButton{opacity: 0.6;}
.matrix-component .binds-radio{
    margin:0;
    text-align: center;
}
.matrix-component .label-option{
    margin-right: 15px;
    display: inline-block;
    vertical-align: super;
    font-size: 16px;
    margin-left: 10px;
}
.matrix-component .binds-radio .binds-radio-label{
    padding-left: 0;
}
.matrix-options{
    border-bottom: 2px solid #c1c1c1;
    margin-bottom: 10px;
    height: auto;
}
.matrix-options ul{
    padding: 0;
    margin-top: 0;
    text-align: center;
}
.matrix-options ul li{
    display: inline-block;
    text-align: center;
    width: 9%;
}
.matrix-options ul li span{
    display: block;
    font-size: 12px;
}
.matrix-component .binds-radio .binds-radio-container:before{
    width: 27px;
    height: 27px;
}
.submit-options-matrix{
    text-align: right;
}
.scroll-height{
    max-height: 400px;
    overflow-y: auto;
}
</style>
