import './base/index.scss'
import BindsReactive from './core/utils/BindsReactive'
import BindsTheme from './core/BindsTheme'

const init = () => {
  let material = new BindsReactive({
    ripple: true,
    theming: {},
    locale: {
      startYear: 1900,
      endYear: 2099,
      dateFormat: 'yyyy-MM-dd',
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      shorterDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Ju', 'Ju', 'A', 'Se', 'O', 'N', 'D'],
      firstDayOfAWeek: 0
    },
    router: {
      linkActiveClass: 'router-link-active'
    }
  })

  Object.defineProperties(material.theming, {
    metaColors: {
      get: () => BindsTheme.metaColors,
      set (metaColors) {
        BindsTheme.metaColors = metaColors
      }
    },
    theme: {
      get: () => BindsTheme.theme,
      set (theme) {
        BindsTheme.theme = theme
      }
    },
    enabled: {
      get: () => BindsTheme.enabled,
      set (enabled) {
        BindsTheme.enabled = enabled
      }
    }
  })

  return material
}

export default Vue => {
  if (!Vue.material) {
    Vue.material = init()
    Vue.prototype.$material = Vue.material
  }
}
