<template>
  <binds-tag-switcher :binds-tag="contentTag" class="binds-table">
    <slot name="binds-table-toolbar" />

    <keep-alive>
      <binds-table-alternate-header v-if="$scopedSlots['binds-table-alternate-header'] && selectedCount">
        <slot name="binds-table-alternate-header" :count="selectedCount" />
      </binds-table-alternate-header>
    </keep-alive>

    <div class="binds-table-fixed-header" :class="headerClasses" :style="headerStyles" v-if="bindsFixedHeader">
      <div class="binds-table-fixed-header-container" ref="fixedHeaderContainer" @scroll="setHeaderScroll">
        <table :style="fixedHeaderTableStyles">
          <binds-table-thead />
        </table>
      </div>
    </div>

    <binds-content class="binds-table-content binds-scrollbar" :class="contentClasses" :style="contentStyles" @scroll="setScroll">
      <table ref="contentTable">
        <binds-table-thead :class="headerClasses" v-if="!bindsFixedHeader && $scopedSlots['binds-table-row']" />

        <tbody v-if="!$scopedSlots['binds-table-row']">
          <slot />
        </tbody>

        <tbody v-else-if="value.length">
          <binds-table-row-ghost
            v-for="(item, index) in value"
            :key="getRowId(item, bindsModelId)"
            :binds-id="getRowId(item, bindsModelId)"
            :binds-index="index"
            :binds-item="item">
            <slot name="binds-table-row" :item="item, index"/>
          </binds-table-row-ghost>
        </tbody>

        <tbody v-else-if="$scopedSlots['binds-table-empty-state']">
          <tr>
            <td :colspan="headerCount">
              <slot name="binds-table-empty-state" />
            </td>
          </tr>
        </tbody>
      </table>

      <slot name="binds-table-pagination" />
    </binds-content>

    <slot v-if="!hasValue && $scopedSlots['binds-table-row']" />
  </binds-tag-switcher>
</template>

<script>
import raf from 'raf'

import BindsTagSwitcher from '../BindsTagSwitcher/BindsTagSwitcher'
import BindsUuid from '../../core/utils/BindsUuid'
import BindsPropValidator from '../../core/utils/BindsPropValidator'
import BindsTableThead from './BindsTableThead'
import BindsTableAlternateHeader from './BindsTableAlternateHeader'
import BindsTableRow from './BindsTableRow'
import BindsTableRowGhost from './BindsTableRowGhost'
import BindsTableCellSelection from './BindsTableCellSelection'
import BindsResizeObserver from '../../core/utils/BindsResizeObserver'

const getObjectAttribute = (object, key) => {
  let value = object

  for (const attribute of key.split('.')) {
    value = value[attribute]
  }

  return value
}

export default {
  name: 'BindsTable',
  components: {
    BindsTagSwitcher,
    BindsTableAlternateHeader,
    BindsTableThead,
    BindsTableRow,
    BindsTableRowGhost,
    BindsTableCellSelection
  },
  props: {
    value: [Array, Object],
    bindsModelId: {
      type: String,
      default: 'id'
    },
    bindsCard: Boolean,
    bindsFixedHeader: Boolean,
    bindsHeight: {
      type: [Number, String],
      default: 400
    },
    bindsSort: String,
    bindsSortOrder: {
      type: String,
      default: 'asc',
      ...BindsPropValidator('binds-sort-order', ['asc', 'desc'])
    },
    bindsSortFn: {
      type: Function,
      default (value) {
        return value.sort((a, b) => {
          const sortBy = this.BindsTable.sort
          const aAttr = getObjectAttribute(a, sortBy)
          const bAttr = getObjectAttribute(b, sortBy)
          const isAsc = this.BindsTable.sortOrder === 'asc'
          let isNumber = typeof aAttr === 'number'

          if (!aAttr) {
            return 1
            }

          if (!bAttr) {
            return -1
          }

          if (isNumber) {
            return isAsc ? (aAttr - bAttr) : (bAttr - aAttr)
          }

          return isAsc
              ? aAttr.localeCompare(bAttr)
              : bAttr.localeCompare(aAttr)
        })
      }
    },
    bindsSelectedValue: {
      type: [Array, Object]
    }
  },
  data () {
    return {
      windowResizeObserver: null,
      fixedHeaderTableWidth: 0,
      fixedHeaderPadding: 0,
      hasContentScroll: false,
      BindsTable: {
        items: {},
        sort: null,
        sortOrder: null,
        singleSelection: null,
        selectedItems: [],
        selectable: [],
        fixedHeader: null,
        contentPadding: null,
        contentEl: null,
        // computed
        hasValue: this.hasValue,
        // methods
        emitEvent: this.emitEvent,
        sortTable: this.sortTable,
        manageItemSelection: this.manageItemSelection,
        getModel: this.getModel,
        getModelItem: this.getModelItem,
        selectingMode: null
      },
      itemsUuidMap: new WeakMap()
    }
  },
  computed: {
    contentTag () {
      if (this.bindsCard) {
        return 'binds-card'
      }

      return 'binds-content'
    },
    headerCount () {
      return Object.keys(this.BindsTable.items).length
    },
    selectedCount () {
      return this.BindsTable.selectedItems.length
    },
    headerStyles () {
      if (this.bindsFixedHeader) {
        return `padding-right: ${this.fixedHeaderPadding}px`
      }
    },
    hasValue () {
      return this.value && this.value.length !== 0
    },
    headerClasses () {
      if ((this.bindsFixedHeader && this.hasContentScroll) || !this.hasValue) {
        return 'binds-table-fixed-header-active'
      }
    },
    contentStyles () {
      if (this.bindsFixedHeader) {
        const height = typeof this.bindsHeight === 'number'
          ? `${this.bindsHeight}px`
          : this.bindsHeight
        return `height: ${height};max-height: ${height}`
      }
    },
    contentClasses () {
      if (this.bindsFixedHeader && this.value.length === 0) {
        return `binds-table-empty`
      }
    },
    fixedHeaderTableStyles () {
      return {
        width: this.fixedHeaderTableWidth + 'px'
      }
    }
  },
  provide () {
    const BindsTable = this.BindsTable

    return { BindsTable }
  },
  watch: {
    bindsSort: {
      immediate: true,
      handler () {
        this.BindsTable.sort = this.bindsSort
      }
    },
    bindsSortOrder: {
      immediate: true,
      handler () {
        this.BindsTable.sortOrder = this.bindsSortOrder
      }
    },
    bindsFixedHeader: {
      immediate: true,
      handler () {
        this.BindsTable.fixedHeader = this.bindsFixedHeader
      }
    },
    hasValue: {
      immediate: true,
      handler () {
        this.BindsTable.hasValue = this.hasValue
      }
    },
    'BindsTable.selectedItems' (val, old) {
      let changed = (() => {
        let isValEmpty = this.isEmpty(val)
        let isOldEmpty = this.isEmpty(old)
        let hasValues = isValEmpty && isOldEmpty

        if (hasValues) {
          return false
        } else if (!hasValues) {
          return (val.length !== old.length) ? true : !val.every((item, index) => item == old[index])
        }

        return true
      })()

      if (changed) {
        this.select(val)
      }
    },
    'BindsTable.singleSelection' (val, old) {
      if (val != old) {
        this.select(val)
      }
    },
    bindsSelectedValue () {
      this.syncSelectedValue()
    },
    value () {
      this.syncSelectedValue()
      this.setWidth()
    }
  },
  methods: {
    isEmpty (value) {
      return !value || value.length === 0
    },
    emitEvent (eventName, value) {
      this.$emit(eventName, value)
    },
    getRowId (item, propertyName) {
      let id = item[propertyName]

      if (id) {
        return id
      }

      id = this.itemsUuidMap.get(item)

      if (!id) {
        id = 'binds-row-' + BindsUuid()
        this.itemsUuidMap.set(item, id)
      }

      return id
    },
    setScroll ($event) {
      raf(() => {
        if (this.bindsFixedHeader) {
          this.$refs.fixedHeaderContainer.scrollLeft = $event.target.scrollLeft
        }

        this.hasContentScroll = $event.target.scrollTop > 0
      })
    },
    setHeaderScroll ($event) {
      raf(() => {
        this.BindsTable.contentEl.scrollLeft = $event.target.scrollLeft
      })
    },
    getContentEl () {
      return this.$el.querySelector('.binds-table-content')
    },
    setContentEl () {
      this.BindsTable.contentEl = this.getContentEl()
    },
    setHeaderPadding () {
      this.setContentEl()

      const { contentEl } = this.BindsTable
      const tableEl = contentEl.childNodes[0]

      this.fixedHeaderPadding = contentEl.offsetWidth - tableEl.offsetWidth
    },
    getModel () {
      return this.value
    },
    getModelItem (index) {
      return this.value[index]
    },
    manageItemSelection (item) {
      if (this.BindsTable.selectedItems.includes(item)) {
        this.BindsTable.selectedItems = this.BindsTable.selectedItems.filter(target => target !== item)
      } else {
        this.BindsTable.selectedItems = this.BindsTable.selectedItems.concat([item])
      }
    },
    sortTable () {
      if (Array.isArray(this.value)) {
        this.$emit('input', this.bindsSortFn(this.value))
      }
    },
    select (val) {
      this.$emit('update:bindsSelectedValue', val)
      this.$emit('binds-selected', val)
    },
    syncSelectedValue () {
      this.$nextTick().then(() => { // render the table first
        if (this.BindsTable.selectingMode === 'single') {
          this.BindsTable.singleSelection = this.bindsSelectedValue
        } else if (this.BindsTable.selectingMode === 'multiple') {
          this.BindsTable.selectedItems = this.bindsSelectedValue || []
        }
      })
    },
    setWidth () {
      if (this.bindsFixedHeader) {
        this.fixedHeaderTableWidth = this.$refs.contentTable.offsetWidth
      }
    }
  },
  created () {
    if (this.bindsSort) {
      this.sortTable()
    }

    this.syncSelectedValue()
  },
  mounted () {
    this.setContentEl()
    this.$nextTick().then(this.setWidth)

    if (this.bindsFixedHeader) {
      this.setHeaderPadding()
      this.windowResizeObserver = new BindsResizeObserver(window, this.setWidth)
    }
  },
  beforeDestroy () {
    if (this.windowResizeObserver) {
      this.windowResizeObserver.destroy()
    }
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-table {
    display: flex;
    flex-flow: column wrap;
    overflow-x: auto;

    .binds-table-fixed-header {
      position: relative;

      .binds-table-fixed-header-container {
        -webkit-box-flex: 1;
        flex: 1;
        overflow-x: auto;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-button {
          display: none;
        }
      }
    }

    .binds-table-fixed-header-active {
      border-bottom: 1px solid;
    }

    .binds-table-content {
      flex: 1;
      overflow-x: auto;
      transition: height .3s $binds-transition-default-timing;
    }

    .binds-table-empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      overflow: hidden;
    }
  }
</style>
