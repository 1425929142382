<template>
  <div class="binds-drawer" :class="[$bindsActiveTheme, drawerClasses]">
    <slot />
    <binds-overlay :binds-active="bindsActive" @click="closeDrawer" v-if="bindsFixed" />
    <binds-overlay :binds-active="bindsActive" @click="closeDrawer" binds-attach-to-parent v-else />
  </div>
</template>

<script>
import BindsComponent from "../../core/BindsComponent";
import BindsOverlay from "../BindsOverlay/BindsOverlay";
import BindsPropValidator from "../../core/utils/BindsPropValidator";

import BindsSwipeable from "../../core/mixins/BindsSwipeable/BindsSwipeable";

export default new BindsComponent({
  name: "BindsDrawer",
  mixins: [BindsSwipeable],
  components: {
    BindsOverlay
  },
  props: {
    bindsRight: Boolean,
    bindsPermanent: {
      type: String,
      ...BindsPropValidator("binds-permanent", ["full", "clipped", "card"])
    },
    bindsPersistent: {
      type: String,
      ...BindsPropValidator("binds-persistent", ["mini", "full"])
    },
    bindsActive: Boolean,
    bindsFixed: Boolean
  },
  watch: {
    bindsActive(visible) {
      if (visible) {
        this.$emit("binds-opened");
      } else {
        this.$emit("binds-closed");
      }
    },
    swiped(value) {
      if (value === "right" || value === "left") {
        this.$emit("update:bindsActive", value === "right");
      }
    }
  },
  computed: {
    drawerClasses() {
      let classes = {
        "binds-left": !this.bindsRight,
        "binds-right": this.bindsRight,
        "binds-temporary": this.isTemporary,
        "binds-persistent": this.bindsPersistent,
        "binds-permanent": this.bindsPermanent,
        "binds-active": this.bindsActive,
        "binds-fixed": this.bindsFixed
      };

      if (this.bindsPermanent) {
        classes["binds-permanent-" + this.bindsPermanent] = true;
      }

      if (this.bindsPersistent) {
        classes["binds-persistent-" + this.bindsPersistent] = true;
      }

      return classes;
    },
    isTemporary() {
      return !this.bindsPermanent && !this.bindsPersistent;
    },
    mode() {
      if (this.bindsPersistent) {
        return "persistent";
      }

      if (this.bindsPermanent) {
        return "permanent";
      }

      return "temporary";
    },
    submode() {
      if (this.bindsPersistent) {
        return this.bindsPersistent;
      }

      if (this.bindsPermanent) {
        return this.bindsPermanent;
      }
    },
    bindsSwipeElement() {
      return this.$el.parentNode;
    }
  },
  methods: {
    closeDrawer() {
      this.$emit("update:bindsActive", false);
    }
  }
});
</script>

<style lang="scss">
@import "../BindsAnimation/variables";
@import "../BindsLayout/mixins";
@import "../BindsElevation/mixins";
@import "../BindsCard/base";

@mixin binds-drawer-base() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@mixin binds-drawer-temporary() {
  @include binds-drawer-base;
  z-index: 30;
  transform: translate3D(-100%, 0, 0);
  transition: transform 0.4s $binds-transition-stand-timing;
  will-change: transform, box-shadow;
}

@mixin binds-drawer-temporary-active() {
  transform: translate3D(0, 0, 0);
  transition-timing-function: $binds-transition-default-timing;

  @include binds-layout-xsmall {
    @include binds-elevation(16);
  }
}

.binds-drawer {
  @include binds-drawer-temporary;
  width: 400px;
  max-width: calc(100vw - 56px);
  overflow-x: hidden;
  overflow-y: auto;

  @include binds-layout-xsmall {
    width: 320px;
  }

  &.binds-right {
    right: 0;
    left: auto;
    transform: translate3D(100%, 0, 0);
  }

  &.binds-fixed {
    position: fixed;
  }

  &.binds-active {
    @include binds-drawer-temporary-active;
  }

  &:not(.binds-temporary) {
    ~ .binds-overlay {
      @include binds-layout-small-and-up {
        background: none;
        pointer-events: none;
      }
    }
  }

  &.binds-temporary {
    &.binds-left {
      + .binds-app-container .binds-content {
        border-left: none;
      }
    }

    &.binds-right-previous {
      + .binds-app-container .binds-content {
        border-right: none;
      }
    }

    &.binds-active {
      @include binds-elevation(16);
    }
  }

  &.binds-permanent {
    @include binds-layout-small-and-up {
      position: relative;
      transform: translate3D(0, 0, 0);
    }
  }

  &.binds-permanent-full {
    @include binds-layout-small-and-up {
      z-index: 3;

      .binds-list {
        padding-top: 0;
      }
    }
  }

  &.binds-permanent-clipped,
  &.binds-permanent-card {
    @include binds-layout-small-and-up {
      z-index: 1;
    }
  }

  &.binds-permanent-card {
    @include binds-layout-small-and-up {
      @include binds-card;
      margin: 8px;
      z-index: 1;
    }

    @include binds-layout-medium-and-up {
      margin: 16px;
    }

    @include binds-layout-large-and-up {
      margin: 24px;
    }
  }

  &.binds-persistent {
    &:not(.binds-active) {
      &.binds-left {
        + .binds-app-container .binds-content {
          border-left: none;
        }
      }

      &.binds-right-previous {
        + .binds-app-container .binds-content {
          border-right: none;
        }
      }
    }
  }

  &.binds-persistent-mini {
    transform: translate3D(0, 64px, 0);
    transition: 0.3s $binds-transition-stand-timing;
    transition-property: transform, width;
    will-change: transform, box-shadow;

    &.binds-left {
      border-right: 1px solid;
    }

    &.binds-right {
      border-left: 1px solid;
    }

    &.binds-active {
      &.binds-left {
        + .binds-app-container .binds-content {
          border-left: none;
        }
      }

      &.binds-right-previous {
        + .binds-app-container .binds-content {
          border-right: none;
        }
      }
    }

    &:not(.binds-active) {
      width: 70px !important;
      z-index: 1;
      white-space: nowrap;

      .binds-toolbar {
        display: none;
      }

      .binds-list-item-content {
        padding: 0 23px;
      }
    }

    &.binds-active {
      position: relative;
      transform: translate3D(0, 0, 0);
      white-space: normal;
    }
  }

  .binds-list-item-container {
    font-size: 14px;
    text-transform: none;
  }
}
</style>
