<template>
  <div class="binds-app binds-app-internal-drawer binds-layout-column" :class="[appClasses, $bindsActiveTheme]">
    <slot name="binds-app-toolbar"></slot>

    <main class="binds-app-container binds-flex binds-layout-row" :style="[containerStyles, contentStyles]" :class="[$bindsActiveTheme, scrollerClasses]">
      <slot name="binds-app-drawer-left"></slot>
      <slot name="binds-app-drawer-right-previous"></slot>
      <div class="binds-app-scroller binds-layout-column binds-flex" :class="[$bindsActiveTheme, scrollerClasses]">
        <slot name="binds-app-content"></slot>
      </div>
      <slot name="binds-app-drawer-right"></slot>
    </main>
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsAppMixin from './BindsAppMixin'

export default new BindsComponent({
  name: 'BindsAppInternalDrawer',
  mixins: [BindsAppMixin]
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-app-internal-drawer {
    flex-direction: column;

    .binds-app-scroller {
      overflow: auto;
    }
  }
</style>
