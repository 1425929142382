<template>
  <button type="button" class="binds-list-item-button" :disabled="disabled">
    <binds-list-item-content :binds-disabled="isDisabled">
      <slot />
    </binds-list-item-content>
  </button>
</template>

<script>
import BindsListItemMixin from './BindsListItemMixin'

export default {
  name: 'BindsListItemButton',
  mixins: [BindsListItemMixin]
}
</script>
