<template>
  <binds-icon class="binds-icon-image" v-once>
    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </svg>
  </binds-icon>
</template>

<script>
import BindsIcon from "../../components/BindsIcon/BindsIcon";

export default {
  name: "BindsCheckIcon",
  components: {
    BindsIcon
  }
};
</script>
