export default {
  redirect: 'You will be redirected to',
  previousQuestion: 'Previous',
  vu: 'Very dissatisfied',
  un: 'Dissatisfied',
  ind: 'Indifferent',
  sat: 'Satisfied',
  satisf: 'Good',
  vs: 'Very satisfied',
  vb: 'Very bad',
  bd: 'Bad',
  st: 'Good',
  vg: 'Very good',
  ex: 'Excellent',
  le: 'Little effort',
  me: 'Much effort',
  ee: 'Expected effort',
  op1: 'Yes',
  op2: 'No',
  op3: "Don't know",
  op4: 'Maybe',
  op: 'Option',
  required: 'Fill in the required fields',
  name: 'Name',
  email: 'Email',
  phone: 'Phone'
}
