<template>
  <binds-popover :binds-settings="popperSettings" :binds-active="shouldRender">
    <transition name="binds-menu-content" :css="didMount" v-if="shouldRender" v-on="$listeners">
      <div
        :class="[menuClasses, bindsContentClass, $bindsActiveTheme]"
        :style="menuStyles"
        ref="menu">
        <div class="binds-menu-content-container binds-scrollbar" :class="$bindsActiveTheme" ref="container">
          <binds-list :class="listClasses" v-bind="filteredAttrs">
            <slot />
          </binds-list>
        </div>
      </div>
    </transition>
  </binds-popover>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsPropValidator from '../../core/utils/BindsPropValidator'
import BindsObserveEvent from '../../core/utils/BindsObserveEvent'
import BindsResizeObserver from '../../core/utils/BindsResizeObserver'
import BindsPopover from '../BindsPopover/BindsPopover'
import BindsFocusTrap from '../BindsFocusTrap/BindsFocusTrap'
import BindsList from '../BindsList/BindsList'

export default new BindsComponent({
  name: 'BindsMenuContent',
  components: {
    BindsPopover,
    BindsFocusTrap,
    BindsList
  },
  props: {
    bindsListClass: [String, Boolean],
    bindsContentClass: [String, Boolean]
  },
  inject: ['BindsMenu'],
  data: () => ({
    highlightIndex: -1,
    didMount: false,
    highlightItems: [],
    popperSettings: null,
    menuStyles: ''
  }),
  computed: {
    filteredAttrs () {
      const attrs = this.$attrs
      delete attrs.id
      return attrs
    },
    highlightedItem () {
      return this.highlightItems[this.highlightIndex]
    },
    shouldRender () {
      return this.BindsMenu.active
    },
    menuClasses () {
      const prefix = 'binds-menu-content-'

      return {
        [prefix + this.BindsMenu.direction]: true,
        [prefix + this.BindsMenu.size]: true,
        'binds-menu-content': this.didMount,
        'binds-shallow': !this.didMount
      }
    },
    listClasses () {
      return {
        'binds-dense': this.BindsMenu.dense,
        ...this.bindsListClass
      }
    }
  },
  watch: {
    shouldRender (shouldRender) {
      if (shouldRender) {
        this.setPopperSettings()

        this.$nextTick().then(() => {
          this.setInitialHighlightIndex()
          this.createClickEventObserver()
          this.createResizeObserver()
          this.createKeydownListener()
        })
      }
    }
  },
  methods: {
    setPopperSettings () {
      const { direction, alignTrigger } = this.BindsMenu

      let { offsetX, offsetY } = this.getOffsets()

      if (!this.hasCustomOffsets()) {
        if (this.BindsMenu.instance.$el && this.BindsMenu.instance.$el.offsetHeight) {
          offsetY = -this.BindsMenu.instance.$el.offsetHeight - 11
        }

        if (direction.includes('start')) {
          offsetX = -8
        } else if (direction.includes('end')) {
          offsetX = 8
        }
      }

      this.popperSettings = {
        placement: direction,
        modifiers: {
          keepTogether: {
            enabled: true
          },
          flip: {
            enabled: false
          },
          offset: {
            offset: `${offsetX}, ${offsetY}`
          }
        }
      }
    },
    setInitialHighlightIndex () {
      this.setHighlightItems()
      this.highlightItems.forEach((item, index) => {
        if (item.classList.contains('binds-selected')) {
          this.highlightIndex = index - 1
        }
      })
    },
    setHighlightItems () {
      if (this.$el.querySelectorAll) {
        const items = this.$el.querySelectorAll('.binds-list-item-container:not(.binds-list-item-default):not([disabled])')

        this.highlightItems = Array.from(items)
      }
    },
    setHighlight (direction) {
      this.setHighlightItems()

      if (this.highlightItems.length) {
        if (direction === 'down') {
          if (this.highlightIndex === this.highlightItems.length - 1) {
            this.highlightIndex = 0
          } else {
            this.highlightIndex++
          }
        } else {
          if (this.highlightIndex === 0) {
            this.highlightIndex = this.highlightItems.length - 1
          } else {
            this.highlightIndex--
          }
        }

        this.clearAllHighlights()
        this.setItemHighlight()
      }
    },
    clearAllHighlights () {
      this.highlightItems.forEach(item => {
        item.parentNode.__vue__.highlighted = false
      })
    },
    setItemHighlight () {
      if (this.highlightedItem) {
        this.highlightedItem.parentNode.__vue__.highlighted = true
        if (this.$parent.$parent.setOffsets) {
          this.$parent.$parent.setOffsets(this.highlightedItem.parentNode)
        }
      }
    },
    setSelection () {
      if (this.highlightedItem) {
        this.highlightedItem.parentNode.click()
      }
    },
    onEsc () {
      this.BindsMenu.active = false
      this.destroyKeyDownListener()
    },
    getOffsets () {
      const relativePosition = this.getBodyPosition()

      const offsetX = this.BindsMenu.offsetX || 0
      const offsetY = this.BindsMenu.offsetY || 0

      return {
        offsetX: offsetX - relativePosition.x,
        offsetY: offsetY - relativePosition.y
      }
    },
    hasCustomOffsets () {
      const { offsetX, offsetY, alignTrigger } = this.BindsMenu

      return Boolean(alignTrigger || offsetY || offsetX)
    },
    isMenu ({ target }) {
      return this.BindsMenu.$el ? this.BindsMenu.$el.contains(target) : false
    },
    isMenuContentEl ({ target }) {
      return this.$refs.menu ? this.$refs.menu.contains(target) : false
    },
    isBackdropExpectMenu ($event) {
      return !this.$el.contains($event.target) && !this.isMenu($event)
    },
    createClickEventObserver () {
      if (document) {
        this.BindsMenu.bodyClickObserver = new BindsObserveEvent(document.body, 'click', $event => {
          $event.stopPropagation()

          if (!this.isMenu($event) && (this.BindsMenu.closeOnClick || this.isBackdropExpectMenu($event))) {
            this.BindsMenu.active = false
            this.BindsMenu.bodyClickObserver.destroy()
            this.BindsMenu.windowResizeObserver.destroy()
            this.destroyKeyDownListener()
          }
        })
      }
    },
    createKeydownListener () {
      window.addEventListener('keydown', this.keyNavigation)
    },
    destroyKeyDownListener () {
      window.removeEventListener('keydown', this.keyNavigation)
    },
    keyNavigation (event) {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault()
          this.setHighlight('up')
          break

        case 'ArrowDown':
          event.preventDefault()
          this.setHighlight('down')
          break

        case 'Enter':
          this.setSelection()
          break

        case 'Space':
          this.setSelection()
          break

        case 'Escape':
          this.onEsc()
      }
    },
    createResizeObserver () {
      this.BindsMenu.windowResizeObserver = new BindsResizeObserver(window, this.setStyles)
    },
    setupWatchers () {
      this.$watch('BindsMenu.direction', this.setPopperSettings)
      this.$watch('BindsMenu.alignTrigger', this.setPopperSettings)
      this.$watch('BindsMenu.offsetX', this.setPopperSettings)
      this.$watch('BindsMenu.offsetY', this.setPopperSettings)
    },
    setStyles () {
      if (this.BindsMenu.fullWidth) {
        this.menuStyles = `
            width: ${this.BindsMenu.instance.$el.offsetWidth}px;
            max-width: ${this.BindsMenu.instance.$el.offsetWidth}px
          `
      }
    },
    getBodyPosition () {
      const body = document.body
      const { top, left } = body.getBoundingClientRect()

      const scrollLeft = window.pageXOffset !== undefined ? window.pageXOffset : body.scrollLeft
      const scrollTop = window.pageYOffset !== undefined ? window.pageYOffset : body.scrollTop

      return { x: left + scrollLeft, y: top + scrollTop }
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.setHighlightItems()
      this.setupWatchers()
      this.setStyles()
      this.didMount = true
    })
  },
  beforeDestroy () {
    if (this.BindsMenu.bodyClickObserver) {
      this.BindsMenu.bodyClickObserver.destroy()
    }

    if (this.BindsMenu.windowResizeObserver) {
      this.BindsMenu.windowResizeObserver.destroy()
    }
    this.destroyKeyDownListener()
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";
  @import "../BindsLayout/mixins";

  $binds-menu-base-width: 56px;

  .binds-menu-content {
    @include binds-elevation(8);
    min-width: $binds-menu-base-width * 2;
    max-width: $binds-menu-base-width * 5;
    max-height: 35vh;
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 60;
    border-radius: 2px;
    transition: transform .2s $binds-transition-stand-timing,
                opacity .3s $binds-transition-stand-timing;
    will-change: opacity, transform, top, left !important;

    &.binds-shallow {
      position: fixed !important;
      top: -9999em !important;
      left: -9999em !important;
      pointer-events: none;
    }

    &.binds-menu-content-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &.binds-menu-content-leave-active {
      transition: opacity .4s $binds-transition-default-timing;
      opacity: 0;
    }

    &.binds-menu-content-enter {
      &.binds-menu-content-top-start {
        transform-origin: bottom left;
        transform: translate3d(0, 8px, 0) scaleY(.95);
      }

      &.binds-menu-content-top-end {
        transform-origin: bottom right;
        transform: translate3d(0, 8px, 0) scaleY(.95);
      }

      &.binds-menu-content-right-start {
        transform-origin: left top;
        transform: translate3d(0, -8px, 0) scaleY(.95);
      }

      &.binds-menu-content-right-end {
        transform-origin: left bottom;
        transform: translate3d(0, 8px, 0) scaleY(.95);
      }

      &.binds-menu-content-bottom-start {
        transform-origin: top left;
        transform: translate3d(0, -8px, 0) scaleY(.95);
      }

      &.binds-menu-content-bottom-end {
        transform-origin: top right;
        transform: translate3d(0, -8px, 0) scaleY(.95);
      }

      &.binds-menu-content-left-start {
        transform-origin: right top;
        transform: translate3d(0, -8px, 0) scaleY(.95);
      }

      &.binds-menu-content-left-end {
        transform-origin: right bottom;
        transform: translate3d(0, 8px, 0) scaleY(.95);
      }

      .binds-list {
        opacity: 0;
      }
    }

    &.binds-menu-content-medium {
      min-width: $binds-menu-base-width * 3;
    }

    &.binds-menu-content-big {
      min-width: $binds-menu-base-width * 4;
    }

    &.binds-menu-content-huge {
      min-width: $binds-menu-base-width * 5;
    }
  }

  .binds-menu-content-container {
    flex: 1;
    overflow: auto;

    .binds-list {
      transition: opacity .3s $binds-transition-stand-timing;
      will-change: opacity;
      font-family: 'Roboto', sans-serif;
      text-transform: none;
      white-space: nowrap;

      .binds-list-item-container {
        height: 100%;
      }

      @include binds-layout-small {
        font-size: 14px;
      }
    }
  }
</style>
