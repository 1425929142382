<template>
  <div :class="['binds-dialog-content', $bindsActiveTheme]">
    <slot />
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsDialogContent'
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-dialog-content {
    padding: 0 24px 24px;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    position: relative;

    &:first-child {
      padding-top: 24px;
    }

    p:first-child:not(:only-child) {
      margin-top: 0;
    }

    p:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  }
</style>
