<template>
  <ul class="binds-list" :class="[$bindsActiveTheme]" v-bind="$attrs" v-on="$listeners">
    <slot />
  </ul>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsList',
  data () {
    return {
      BindsList: {
        expandable: [],
        expandATab: this.expandATab,
        pushExpandable: this.pushExpandable,
        removeExpandable: this.removeExpandable
      }
    }
  },
  provide () {
    return {
      BindsList: this.BindsList
    }
  },
  props: {
    bindsExpandSingle: {
      default: false
    }
  },
  methods: {
    expandATab (expandedListItem) {
      if (this.bindsExpandSingle && expandedListItem) {
        const otherExpandableListItems = this.BindsList.expandable.filter(target => target !== expandedListItem)
        otherExpandableListItems.forEach(expandableListItem => expandableListItem.close())
      }
    },
    pushExpandable (expandableListItem) {
      let expandableListItems = this.BindsList.expandable

      if (!expandableListItems.find(target => target === expandableListItem)) {
        this.BindsList.expandable = expandableListItems.concat([expandableListItem])
      }
    },
    removeExpandable (expandableListItem) {
      let expandableListItems = this.BindsList.expandable

      if (expandableListItems.find(target => target === expandableListItem)) {
        this.BindsList.expandable = expandableListItems.filter(target => target !== expandableListItem)
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-list {
    margin: 0;
    padding: 8px 0;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    list-style: none;

    &.binds-dense {
      padding: 4px 0;
    }

    .binds-divider {
      margin-top: -1px;
    }

    .binds-subheader {
      &.binds-inset {
        padding-left: 72px;
      }
    }

    > .binds-subheader:first-of-type {
      margin-top: -8px;
    }
  }
</style>
