<template>
  <binds-button class="binds-stepper-header" :class="classes" :disabled="shouldDisable" v-bind="data.props" v-on="data.events" @click.native="!BindsSteppers.syncRoute && BindsSteppers.setActiveStep(index)">
    <binds-warning-icon class="binds-stepper-icon" v-if="data.error" />

    <div class="binds-stepper-number" v-else>
      <binds-edit-icon class="binds-stepper-editable" v-if="data.done && data.editable" />
      <binds-check-icon class="binds-stepper-done" v-else-if="data.done" />
      <template v-else>{{ BindsSteppers.getStepperNumber(index) }}</template>
    </div>

    <div class="binds-stepper-text">
      <span class="binds-stepper-label">{{ data.label }}</span>
      <span class="binds-stepper-error" v-if="data.error">{{ data.error }}</span>
      <span class="binds-stepper-description" v-else-if="data.description">{{ data.description }}</span>
    </div>
  </binds-button>
</template>

<script>
  import BindsWarningIcon from '../../core/icons/BindsWarningIcon'
  import BindsCheckIcon from '../../core/icons/BindsCheckIcon'
  import BindsEditIcon from '../../core/icons/BindsEditIcon'

  export default {
    name: 'BindsStepperHeader',
    components: {
      BindsWarningIcon,
      BindsCheckIcon,
      BindsEditIcon
    },
    props: {
      index: {
        type: String,
        required: true
      }
    },
    inject: ['BindsSteppers'],
    computed: {
      data () {
        return this.BindsSteppers.items[this.index]
      },
      shouldDisable () {
        const { data, index, BindsSteppers }  = this

        if (data.done && !data.editable) {
          return true
        }

        return BindsSteppers.isLinear && !BindsSteppers.isPreviousStepperDone(index)
      },
      classes () {
        return {
          'binds-active': !this.BindsSteppers.syncRoute && this.index === this.BindsSteppers.activeStep,
          'binds-error': this.data.error,
          'binds-done': this.data.done
        }
      }
    }
  }
</script>
