<template>
  <div class="binds-card-media-cover" :class="coverClasses">
    <slot />
    <div class="binds-card-backdrop" :style="coverStyles" v-if="bindsTextScrim" ref="backdrop"></div>
  </div>
</template>

<script>
export default {
  name: 'BindsCardMediaCover',
  props: {
    bindsTextScrim: Boolean,
    bindsSolid: Boolean
  },
  data: () => ({
    backdropBackground: {}
  }),
  computed: {
    coverClasses () {
      return {
        'binds-text-scrim': this.bindsTextScrim,
        'binds-solid': this.bindsSolid
      }
    },
    coverStyles () {
      return {
        background: this.backdropBackground
      }
    }
  },
  methods: {
    applyScrimColor (darkness) {
      if (this.$refs.backdrop) {
        this.backdropBackground = `linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, ${darkness / 2}) 66%, rgba(0, 0, 0, ${darkness}) 100%)`
      }
    },
    applySolidColor (darkness) {
      let area = this.$el.querySelector('.binds-card-area')

      if (area) {
        area.style.background = `rgba(0, 0, 0, ${darkness})`
      }
    },
    getImageLightness (image, onLoad, onError) {
      let canvas = document.createElement('canvas')
      canvas.setAttribute('class', 'binds-canvas')
      image.crossOrigin = 'Anonymous'

      image.onload = function () {
        let colorSum = 0
        let ctx
        let imageData
        let imageMetadata
        let r
        let g
        let b
        let average

        canvas.width = this.width
        canvas.height = this.height
        ctx = canvas.getContext('2d')

        ctx.drawImage(this, 0, 0)

        imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        imageMetadata = imageData.data

        for (let x = 0, len = imageMetadata.length; x < len; x += 4) {
          r = imageMetadata[x]
          g = imageMetadata[x + 1]
          b = imageMetadata[x + 2]

          average = Math.floor((r + g + b) / 3)
          colorSum += average
        }

        onLoad(Math.floor(colorSum / (this.width * this.height)))
      }

      image.onerror = onError
    }
  },
  mounted () {
    const applyBackground = (darkness = 0.6) => {
      if (this.bindsTextScrim) {
        this.applyScrimColor(darkness)
      } else if (this.bindsSolid) {
        this.applySolidColor(darkness)
      }
    }
    let image = this.$el.querySelector('img')

    if (image && (this.bindsTextScrim || this.bindsSolid)) {
      this.getImageLightness(image, (lightness) => {
        let limit = 256
        let darkness = (Math.abs(limit - lightness) * 100 / limit + 15) / 100

        if (darkness >= 0.7) {
          darkness = 0.7
        }

        applyBackground(darkness)
      }, applyBackground)
    }
  }
}
</script>

<style lang="scss">
  .binds-card-media-cover {
    position: relative;
    color: #fff;

    &.binds-solid {
      .binds-card-area {
        background-color: rgba(#000, .54);
      }
    }

    &.binds-text-scrim {
      .binds-card-backdrop {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }

    .binds-card-area {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    .binds-card-header {
      display: flex;
      flex-direction: column;

      + .binds-card-actions {
        padding-top: 0;
      }
    }

    .binds-subhead {
      opacity: 1;
    }

    .binds-card-header,
    .binds-card-actions {
      .binds-button:not(.binds-primary):not(.binds-accent) {
        color: #fff !important;

        &.binds-icon-button {
          .binds-icon {
            color: #fff !important;
          }
        }
      }
    }
  }
</style>
