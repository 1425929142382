<script>
export default {
  functional: true,
  props: {
    bindsTag: {
      type: String,
      default: 'div'
    }
  },
  render (createElement, { props, children, data, listeners }) {
    return createElement(props.bindsTag, {
      ...data,
      on: listeners
    }, children)
  }
}
</script>
