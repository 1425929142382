import BindsRipple from '../../../components/BindsRipple/BindsRipple'

export default {
  components: {
    BindsRipple
  },
  props: {
    bindsRipple: {
      type: Boolean,
      default: true
    }
  }
}
