<script>
import BindsComponent from '../../core/BindsComponent'
import BindsFocused from '../../core/mixins/BindsFocused/BindsFocused'
import BindsRipple from '../../core/mixins/BindsRipple/BindsRipple'
import BindsRouterLink from '../../core/mixins/BindsRouterLink/BindsRouterLink'
import BindsRouterLinkProps from '../../core/utils/BindsRouterLinkProps'
import BindsButtonContent from './BindsButtonContent'

export default new BindsComponent({
  name: 'BindsButton',
  data () {
    return {
      rippleActive: false
    }
  },
  components: {
    BindsButtonContent
  },
  mixins: [
    BindsRipple,
    BindsFocused,
    BindsRouterLink
  ],
  props: {
    href: String,
    type: {
      type: String,
      default: 'button'
    },
    disabled: Boolean
  },
  computed: {
    rippleWorks () {
      return this.bindsRipple && !this.disabled
    },
    isRouterLink () {
      return this.$router && this.to
    }
  },
  render (createElement) {
    const buttonContent = createElement('binds-button-content', {
      attrs: {
        bindsRipple: this.bindsRipple,
        disabled: this.disabled
      },
      props: {
        bindsRippleActive: this.rippleActive
      },
      on: {
        'update:bindsRippleActive': active => this.rippleActive = active
      }
    }, this.$slots.default)
    let buttonAttrs = {
      staticClass: 'binds-button',
      class: [
        this.$bindsActiveTheme,
        {
          'binds-ripple-off': !this.bindsRipple,
          'binds-focused': this.bindsHasFocus
        }
      ],
      attrs: {
        ...this.attrs,
        href: this.href,
        disabled: this.disabled,
        type: !this.href && (this.type || 'button')
      },
      on: {
        ...this.$listeners,
        touchstart: event => {
          if (this.rippleWorks) {
            this.rippleActive = event
          }

          this.$listeners.touchstart && this.$listeners.touchstart(event)
        },
        touchmove: event => {
          if (this.rippleWorks) {
            this.rippleActive = event
          }

          this.$listeners.touchmove && this.$listeners.touchmove(event)
        },
        mousedown: event => {
          if (this.rippleWorks) {
            this.rippleActive = event
          }

          this.$listeners.mousedown && this.$listeners.mousedown(event)
        }
      }
    }
    let tag = 'button'

    if (this.href) {
      tag = 'a'
    } else if (this.isRouterLink) {
      this.$options.props = BindsRouterLinkProps(this, this.$options.props)

      tag = 'router-link'
      const exactActiveClass = this.$props.exactActiveClass
      const activeClass = `${this.$props.activeClass || this.$material.router.linkActiveClass} binds-active`
      buttonAttrs.props = {
        ...this.$props,
        exactActiveClass,
        activeClass
      }
      delete buttonAttrs.props.type
      delete buttonAttrs.attrs.type
      delete buttonAttrs.props.href
      delete buttonAttrs.attrs.href
    }

    return createElement(tag, buttonAttrs, [buttonContent])
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";
  @import "../BindsElevation/variables";

  $binds-button-min-width: 88px;
  $binds-button-height: 36px;
  $binds-button-radius: 2px;
  $binds-button-font-size: 14px;

  $binds-button-dense-height: 32px;

  $binds-button-icon-size: 40px;

  $binds-button-fab-size: 56px;
  $binds-button-fab-size-mini: $binds-button-icon-size;

  .binds-button-clean {
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    overflow: hidden;
    outline: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    transition: $binds-transition-default;
    font-family: inherit;
    line-height: normal;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
  }

  .binds-button {
    @extend .binds-button-clean;
    min-width: $binds-button-min-width;
    height: $binds-button-height;
    margin: 6px 8px;
    user-select: none;
    border-radius: $binds-button-radius;
    font-size: $binds-button-font-size;
    font-weight: 500;
    text-transform: uppercase;

    &:active {
      outline: none;
    }

    &[disabled] {
      pointer-events: none;
    }

    &:not([disabled]) {
      cursor: pointer;

      &:hover,
      &:active,
      &.binds-focused {
        &:before {
          background-color: currentColor;
          opacity: .12;
        }
      }

      &.binds-focused {
        &.binds-primary,
        &.binds-accent {
          &:before {
            opacity: .2;
          }
        }
      }

      &:active {
        &:before {
          opacity: .2;
        }
      }

      &.binds-ripple-off:active:before {
        opacity: .26;
      }
    }

    &.binds-plain.binds-button.binds-raised:not([disabled]) {
      color: rgba(#000, .87);
      background-color: #fff;

      .binds-icon-font {
        color: rgba(#000, .87);
      }

      .binds-icon-image {
        fill: rgba(#000, .87);
      }
    }

    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      transition: $binds-transition-default;
      will-change: background-color, opacity;
      content: " ";
    }

    &.binds-dense {
      height: $binds-button-dense-height;
      font-size: 13px;
    }

    &.binds-raised:not([disabled]) {
      @include binds-elevation(2);

      &:active {
        @include binds-elevation(8);
      }

      &.binds-ripple-off:active:before {
        opacity: .2;
      }
    }

    + .binds-button {
      margin-left: 0;
    }

    .binds-ripple {
      padding: 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .binds-button-spaced .binds-ripple {
    padding: 0 16px;
  }

  .binds-icon-button,
  .binds-fab {
    border-radius: 50%;
    z-index: 10;

    &:before {
      border-radius: 50%;
    }

    .binds-ripple {
      border-radius: 50%;
    }
  }

  .binds-icon-button,
  .binds-fab.binds-mini,
  .binds-fab.binds-dense {
    .binds-ripple-wave {
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
    }
  }

  .binds-icon-button {
    width: $binds-button-icon-size;
    min-width: $binds-button-icon-size;
    height: $binds-button-icon-size;
    margin: 0 6px;

    &.binds-dense {
      width: $binds-button-dense-height;
      min-width: $binds-button-dense-height;
      height: $binds-button-dense-height;
    }

    .binds-ripple-enter-active {
      transition-duration: 1.2s;
    }
  }

  .binds-fab {
    @include binds-elevation(6);

    width: $binds-button-fab-size;
    height: $binds-button-fab-size;
    min-width: 0;
    overflow: hidden;

    &:active {
      @include binds-elevation(12);
    }

    &.binds-mini,
    &.binds-dense {
      width: $binds-button-fab-size-mini;
      height: $binds-button-fab-size-mini;
    }

    &.binds-fab-top-right,
    &.binds-fab-top-left {
      position: absolute;
      top: 24px;
    }

    &.binds-fab-bottom-right,
    &.binds-fab-bottom-left {
      position: absolute;
      bottom: 24px;
    }

    &.binds-fab-top-center,
    &.binds-fab-bottom-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &.binds-fab-top-center {
      top: 24px;
    }

    &.binds-fab-bottom-center {
      bottom: 24px;
    }

    &.binds-fab-top-right,
    &.binds-fab-bottom-right {
      right: 24px;
    }

    &.binds-fab-top-left,
    &.binds-fab-bottom-left {
      left: 24px;
    }

    &.binds-fixed {
      position: fixed;
    }

    .binds-ripple {
      padding: 0;
    }
  }
</style>
