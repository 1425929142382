<template>
  <div class="binds-avatar" :class="[$bindsActiveTheme]">
    <slot />
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsAvatar'
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  $binds-avatar-size: 40px;
  $binds-avatar-large-size: 64px;
  $binds-avatar-large-icon: 40px;
  $binds-avatar-small-size: 24px;
  $binds-avatar-small-icon: 16px;

  .binds-avatar {
    width: $binds-avatar-size;
    min-width: $binds-avatar-size;
    height: $binds-avatar-size;
    margin: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    user-select: none;
    position: relative;
    border-radius: $binds-avatar-size;
    transition: $binds-transition-default;
    transition-property: color, background-color;
    will-change: color, background-color;
    font-size: 24px;
    letter-spacing: -.05em;
    vertical-align: middle;

    &.binds-large {
      min-width: $binds-avatar-large-size;
      min-height: $binds-avatar-large-size;
      border-radius: $binds-avatar-large-size;
      font-size: $binds-avatar-large-icon - 8px;

      .binds-icon {
        font-size: $binds-avatar-large-icon !important;
      }
    }

    &.binds-small {
      width: $binds-avatar-small-size;
      min-width: $binds-avatar-small-size;
      height: $binds-avatar-small-size;
      border-radius: $binds-avatar-small-size;
      font-size: $binds-avatar-small-icon - 2px;

      .binds-icon {
        font-size: $binds-avatar-small-icon !important;
      }
    }

    .binds-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .binds-ripple {
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
</style>
