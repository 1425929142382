<template functional>
  <transition name="binds-snackbar" appear>
    <div class="binds-snackbar" :class="props.bindsClasses">
      <div class="binds-snackbar-content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BindsSnackbarContent',
  props: {
    bindsClasses: Array
  }
}
</script>
