<template>
  <binds-toolbar class="binds-app-toolbar" v-bind="$attrs" v-on="$listeners" :class="toolbarClasses" :style="toolbarStyles">
    <slot />
  </binds-toolbar>
</template>

<script>
  export default {
    name: 'BindsAppToolbar',
    inject: ['BindsApp'],
    computed: {
      toolbarClasses () {
        return {
          'binds-no-elevation': !this.BindsApp.toolbar.hasElevation,
          'binds-reveal-active': this.BindsApp.toolbar.revealActive,
          'binds-fixed-last-active': this.BindsApp.toolbar.fixedLastActive,
          'binds-overlap-off': this.BindsApp.toolbar.overlapOff
        }
      },
      toolbarStyles () {
        let styles = {
          'top': `${this.BindsApp.toolbar.top}px`
        }

        if (this.BindsApp.toolbar.fixedLastActive) {
          styles['transform'] = `translate3D(0, ${this.BindsApp.toolbar.fixedLastHeight}px, 0)`
        }

        return styles
      }
    },
    mounted () {
      const title = this.$el.querySelector('.binds-title, .binds-display-1, .binds-display-2')

      this.BindsApp.toolbar.element = this.$el
      this.BindsApp.toolbar.titleElement = title

      if (title) {
        this.BindsApp.toolbar.titleSize = parseInt(window.getComputedStyle(title).fontSize, 10)
      }
    }
  }
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-no-elevation {
    box-shadow: none !important;
  }

  .binds-reveal,
  .binds-fixed-last,
  .binds-flexible,
  .binds-overlap {
    .binds-reveal-active {
      transform: translate3d(0, calc(100% + 10px), 0);
      transition: .3s $binds-transition-stand-timing;
      transition-property: box-shadow, transform;
      will-change: height, box-shadow, transform;
    }
  }

  .binds-app-toolbar {
    min-height: 64px;
  }

  .binds-overlap {
    .binds-app-toolbar {
      height: 196px;
    }
  }

  .binds-fixed-last-active {
    transition: .3s $binds-transition-stand-timing;
    transition-property: box-shadow, transform;
    will-change: height, box-shadow, transform;
  }

  .binds-overlap-off {
    z-index: 3 !important;
  }
</style>
