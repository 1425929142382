<template>
  <div>
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">

      <binds-card-content>
        <nav class="nvs-5radio">
        <li v-for="(i,index) in data.question.options"  v-bind:key="index">
            <div class="item-single hvr-grow ">
                <binds-radio
                v-model="singleOption"
                :value="i.label"
                v-on:change="postResponse(i)"
                class="binds-primary">
                <span>{{i.label}}</span>
                </binds-radio>
            </div>
        </li>
        </nav>
      </binds-card-content>

    </binds-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsContent, BindsTabs, BindsRadio, BindsCard } from '../bindsMaterial/components'
import { widgetService } from '@/_services'

Vue.use(BindsContent)
Vue.use(BindsTabs)
Vue.use(BindsRadio)
Vue.use(BindsCard)

export default {
  props: ['data'],
  data () {
    return {
      singleOption: ''
    }
  },
  methods: {
    async postResponse (options) {
      const formatResponse = {
        rating: options.rating,
        value: options.label
      }
      console.log(formatResponse)
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, formatResponse)
        this.$root.$emit('questionUpdated', questionUpdated, options.rating)
      }
    }
  },
  mounted () {
    this.$root.$emit('setLoading', false)
  }
}
</script>
<style>
.nvs-5radio {
    padding-left: 10px;
}
.nvs-5radio li span {
    font-size: 16px;
}

</style>
