<template>
<div>
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">
      <binds-card-content>
        <div class="binds-layout-item binds-layout binds-gutter">
            <div class="binds-layout-item binds-size-50 binds-alignment-top-center">
                 <binds-card-media>
                    <img class="binds-image-end-message" :src="imageMessage"  alt="People">
                </binds-card-media>
            </div>
            <div class="binds-layout-item"></div>
            <div class="binds-layout-item"></div>
        </div>

        <div class="binds-layout-item binds-layout binds-gutter">
            <div class="binds-layout-item binds-size-50 binds-alignment-top-center">
                <binds-card-header>
                    <div class="binds-subhead">{{data.endMessage.message}}</div>
                </binds-card-header>
            </div>
            <div class="binds-layout-item"></div>
            <div class="binds-layout-item"></div>
        </div>

        <div class="binds-layout-item binds-layout binds-gutter">
            <div class="binds-layout-item"></div>
            <div class="binds-layout-item binds-size-50 binds-alignment-top-center"></div>
            <div class="binds-layout-item">
                <binds-card-media>
                    <img src="https://survey.binds.co/src/img/binds.svg" style="width: 60%;" alt="People">
                </binds-card-media>
            </div>
        </div>
      </binds-card-content>
    </binds-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsRadio, BindsCard, BindsContent, BindsButton, BindsRipple, BindsSteppers } from '../bindsMaterial/components'

Vue.use(BindsRadio)
Vue.use(BindsCard)
Vue.use(BindsContent)
Vue.use(BindsButton)
Vue.use(BindsRipple)
Vue.use(BindsSteppers)

export default {
  props: ['data'],
  created () {
    this.$root.$emit('setLoading', false)
    if (this.data.hidde_after_answer) {
      setTimeout(() => {
        this.$root.$emit('hiddeAfterAnswer')
      }, 2000)
    }
  },
  computed: {
    imageMessage () {
      let img
      if (!this.data.endMessage.logo.url) {
        img = this.data.endMessage.logo.link
      } else {
        img = this.data.endMessage.logo.url
      }
      return img
    }
  }
}
</script>
<style>

</style>
