<template>
  <div class="binds-card-header">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsCardHeader'
}
</script>

<style lang="scss">
  .binds-card-header {
    padding: 16px;

    &:first-child {
      > .binds-title:first-child,
      > .binds-card-header-text > .binds-title:first-child {
        margin-top: 8px;
      }
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &.binds-card-header-flex {
      display: flex;
      justify-content: space-between;
    }

    + .binds-card-content {
      padding-top: 0;
    }

    + .binds-card-actions:not(:last-child) {
      padding: 0 8px;
    }

    > img {
      border-radius: 50%;
    }

    > img,
    .binds-avatar {
      margin-right: 16px;
      float: left;

      ~ .binds-title {
        font-size: 14px;
      }

      ~ .binds-title,
      ~ .binds-subhead {
        font-weight: 500;
        line-height: 20px;
      }
    }

    .binds-button {
      margin: 0;

      &:last-child {
        margin-right: -4px;
      }

      + .binds-button {
        margin-left: 8px;
      }
    }

    .binds-card-header-text {
      flex: 1;
    }

    .binds-card-media {
      $size: 80px;

      width: $size;
      height: $size;
      margin-left: 16px;
      flex: 0 0 $size;

      &.binds-medium {
        $size: 120px;

        width: $size;
        height: $size;
        flex: 0 0 $size;
      }

      &.binds-big {
        $size: 160px;

        width: $size;
        height: $size;
        flex: 0 0 $size;
      }
    }
  }
</style>
