<script>
import BindsInteractionEvents from '../../../core/utils/BindsInteractionEvents'
import BindsRouterLinkProps from '../../../core/utils/BindsRouterLinkProps'
import BindsListItemDefault from './BindsListItemDefault'
import BindsListItemFakeButton from './BindsListItemFakeButton'
import BindsListItemButton from './BindsListItemButton'
import BindsListItemLink from './BindsListItemLink'
import BindsListItemRouter from './BindsListItemRouter'
import BindsListItemExpand from './BindsListItemExpand'
import BindsButton from '../../BindsButton/BindsButton'

function hasExpansion (props) {
  return props.hasOwnProperty('bindsExpand') && props.bindsExpand !== false
}

function resolveScopedSlot (props, children) {
  if (hasExpansion(props)) {
    return {
      'binds-expand': () => {
        return children['binds-expand'][0]
      }
    }
  }
}

function hasChildrenButtons (childrens) {
  return childrens.default.some(children => children.componentOptions && children.componentOptions.tag === 'binds-button')
}

function shouldRenderButtonWithListener (listeners) {
  let listenerNames = Object.keys(listeners)
  let shouldRender = false

  listenerNames.forEach(listener => {
    if (BindsInteractionEvents.includes(listener)) {
      shouldRender = true
    }
  })

  return shouldRender
}

function isRouterLink (parent, props) {
  return parent && parent.$router && props.to
}

function createListComponent (props, parent, listeners, children) {
  if (hasExpansion(props)) {
    return BindsListItemExpand
  }

  if (props.disabled) {
    return BindsListItemButton
  }

  if (isRouterLink(parent, props)) {
    BindsListItemRouter.props = BindsRouterLinkProps(parent, {
      target: String
    })
    delete BindsListItemRouter.props.href

    return BindsListItemRouter
  }

  if (props.href) {
    return BindsListItemLink
  }

  if (shouldRenderButtonWithListener(listeners)) {
    return renderButtonWithListener(children)
  }

  return BindsListItemDefault
}

function renderButtonWithListener (children) {
  if (hasChildrenButtons(children)) {
    return BindsListItemFakeButton
  }
  return BindsListItemButton
}

export default {
  name: 'BindsListItem',
  functional: true,
  components: {
    BindsButton
  },
  render (createElement, { parent, props, listeners, data, slots }) {
    let children = slots()
    let listComponent = createListComponent(props, parent, listeners, children)
    let staticClass = 'binds-list-item'

    if (data.staticClass) {
      staticClass += ' ' + data.staticClass
    }

    return createElement('li', {
      ...data,
      staticClass,
      on: listeners
    }, [
      createElement(listComponent, {
        props,
        scopedSlots: resolveScopedSlot(props, children),
        staticClass: 'binds-list-item-container binds-button-clean',
        on: listeners
      }, children.default)
    ])
  }
}
</script>

<style lang="scss">
  @import "../../BindsAnimation/variables";

  .binds-list-item {
    height: auto;
    position: relative;
    z-index: 2;

    &.binds-inset {
      .binds-list-item-content {
        padding-left: 72px;
      }
    }

    .binds-icon {
      margin: 0;
      transition-property: color, margin-right;
    }
  }

  .binds-list-item-container {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    text-transform: none;

    &:not(.binds-list-item-default):not([disabled]) {
      > .binds-list-item-content {
        user-select: none;
        cursor: pointer;
      }
    }

    &.binds-button-clean:hover {
      opacity: 1;
      text-decoration: none;
    }
  }

  .binds-list-item-content {
    min-height: 48px;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding .4s $binds-transition-stand-timing;
    will-change: padding;

    .binds-list.binds-dense & {
      min-height: 40px;
      font-size: 13px;

      > .binds-avatar {
        margin-top: 0;
        margin-bottom: 0;

        &:not(.binds-small) {
          width: 36px;
          min-width: 36px;
          height: 36px;
        }

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .binds-list.binds-double-line & {
      min-height: 72px;
    }

    .binds-list.binds-double-line.binds-dense & {
      min-height: 60px;
    }

    .binds-list.binds-triple-line & {
      min-height: 88px;
    }

    .binds-list.binds-triple-line.binds-dense & {
      min-height: 76px;
    }

    .binds-list-action {
      margin: 0 -10px 0 0;

      &:last-of-type {
        margin: 0 -10px 0 16px;

        .binds-list.binds-triple-line & {
          align-self: flex-start;
        }
      }
    }

    > .binds-icon:first-child {
      margin-right: 32px;
    }

    > .binds-icon:last-child {
      margin-left: 16px;
    }

    > .binds-checkbox,
    > .binds-radio {
      margin: 0;

      &:first-child {
        margin-right: 36px;
      }
    }

    > .binds-switch {
      margin: 0;

      &:first-child {
        margin-right: 22px;
      }
    }

    > .binds-avatar {
      margin: 4px 0;

      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .binds-list-item-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    line-height: 1.25em;
    white-space: nowrap;

    .binds-list.binds-dense & {
      font-size: 13px;
    }

    * {
      width: 100%;
      margin: 0;
      overflow: hidden;
      line-height: 1.25em;
      text-overflow: ellipsis;
    }

    :nth-child(2),
    :nth-child(3) {
      font-size: 14px;
    }

    .binds-list.binds-dense & * {
      font-size: 13px;
    }
  }
</style>
