<template>
  <binds-portal>
    <transition name="binds-dialog">
      <div class="binds-dialog" :class="[dialogClasses, $bindsActiveTheme]" v-on="$listeners" @keydown.esc="onEsc" v-if="bindsActive">
        <binds-focus-trap>
          <div class="binds-dialog-container">
            <slot />

            <keep-alive>
              <binds-overlay :class="bindsBackdropClass" binds-fixed :binds-active="bindsActive" @click="onClick" v-if="bindsBackdrop" />
            </keep-alive>
          </div>
        </binds-focus-trap>
      </div>
    </transition>
  </binds-portal>
</template>

<script>
import BindsComponent from '../../core/BindsComponent.js'
import BindsPortal from '../BindsPortal/BindsPortal'
import BindsOverlay from '../BindsOverlay/BindsOverlay'
import BindsFocusTrap from '../BindsFocusTrap/BindsFocusTrap'

export default new BindsComponent({
  name: 'BindsDialog',
  components: {
    BindsPortal,
    BindsOverlay,
    BindsFocusTrap
  },
  props: {
    bindsActive: Boolean,
    bindsBackdrop: {
      type: Boolean,
      default: true
    },
    bindsBackdropClass: {
      type: String,
      default: 'binds-dialog-overlay'
    },
    bindsCloseOnEsc: {
      type: Boolean,
      default: true
    },
    bindsClickOutsideToClose: {
      type: Boolean,
      default: true
    },
    bindsFullscreen: {
      type: Boolean,
      default: true
    },
    bindsAnimateFromSource: Boolean
  },
  computed: {
    dialogClasses () {
      return {
        'binds-dialog-fullscreen': this.bindsFullscreen
      }
    }
  },
  watch: {
    bindsActive (isActive) {
      this.$nextTick().then(() => {
        if (isActive) {
          this.$emit('binds-opened')
        } else {
          this.$emit('binds-closed')
        }
      })
    }
  },
  methods: {
    closeDialog () {
      this.$emit('update:bindsActive', false)
    },
    onClick () {
      if (this.bindsClickOutsideToClose) {
        this.closeDialog()
      }
      this.$emit('binds-clicked-outside')
    },
    onEsc () {
      if (this.bindsCloseOnEsc) {
        this.closeDialog()
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsLayout/mixins";
  @import "../BindsElevation/mixins";

  .binds-dialog {
    @include binds-elevation(24);
    min-width: 280px;
    max-width: 80%;
    max-height: 80%;
    margin: auto;
    display: flex;
    flex-flow: column;
    flex-direction: row;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 110;
    border-radius: 2px;
    backface-visibility: hidden;
    pointer-events: auto;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    transition: opacity .15s $binds-transition-stand-timing,
                transform .2s $binds-transition-stand-timing;
    will-change: opacity, transform, left, top;

    > .binds-dialog-tabs,
    > .binds-dialog-title,
    > .binds-dialog-content,
    > .binds-dialog-actions {
      transition: opacity .3s $binds-transition-default-timing,
                  transform .25s $binds-transition-default-timing;
      will-change: opacity, transform;
    }
  }

.binds-dialog-enter-active,
  .binds-dialog-leave-active {
    opacity: 0;
    transform: translate(-50%, -50%) scale(.9);

    > .binds-dialog-tabs,
    > .binds-dialog-title,
    > .binds-dialog-content,
    > .binds-dialog-actions {
      opacity: 0;
      transform: scale(.95) translate3D(0, 10%, 0);
    }
  }

  .binds-dialog-container {
    display: flex;
    flex-flow: column;
    flex: 1;

    .binds-tabs {
      flex: 1;
    }

    .binds-tabs-navigation {
      padding: 0 12px;
    }

    .binds-tab {
      @include binds-layout-xsmall {
        padding: 12px;
      }
    }
  }

  .binds-dialog-fullscreen {
    @include binds-layout-xsmall {
      max-width: 100%;
      max-height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;
      transform: none;

      &.binds-dialog-enter {
        opacity: 0;
        transform: translate3D(0, 30%, 0);
      }

      &.binds-dialog-leave-active {
        opacity: 0;
        transform: translate3D(0, 0, 0);
      }
    }
  }
</style>
