export default {
  redirect: 'Você será redirecionado para',
  previousQuestion: 'Anterior',
  vu: 'Muito insatisfeito',
  un: 'Insatisfeito',
  ind: 'Indiferente',
  sat: 'Satisfeito',
  satisf: 'Satisfatório',
  vs: 'Muito satisfeito',
  vb: 'Muito ruim',
  bd: 'Ruim',
  st: 'Bom',
  vg: 'Muito bom',
  ex: 'Excelente',
  le: 'Pouco esforço',
  me: 'Muito esforço',
  ee: 'Esforço esperado',
  op1: 'Sim',
  op2: 'Não',
  op3: 'Não sei',
  op4: 'Talvez',
  op: 'Opção',
  required: 'Preencha os campos obrigatórios',
  name: 'Nome',
  email: 'Email',
  phone: 'Telefone',
  nvs: {
    nvs_op1: 'Significativamente melhor',
    nvs_op2: 'Um pouco melhor',
    nvs_op3: 'Nem melhor, nem pior',
    nvs_op4: 'Um pouco pior',
    nvs_op5: 'Significativamente pior'
  },
  ces: {
    totally_disagree: 'Discordo totalmente',
    disagree: 'Discordo',
    partially_disagree: 'Discordo Parcialmente',
    neutral: 'Neutro',
    partially_agree: 'Concordo Parcialmente',
    agree: 'Concordo',
    totally_agree: 'Concordo totalmente'
  },
}
