<template>
  <div class="binds-card-expand">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsCardExpand',
  inject: ['BindsCard']
}
</script>

<style lang="scss">
  .binds-card-expand {
    overflow: hidden;
    .binds-card-actions {
      position: relative;
      z-index: 2;
    }
    .binds-card-expand-content {
      position: relative;
      z-index: 1;
    }
  }
</style>
