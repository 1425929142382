<template>
  <div class="binds-app binds-app-side-drawer binds-layout-row" :class="[appClasses, $bindsActiveTheme]">
    <slot name="binds-app-drawer-left"></slot>
    <slot name="binds-app-drawer-right-previous"></slot>
    <main class="binds-app-container binds-flex binds-layout-column" :class="[$bindsActiveTheme, scrollerClasses]" :style="contentStyles" @scroll.passive="handleScroll">
      <slot name="binds-app-toolbar"></slot>
      <div class="binds-app-scroller binds-layout-column binds-flex" :class="[$bindsActiveTheme, scrollerClasses]" :style="containerStyles" @scroll.passive="handleScroll">
        <slot name="binds-app-content"></slot>
      </div>
    </main>

    <slot name="binds-app-drawer-right"></slot>
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsAppMixin from './BindsAppMixin'

export default new BindsComponent({
  name: 'BindsAppSideDrawer',
  mixins: [BindsAppMixin]
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-app-side-drawer {
    .binds-app-container {
      flex-direction: column;
    }
  }
</style>
