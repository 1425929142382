<template>
  <div>
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">

      <binds-card-content>
        <!-- UI igual a 5 num -->
        <div class="animated-faster zoomIn">
          <div class="binds-layout binds-gutter">
            <div
              v-for="(label, index) in items" v-bind:key="index"
              :class="{'binds-size-20': items.length <= 5, 'binds-size-14': items.length > 5}"
              class="binds-layout-item responsiveAlign"
            >
              <binds-button class="binds-raised" :binds-ripple="false" v-on:click="postResponse(label)" 
                style="min-width: 80%"
                :style="returnStyle(index)"
                :value="label.rating">
                {{label.label}}
                </binds-button>
            </div>
          </div>
          <div class="binds-layout binds-gutter">
            <div class="binds-layout-item">
              <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.startLabel}}</span>
            </div>
            <div class="binds-layout-item alignSpecialLabels">
            <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.endLabel}}</span>
            </div>
          </div>
        </div>
      </binds-card-content>

    </binds-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsContent, BindsTabs, BindsButton, BindsCard } from '../bindsMaterial/components'
import { widgetService } from '@/_services'
import { formatButton } from '@/_helpers'

Vue.use(BindsContent)
Vue.use(BindsTabs)
Vue.use(BindsButton)
Vue.use(BindsCard)

export default {
  props: ['data'],
  data () {
    return {
      items: [
        { rating: 0, label: '1' },
        { rating: 25, label: '2' },
        { rating: 50, label: '3' },
        { rating: 75, label: '4' },
        { rating: 100, label: '5' }
      ]   
    }
  },
  methods: {
    async postResponse (options) {
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, options)
        this.$root.$emit('questionUpdated', questionUpdated, options.rating)
      }
    },
    returnStyle (index) {
      return formatButton.returnStyleWithGradient(this.data, index)
    }
  },
  mounted () {
    this.$root.$emit('setLoading', false)
  }
}
</script>
<style scoped>
.binds-size-14 {
  max-width: 14%;
  min-width: 14%;
}
</style>
