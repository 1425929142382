<template>
  <binds-ripple class="binds-list-item-content" :binds-disabled="bindsDisabled">
    <slot />
  </binds-ripple>
</template>

<script>
import BindsRipple from '../../BindsRipple/BindsRipple.vue'

export default {
  name: 'BindsListItemContent',
  components: {
    BindsRipple
  },
  props: {
    bindsDisabled: Boolean
  }
}
</script>
