import Vue from 'vue'
import App from './App.vue'
import Widget from './components/Widget.vue'
import router from './router'
import store from './store/index'
import VueHead from 'vue-head'
import i18n from './translations/i18n'
import './assets/css/main.scss'

// (optional) 'Custom elements polyfill'
import 'document-register-element/build/document-register-element'
// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)
Vue.use(VueHead)

Vue.config.productionTip = false

// use vue-custom-element
App.store = store
App.router = router
App.i18n = i18n

Widget.store = store
Widget.router = router
Widget.i18n = i18n

Vue.customElement('vue-widget', Widget)
Vue.customElement('vue-widget-debug', App)
