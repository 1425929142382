<template>
  <router-link class="binds-list-item-router" v-bind="routerProps">
    <binds-list-item-content :binds-disabled="isDisabled">
      <slot />
    </binds-list-item-content>
  </router-link>
</template>

<script>
import BindsListItemMixin from './BindsListItemMixin'

export default {
  name: 'BindsListItemRouter',
  mixins: [BindsListItemMixin],
  computed: {
    routerProps () {
      return this.$props
    }
  }
}
</script>
