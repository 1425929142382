<template>
  <div class="binds-tabs" :class="[tabsClasses, $bindsActiveTheme]">
    <div class="binds-tabs-navigation" :class="navigationClasses" ref="navigation">
      <binds-button
        v-for="({ label, props, icon, disabled, data, events }, index) in BindsTabs.items"
        :key="index"
        class="binds-tab-nav-button"
        :class="{
          'binds-active': (!bindsSyncRoute && index === activeTab),
          'binds-icon-label': icon && label
        }"
        :disabled="disabled"
        v-bind="props"
        v-on="events"
        @click.native="setActiveTab(index)">
        <slot name="binds-tab" :tab="{ label, icon, data }" v-if="$scopedSlots['binds-tab']"></slot>

        <template v-else>
          <template v-if="!icon">{{ label }}</template>
          <template v-else>
            <binds-icon class="binds-tab-icon" v-if="isAssetIcon(icon)" :binds-src="icon"></binds-icon>
            <binds-icon class="binds-tab-icon" v-else>{{ icon }}</binds-icon>
            <span class="binds-tab-label">{{ label }}</span>
          </template>
        </template>
      </binds-button>
      <span class="binds-tabs-indicator" :style="indicatorStyles" :class="indicatorClass" ref="indicator"></span>
    </div>

    <binds-content ref="tabsContent" class="binds-tabs-content" :style="contentStyles" v-show="hasContent">
      <div class="binds-tabs-container" :style="containerStyles">
        <slot />
      </div>
    </binds-content>
  </div>
</template>

<script>
import raf from 'raf'
import BindsComponent from '../../core/BindsComponent'
import BindsAssetIcon from '../../core/mixins/BindsAssetIcon/BindsAssetIcon'
import BindsPropValidator from '../../core/utils/BindsPropValidator'
import BindsObserveElement from '../../core/utils/BindsObserveElement'
import BindsThrottling from '../../core/utils/BindsThrottling'
import BindsContent from '../BindsContent/BindsContent'
import BindsSwipeable from '../../core/mixins/BindsSwipeable/BindsSwipeable'

export default new BindsComponent({
  name: 'BindsTabs',
  mixins: [BindsAssetIcon, BindsSwipeable],
  components: {
    BindsContent
  },
  props: {
    bindsAlignment: {
      type: String,
      default: 'left',
      ...BindsPropValidator('binds-alignment', ['left', 'right', 'centered', 'fixed'])
    },
    bindsElevation: {
      type: [Number, String],
      default: 0
    },
    bindsSyncRoute: Boolean,
    bindsDynamicHeight: Boolean,
    bindsActiveTab: [String, Number]
  },
  data: () => ({
    resizeObserver: null,
    activeTab: 0,
    activeTabIndex: 0,
    indicatorStyles: {},
    indicatorClass: null,
    noTransition: true,
    containerStyles: {},
    contentStyles: {
      height: '0px'
    },
    hasContent: false,
    BindsTabs: {
      items: {}
    },
    activeButtonEl: null
  }),
  provide () {
    return {
      BindsTabs: this.BindsTabs
    }
  },
  computed: {
    tabsClasses () {
      return {
        ['binds-alignment-' + this.bindsAlignment]: true,
        'binds-no-transition': this.noTransition,
        'binds-dynamic-height': this.bindsDynamicHeight
      }
    },
    navigationClasses () {
      return 'binds-elevation-' + this.bindsElevation
    },
    bindsSwipeElement () {
      return this.$refs.tabsContent.$el
    }
  },
  watch: {
    BindsTabs: {
      deep: true,
      handler () {
        this.setHasContent()
      }
    },
    activeTab (index) {
      this.$emit('binds-changed', index)
      this.$nextTick().then(() => {
        this.setIndicatorStyles()
        this.setActiveButtonEl()
      })
    },
    bindsActiveTab (tab) {
      this.activeTab = tab
      this.$emit('binds-changed', tab)
    },
    activeButtonEl (activeButtonEl) {
      this.activeTabIndex = activeButtonEl ? [].indexOf.call(activeButtonEl.parentNode.childNodes, activeButtonEl) : -1
    },
    activeTabIndex (index) {
      this.setIndicatorStyles()
      this.calculateTabPos()
    },
    '$route' () {
      this.$nextTick(this.setActiveButtonEl)
    },
    swiped (value) {
      const { keys } = this.getItemsAndKeys()
      const max = keys.length || 0
      if (this.activeTabIndex < max && value === 'right') {
        this.setSwipeActiveTabByIndex(this.activeTabIndex + 1)
      } else if (this.activeTabIndex > 0 && value === 'left') {
        this.setSwipeActiveTabByIndex(this.activeTabIndex - 1)
      }
    }
  },
  methods: {
    hasActiveTab () {
      return this.activeTab || this.bindsActiveTab
    },
    getItemsAndKeys () {
      const items = this.BindsTabs.items

      return {
        items,
        keys: Object.keys(items)
      }
    },
    setActiveTab (index) {
      if (!this.bindsSyncRoute) {
        this.activeTab = index
      }
    },
    setActiveButtonEl () {
      this.activeButtonEl = this.$refs.navigation.querySelector('.binds-tab-nav-button.binds-active')
    },
    setSwipeActiveTabByIndex (index) {
      const { keys } = this.getItemsAndKeys()

      if (keys) {
        this.activeTab = keys[index]
      }
    },
    setActiveTabByIndex (index) {
      const { keys } = this.getItemsAndKeys()

      if (!this.hasActiveTab()) {
        this.activeTab = keys[index]
      }
    },
    setHasContent () {
      const { items, keys } = this.getItemsAndKeys()

      this.hasContent = keys.some(key => items[key].hasContent)
    },
    setIndicatorStyles () {
      raf(() => {
        this.$nextTick().then(() => {
          // this.setActiveButtonEl()
          if (this.activeButtonEl && this.$refs.indicator) {
            const buttonWidth = this.activeButtonEl.offsetWidth
            const buttonLeft = this.activeButtonEl.offsetLeft
            const indicatorLeft = this.$refs.indicator.offsetLeft

            if (indicatorLeft < buttonLeft) {
              this.indicatorClass = 'binds-tabs-indicator-right'
            } else {
              this.indicatorClass = 'binds-tabs-indicator-left'
            }

            this.indicatorStyles = {
              left: `${buttonLeft}px`,
              right: `calc(100% - ${buttonWidth + buttonLeft}px)`
            }
          } else {
            this.indicatorStyles = {
              left: '100%',
              right: '100%'
            }
          }
        })
      })
    },
    calculateTabPos () {
      if (this.hasContent) {
        const tabElement = this.$el.querySelector(`.binds-tab:nth-child(${this.activeTabIndex + 1})`)

        this.contentStyles = {
          height: tabElement ? `${tabElement.offsetHeight}px` : 0
        }

        this.containerStyles = {
          transform: `translate3D(${-this.activeTabIndex * 100}%, 0, 0)`
        }
      }
    },
    callResizeFunctions () {
      this.setIndicatorStyles()
      this.calculateTabPos()
    },
    setupObservers () {
      this.resizeObserver = BindsObserveElement(this.$el.querySelector('.binds-tabs-content'), {
        childList: true,
        characterData: true,
        subtree: true
      }, () => {
        this.callResizeFunctions()
      })

      window.addEventListener('resize', this.callResizeFunctions)
    }
  },
  created () {
    this.setIndicatorStyles = BindsThrottling(this.setIndicatorStyles, 300)
    this.setHasContent()
    this.activeTab = this.bindsActiveTab
  },
  mounted () {
    this.setupObservers()

    this.$nextTick().then(() => {
      if (!this.bindsSyncRoute) {
        this.setActiveTabByIndex(0)
      }

      return this.$nextTick()
    }).then(() => {
      this.setActiveButtonEl()
      this.calculateTabPos()

      window.setTimeout(() => {
        this.noTransition = false
        this.setupObservers()
      }, 100)
    })

    this.$refs.navigation.addEventListener('transitionend', this.setIndicatorStyles)
  },
  beforeDestroy () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }

    window.removeEventListener('resize', this.callResizeFunctions)
    this.$refs.navigation.removeEventListener('transitionend', this.setIndicatorStyles)
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";
  @import "../BindsLayout/mixins";

  .binds-tabs {
    display: flex;
    flex-direction: column;

    &.binds-no-transition * {
      transition: none !important;
    }

    &.binds-dynamic-height .binds-tabs-content {
      transition: height .3s $binds-transition-default-timing;
      will-change: height;
    }

    &.binds-transparent {
      .binds-tabs-navigation,
      .binds-tabs-content {
        background-color: transparent !important;
      }
    }

    &.binds-dynamic-height .binds-tabs-content {
      transition: height .35s $binds-transition-stand-timing;
    }

    &.binds-alignment-left .binds-tabs-navigation {
      justify-content: flex-start;
    }

    &.binds-alignment-right .binds-tabs-navigation {
      justify-content: flex-end;
    }

    &.binds-alignment-centered .binds-tabs-navigation {
      justify-content: center;
    }

    &.binds-alignment-fixed .binds-tabs-navigation {
      justify-content: center;

      .binds-button {
        max-width: 264px;
        min-width: 160px;
        flex: 1;

        @include binds-layout-small {
          min-width: 72px;
        }
      }
    }

    .binds-toolbar & {
      padding-left: 48px;

      @include binds-layout-small {
        margin: 0 -8px;
        padding-left: 0px;
      }
    }
  }

  .binds-tabs-navigation {
    display: flex;
    position: relative;

    .binds-button {
      max-width: 264px;
      min-width: 72px;
      height: 48px;
      margin: 0;
      cursor: pointer;
      border-radius: 0;
      font-size: 13px;
    }

    .binds-button-content {
      position: static;
    }

    .binds-icon-label {
      height: 72px;

      .binds-button-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .binds-tab-icon + .binds-tab-label {
        margin-top: 10px;
      }
    }

    .binds-ripple {
      padding: 0 24px;

      @include binds-layout-small {
        padding: 0 12px;
      }
    }
  }

  .binds-tabs-indicator {
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    will-change: left, right;

    &.binds-tabs-indicator-left {
      transition: left .3s $binds-transition-default-timing,
                  right .35s $binds-transition-default-timing;
    }

    &.binds-tabs-indicator-right {
      transition: right .3s $binds-transition-default-timing,
                  left .35s $binds-transition-default-timing;
    }
  }

  .binds-tabs-content {
    overflow: hidden;
    transition: none;
    will-change: height;
  }

  .binds-tabs-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    transform: translateZ(0);
    transition: transform .35s $binds-transition-default-timing;
    will-change: transform;
  }

  .binds-tab {
    width: 100%;
    flex: 1 0 100%;
    padding: 16px;

    @include binds-layout-small {
      padding: 8px;
    }
  }
</style>
