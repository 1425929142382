import BindsTheme from './BindsTheme'
import deepmerge from 'deepmerge'

export default function (newComponent) {
  const defaults = {
    props: {
      bindsTheme: null
    },
    computed: {
      $bindsActiveTheme () {
        const { enabled, getThemeName, getAncestorTheme } = BindsTheme

        if (enabled && this.bindsTheme !== false) {
          return getThemeName(this.bindsTheme || getAncestorTheme(this))
        }

        return null
      }
    }
  }

  return deepmerge(defaults, newComponent)
}
