import { render, staticRenderFns } from "./Ces.vue?vue&type=template&id=64c457fe&scoped=true&"
import script from "./Ces.vue?vue&type=script&lang=js&"
export * from "./Ces.vue?vue&type=script&lang=js&"
import style0 from "./Ces.vue?vue&type=style&index=0&id=64c457fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c457fe",
  null
  
)

export default component.exports