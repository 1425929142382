export default {
  redirect: 'Serás redirigido ao',
  previousQuestion: 'Anterior',
  vu: 'Muy insatisfecho',
  un: 'Insatisfecho',
  ind: 'Indiferente',
  sat: 'Satisfecho',
  satisf: 'Bien',
  vs: 'Muy satisfecho',
  vb: 'Muy malo',
  bd: 'Malo',
  st: 'Bien',
  vg: 'Muy bien',
  ex: 'Excelente',
  le: 'Pequeño esfuerzo',
  me: 'Mucho esfuerzo',
  ee: 'Esfuerzo esperado',
  op1: 'Sí  ',
  op2: 'No',
  op3: 'No sé',
  op4: 'Tal vez',
  op: 'Opción',
  required: 'Complete los campos obligatorios',
  name: 'Nombre',
  email: 'Email',
  phone: 'Teléfono'
}
