<template>
  <div>
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">

      <binds-card-content>
        <!-- UI igual a 5 num -->
        <div class="animated-faster zoomIn" v-if="data.question.ui === '5num'">
          <div class="binds-layout binds-gutter">
            <div class="binds-layout-item binds-size-20 responsiveAlign" v-for="(label, index) in data.question.options" v-bind:key="index">
              <binds-button class="binds-raised" :binds-ripple="false" v-on:click="postResponse(data.question.options[index])"
                style="min-width: 80%"
                :style="returnStyle(index)"
                :value="data.question.options[index].rating">
                {{data.question.options[index].label}}
                </binds-button>
            </div>
          </div>
          <div class="binds-layout binds-gutter">
            <div class="binds-layout-item">
              <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.startLabel}}</span>
            </div>
            <div class="binds-layout-item alignSpecialLabels">
            <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.endLabel}}</span>
            </div>
          </div>
        </div>
        <!-- UI 10num -->
        <div class="animated-faster zoomIn" v-if="data.question.ui === '10num'">
          <div class="binds-layout align-buttons">
            <div class="responsiveAlign  content-buttons-num" v-for="(label, index) in data.question.options" v-bind:key="index">
              <binds-button class="spacing"
              :binds-ripple="false"
              v-on:click="postResponse(data.question.options[index])"
              :style="returnStyle(index)"
                :value="data.question.options[index].rating">
                {{data.question.options[index].label}}
                </binds-button>
            </div>
          </div>
          <div class="binds-layout binds-gutter">
            <div class="binds-layout-item">
              <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.startLabel}}</span>
            </div>
            <div class="binds-layout-item alignSpecialLabels">
            <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.endLabel}}</span>
            </div>
          </div>
        </div>
        <!-- UI 5emo -->
        <div v-if="data.question.ui === '5emo'" class="content-5num ">
          <binds-card class="animated-faster zoomIn" binds-with-hover v-for="(item,index) in data5emo" v-on:click.native="postResponse(item)" v-bind:key="index">
            <binds-card-content>
              <div class="data5emo">
                <i :class="item.icon" :style="{'color':item.color}"></i>
                <span>{{item.label}}</span>
              </div>
            </binds-card-content>
          </binds-card>
        </div>
      </binds-card-content>

    </binds-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsContent, BindsTabs, BindsButton, BindsCard } from '../bindsMaterial/components'
import { widgetService } from '@/_services'
import { formatButton } from '@/_helpers'

Vue.use(BindsContent)
Vue.use(BindsTabs)
Vue.use(BindsButton)
Vue.use(BindsCard)

export default {
  props: ['data'],
  data () {
    return {
      buttonEnabled: true,
      data5emo: [
        { icon: 'icon-joy-gee', label: this.$t('vs'), rating: 100, color: '#49BB59' },
        { icon: 'icon-happy-gee', label: this.$t('sat'), rating: 75, color: '#37B0E4' },
        { icon: 'icon-neutral-sleep', label: this.$t('ind'), rating: 50, color: '#9E9E9E' },
        { icon: 'icon-unhappy-sleep', label: this.$t('un'), rating: 25, color: '#F26C3C' },
        { icon: 'icon-wow-open', label: this.$t('vu'), rating: 0, color: '#EE2E35' }
      ]
    }
  },
  created () {
    this.$root.$emit('setLoading', false)
  },
  methods: {
    async postResponse (options) {
      const formatResponse = {
        rating: options.rating,
        value: options.label
      }
      if (this.data.question.ui === '10num') {
        formatResponse.rating = options.label * 10
      }
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, formatResponse)
        this.$root.$emit('questionUpdated', questionUpdated, options.rating)
      }
    },
    returnStyle (index) {
      return formatButton.returnStyleWithGradient(this.data, index)
    }
  }
}
</script>

<style lang="scss">
  .content-5num {
    padding: 0px;
  }
  .animated-faster .binds-card-content {
    text-align: -webkit-center;
  }

  .spacing {
    & button + button {
      margin-left: 2px !important;
    }
  }
  .alignSpecialLabels {
    text-align: right;
  }
</style>
