export default {
  bindsRounded: Boolean,
  bindsSize: {
    type: Number,
    default: 420
  },
  bindsIcon: String,
  bindsLabel: String,
  bindsDescription: String
}
