<template>
  <binds-drawer class="binds-app-drawer" :binds-active="bindsActive && initialized" v-bind="$attrs" v-on="$listeners" :binds-right="bindsRight" ref="drawer">
    <slot />
  </binds-drawer>
</template>

<script>
  export default {
    name: 'BindsAppDrawer',
    inject: ['BindsApp'],
    data: () => ({
      drawerElement: {
        bindsActive: null,
        mode: null,
        submode: null
      },
      initialized: false
    }),
    props: {
      bindsRight: {
        type: Boolean,
        default: false
      },
      bindsActive: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      visible () {
        return this.drawerElement.bindsActive
      },
      mode () {
        return this.drawerElement.mode
      },
      submode () {
        return this.drawerElement.submode
      },
    },
    watch: {
      visible (visible) {
        this.bindsApp.drawer.width = this.getDrawerWidth()
        this.bindsApp.drawer.active = visible
      },
      mode (mode) {
        this.bindsApp.drawer.mode = mode
      },
      submode (submode) {
        this.bindsApp.drawer.submode = submode
      },
      bindsRight (right) {
        this.bindsApp.drawer.right = right
      }
    },
    methods: {
      getDrawerWidth () {
        if (this.$el) {
          return window.getComputedStyle(this.$el).width
        }

        return 0
      },
      updateDrawerData () {
        this.BindsApp.drawer.width = this.getDrawerWidth()
        this.BindsApp.drawer.active = this.visible
        this.BindsApp.drawer.mode = this.mode
        this.BindsApp.drawer.submode = this.submode
        this.BindsApp.drawer.right = this.bindsRight
      },
      clearDrawerData () {
        this.BindsApp.drawer.width = 0
        this.BindsApp.drawer.active = false
        this.BindsApp.drawer.mode = 'temporary'
        this.BindsApp.drawer.submode = null
        this.BindsApp.drawer.initialWidth = 0
      },
    },
    mounted () {
      this.$nextTick().then(() => {
        this.BindsApp.drawer.initialWidth = this.$el.offsetWidth
        this.drawerElement = this.$refs.drawer
        this.updateDrawerData()
        this.initialized = true
      })
    },
    updated () {
      this.drawerElement = this.$refs.drawer
    },
    beforeDestroy () {
      this.clearDrawerData()
    }
  }
</script>
