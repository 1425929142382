<template>
  <binds-list-item class="binds-menu-item" v-bind="$attrs" :class="[itemClasses, $bindsActiveTheme]" :disabled="disabled" :tabindex="highlighted && -1" v-on="listeners">
    <slot />
  </binds-list-item>
</template>

<script>
  import BindsComponent from '../../core/BindsComponent'
  import BindsInteractionEvents from '../../core/utils/BindsInteractionEvents'
  import BindsListItem from '../BindsList/BindsListItem/BindsListItem'

  export default new BindsComponent({
    name: 'BindsMenuItem',
    props: {
      disabled: Boolean
    },
    inject: ['BindsMenu'],
    data: () => ({
      highlighted: false
    }),
    computed: {
      itemClasses () {
        return {
          'binds-highlight': this.highlighted
        }
      },
      listeners () {
        if (this.disabled) {
          return {}
        }

        if (!this.BindsMenu.closeOnSelect) {
          return this.$listeners
        }

        let listeners = {}
        let listenerNames = Object.keys(this.$listeners)

        listenerNames.forEach(listener => {
          if (BindsInteractionEvents.includes(listener)) {
            listeners[listener] = $event => {
              this.$listeners[listener]($event)
              this.closeMenu()
            }
          } else {
            listeners[listener] = this.$listeners[listener]
          }
        })

        return listeners
      }
    },
    methods: {
      closeMenu () {
        this.BindsMenu.active = false

        if (this.BindsMenu.eventObserver) {
          this.BindsMenu.eventObserver.destroy()
        }
      },

      triggerCloseMenu () {
        if (!this.disabled) {
          this.closeMenu()
        }
      }
    },
    mounted () {
      if (this.$el.children && this.$el.children[0]) {
        let listItem = this.$el.children[0]

        if (listItem.tagName.toUpperCase() === 'A') {
          this.$el.addEventListener('click', this.triggerCloseMenu)
        }
      }
    },

    beforeDestroy () {
      this.$el.removeEventListener('click', this.triggerCloseMenu)
    }
  })
</script>
