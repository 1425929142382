import material from '../../material.js'
import BindsIcon from '../BindsIcon'
import BindsSelect from './BindsSelect'
import BindsField from './BindsField'
import BindsFile from './BindsFile/BindsFile'
import BindsInput from './BindsInput/BindsInput'
import BindsTextarea from './BindsTextarea/BindsTextarea'

export default Vue => {
  material(Vue)
  Vue.use(BindsIcon)
  Vue.use(BindsSelect)
  Vue.component(BindsField.name, BindsField)
  Vue.component(BindsFile.name, BindsFile)
  Vue.component(BindsInput.name, BindsInput)
  Vue.component(BindsTextarea.name, BindsTextarea)
}
