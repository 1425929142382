<template>
  <div class="binds-table-pagination">
    <template v-if="bindsPageOptions !== false">
      <span class="binds-table-pagination-label">{{ bindsLabel }}</span>

      <binds-field>
        <binds-select v-model="currentPageSize" binds-dense binds-class="binds-pagination-select" @changed="setPageSize">
          <binds-option v-for="amount in bindsPageOptions" :key="amount" :value="amount">{{ amount }}</binds-option>
        </binds-select>
      </binds-field>
    </template>

    <span>{{ currentItemCount }}-{{ currentPageCount }} {{ bindsSeparator }} {{ bindsTotal }}</span>

    <binds-button class="binds-icon-button binds-table-pagination-previous" @click="goToPrevious()" :disabled="bindsPage === 1">
      <binds-icon>keyboard_arrow_left</binds-icon>
    </binds-button>

    <binds-button class="binds-icon-button binds-table-pagination-next" @click="goToNext()">
      <binds-icon>keyboard_arrow_right</binds-icon>
    </binds-button>
  </div>
</template>

<script>
  export default {
    name: 'BindsTablePagination',
    inject: ['BindsTable'],
    props: {
      bindsPageSize: {
        type: [String, Number],
        default: 10
      },
      bindsPageOptions: {
        type: Array,
        default: () => [10, 25, 50, 100]
      },
      bindsPage: {
        type: Number,
        default: 1
      },
      bindsTotal: {
        type: [String, Number],
        default: 'Many'
      },
      bindsLabel: {
        type: String,
        default: 'Rows per page:'
      },
      bindsSeparator: {
        type: String,
        default: 'of'
      }
    },
    data: () => ({
      currentPageSize: 0
    }),
    computed: {
      currentItemCount () {
        return ((this.bindsPage - 1) * this.bindsPageSize) + 1
      },
      currentPageCount () {
        return this.bindsPage * this.bindsPageSize
      }
    },
    watch: {
      bindsPageSize: {
        immediate: true,
        handler (pageSize) {
          this.currentPageSize = this.pageSize
        }
      }
    },
    methods: {
      setPageSize () {
        this.$emit('update:bindsPageSize', this.currentPageSize)
      },
      goToPrevious () {

      },
      goToNext () {

      }
    },
    created () {
      this.currentPageSize = this.bindsPageSize
    }
  }
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-table-pagination {
    height: 56px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid;
    font-size: 12px;

    .binds-table-pagination-previous {
      margin-right: 2px;
      margin-left: 18px;
    }

    .binds-field {
      width: 48px;
      min-width: 36px;
      margin: -16px 24px 0 32px;

      &:after,
      &:before {
        display: none;
      }

      .binds-select-value {
        font-size: 13px;
      }
    }
  }

  .binds-menu-content.binds-pagination-select {
    max-width: 82px;
    min-width: 56px;
    margin-top: 5px;
  }
</style>
