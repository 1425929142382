<template>
  <binds-button class="binds-speed-dial-target binds-fab" v-on="$listeners" v-bind="$attrs" @click="handleClick">
    <slot />
  </binds-button>
</template>

<script>
import BindsButton from '../BindsButton/BindsButton'

export default {
  name: 'BindsSpeedDialTarget',
  components: {
    BindsButton
  },
  inject: ['BindsSpeedDial'],
  methods: {
    handleClick () {
      if (this.BindsSpeedDial.event === 'click') {
        this.BindsSpeedDial.active = !this.BindsSpeedDial.active
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-speed-dial-target {
    z-index: 1;
  }
</style>
