<template>
  <binds-portal :binds-attach-to-parent="bindsAttachToParent">
    <transition name="binds-overlay">
      <div class="binds-overlay" :class="overlayClasses" v-on="$listeners" v-if="bindsActive"></div>
    </transition>
  </binds-portal>
</template>

<script>
import BindsPortal from '../BindsPortal/BindsPortal'

export default {
  name: 'BindsOverlay',
  components: {
    BindsPortal
  },
  props: {
    bindsActive: Boolean,
    bindsAttachToParent: Boolean,
    bindsFixed: Boolean
  },
  computed: {
    overlayClasses () {
      return {
        'binds-fixed': this.bindsFixed
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;
    background: rgba(#000, .6);
    transition: .35s $binds-transition-default-timing;
    transition-property: opacity;
    will-change: opacity;

    body > &,
    &.binds-fixed {
      position: fixed;
    }
  }

  .binds-overlay-enter,
  .binds-overlay-leave-active {
    opacity: 0;
  }
</style>
