<template>
  <div class="binds-card-media-actions">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsCardMediaActions'
}
</script>

<style lang="scss">
  .binds-card-media-actions {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    .binds-card-media {
      $size: 240px;

      max-width: $size;
      max-height: $size;
      flex: 1;
    }

    .binds-card-actions {
      margin-left: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .binds-button + .binds-button {
        margin: 8px 0 0;
      }
    }
  }
</style>
