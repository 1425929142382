<template>
  <div id="bindsApp">
    <!-- <br>Rota para debugar tudo, os parâmetros são enviados pelo arquivo App.vue<br> -->

    <Widget
      :hidde_when_click_to_close='hidde_when_click_to_close'
      :automatic_answer_first_question='automatic_answer_first_question'
      :hidde_after_answer='hidde_after_answer'
      :widget_position='widget_position'
      :width='width'
      :background='background'
      :buttons='buttons'
      :texts='texts'
      :timeout='timeout'
      :width_metric='width_metric'
      :survey_id='survey_id'
      :metadata='metadata'
      :text_buttons='text_buttons'
      :widget_title='widget_title'
      :is_to_post='is_to_post'
      :from='from'>
    </Widget>

  </div>
</template>
<script>
import Widget from './components/Widget'
export default {
  components: {
    Widget
  },
  props: [
    'widget_position',
    'widget_type',
    'height',
    'width',
    'background',
    'buttons',
    'texts',
    'timeout',
    'width_metric',
    'height_metric',
    'ui',
    'survey_id',
    'metadata',
    'text_buttons',
    'widget_title',
    'send_response',
    'hidde_after_answer',
    'hidde_when_click_to_close',
    'automatic_answer_first_question',
    'is_to_post',
    'from'
  ]
}
</script>
