<template>
  <binds-svg-loader
    class="binds-icon binds-icon-image"
    :binds-src="bindsSrc"
    :class="[$bindsActiveTheme]"
    v-if="bindsSrc"
    @binds-loaded="$emit('binds-loaded')"
  />
  <i class="binds-icon binds-icon-font" :class="[$bindsActiveTheme]" v-else>
    <slot />
  </i>
</template>

<script>
import BindsComponent from "../../core/BindsComponent";
import BindsSvgLoader from "../BindsSvgLoader/BindsSvgLoader";

export default new BindsComponent({
  name: "BindsIcon",
  components: {
    BindsSvgLoader
  },
  props: {
    bindsSrc: String
  }
});
</script>

<style lang="scss">
@import "../BindsAnimation/variables";
@import "./mixins";

$icon-size: 24px;

.binds-icon {
  @include binds-icon-size($icon-size);
  margin: auto;
  display: inline-flex;
  user-select: none;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  &.binds-size-2x {
    @include binds-icon-size($icon-size * 2);
  }

  &.binds-size-3x {
    @include binds-icon-size($icon-size * 3);
  }

  &.binds-size-4x {
    @include binds-icon-size($icon-size * 4);
  }

  &.binds-size-5x {
    @include binds-icon-size($icon-size * 5);
  }
}

.binds-icon-image {
  svg {
    height: 100%;
    flex: 1;
    transition: fill 0.4s $binds-transition-default-timing;
  }
}

.binds-icon {
  transition: color 0.4s $binds-transition-default-timing;
  direction: ltr;
  font-family: "Material Icons";
  font-feature-settings: "liga";
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  text-rendering: optimizeLegibility;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
