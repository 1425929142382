<template>
  <div class="binds-toolbar" :class="[$bindsActiveTheme, `binds-elevation-${bindsElevation}`]">
    <slot />
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsToolbar',
  props: {
    bindsElevation: {
      type: [String, Number],
      default: 4
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsLayout/mixins";
  @import "../BindsElevation/mixins";

  $binds-toolbar-height: 64px;
  $binds-toolbar-height-portrait: 56px;
  $binds-toolbar-height-landscape: 48px;
  $binds-toolbar-height-dense: 48px;

  .binds-toolbar,
  .binds-toolbar-row {
    width: 100%;
    min-height: $binds-toolbar-height;
    display: flex;
    align-items: center;
    align-content: center;
    transition: .3s $binds-transition-default-timing;
    transition-property: opacity, background-color, box-shadow, transform, color, min-height;
    will-change: opacity, background-color, box-shadow, transform, color, min-height;

    @include binds-layout-small {
      min-height: $binds-toolbar-height-landscape;
    }

    @include binds-layout-xsmall {
      min-height: $binds-toolbar-height-portrait;
    }
  }

  .binds-toolbar {
    padding: 0 16px;
    flex-flow: row wrap;
    position: relative;
    z-index: 2;

    @include binds-layout-small {
      padding: 0 8px;
    }

    &.binds-dense {
      min-height: $binds-toolbar-height-dense;
    }

    &.binds-medium,
    &.binds-large {
      .binds-toolbar-row {
        min-height: $binds-toolbar-height;
      }
    }

    &.binds-medium {
      min-height: 88px;
    }

    &.binds-large {
      min-height: 128px;
      align-content: inherit;

      &.binds-dense {
        min-height: 96px;

        .binds-toolbar-row + .binds-toolbar-row {
          min-height: 32px;
        }
      }
    }

    .binds-toolbar-offset {
      margin-left: 56px;

      @include binds-layout-small {
        margin-left: 48px;
      }
    }

    .binds-button,
    .binds-icon {
      z-index: 1;

      ~ .binds-title {
        margin-left: 24px;

        @include binds-layout-small {
          margin-left: 16px;
        }
      }
    }

    .binds-button {
      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .binds-display-2,
    .binds-display-1,
    .binds-title {
      margin: 0;
      margin-left: 8px;
      overflow: hidden;
      font-weight: 400;
      letter-spacing: .02em;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
    }

    .binds-display-1 {
      padding: 12px 0;
    }

    .binds-field {
      margin-top: 2px;
      margin-bottom: 14px;
      padding-top: 16px;
    }
  }

  .binds-toolbar-row {
    align-self: flex-start;
  }

  .binds-toolbar-section-start,
  .binds-toolbar-section-end {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .binds-toolbar-section-start {
    justify-content: flex-start;
    order: 0;
  }

  .binds-toolbar-section-end {
    justify-content: flex-end;
    order: 10;
  }
</style>
