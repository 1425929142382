<script>
import Popper from 'popper.js'
import deepmerge from 'deepmerge'
import BindsPortal from '../BindsPortal/BindsPortal'

export default {
  name: 'BindsPopover',
  abstract: true,
  components: {
    BindsPortal
  },
  props: {
    bindsActive: Boolean,
    bindsSettings: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    popperInstance: null,
    originalParentEl: null,
    shouldRender: false,
    shouldActivate: false
  }),
  computed: {
    popoverClasses () {
      if (this.shouldActivate) {
        return 'binds-active'
      } else if (this.shouldRender) {
        return 'binds-rendering'
      }
    }
  },
  watch: {
    bindsActive: {
      immediate: true,
      handler (shouldRender) {
        this.shouldRender = shouldRender

        if (shouldRender) {
          this.bindPopper()
        } else {
          this.shouldActivate = false
        }
      }
    },
    bindsSettings () {
      if (this.popperInstance) {
        this.createPopper()
      }
    }
  },
  methods: {
    getPopperOptions () {
      return {
        placement: 'bottom',
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
            padding: 16
          },
          computeStyle: {
            gpuAcceleration: false
          }
        },
        onCreate: () => {
          this.shouldActivate = true
          this.$emit('binds-active')
        }
      }
    },
    setOriginalParent (el) {
      if (!this.originalParentEl) {
        this.originalParentEl = el
      }
    },
    killPopper () {
      if (this.popperInstance) {
        this.popperInstance.destroy()
        this.popperInstance = null
      }
    },
    bindPopper () {
      this.$nextTick().then(() => {
        if (this.originalParentEl) {
          this.createPopper()
        }
      })
    },
    createPopper () {
      if (this.bindsSettings) {
        const options = deepmerge(this.getPopperOptions(), this.bindsSettings)

        if (this.$el.nodeType !== Node.COMMENT_NODE) {
          this.popperInstance = new Popper(this.originalParentEl, this.$el, options)
        }
      }
    },
    resetPopper () {
      if (this.popperInstance) {
        this.killPopper()
        this.createPopper()
      }
    }
  },
  beforeDestroy () {
    this.killPopper()
  },
  mounted () {
    this.resetPopper()
  },
  render (createElement) {
    return createElement(BindsPortal, {
      props: {
        ...this.$attrs
      },
      on: {
        ...this.$listeners,
        'binds-initial-parent': this.setOriginalParent,
        'binds-destroy': this.killPopper
      }
    }, this.$slots.default)
  }
}
</script>

<style lang="scss">
  .binds-popover {
    &.binds-rendering {
      opacity: 0;
      transition: none !important;
    }
  }
</style>
