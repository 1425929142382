<template>
  <binds-popover :binds-settings="popperSettings" :binds-active="shouldRender">
    <transition name="binds-tooltip" v-if="shouldRender">
      <div class="binds-tooltip" :class="[tooltipClasses, $bindsActiveTheme]" :style="tooltipStyles">
        <slot />
      </div>
    </transition>
  </binds-popover>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsPropValidator from '../../core/utils/BindsPropValidator'
import BindsPopover from '../BindsPopover/BindsPopover'

export default new BindsComponent({
  name: 'BindsTooltip',
  components: {
    BindsPopover
  },
  props: {
    bindsActive: Boolean,
    bindsDelay: {
      type: [String, Number],
      default: 0
    },
    bindsDirection: {
      type: String,
      default: 'bottom',
      ...BindsPropValidator('binds-direction', ['top', 'right', 'bottom', 'left'])
    }
  },
  data: () => ({
    shouldRender: false,
    targetEl: null
  }),
  computed: {
    tooltipClasses () {
      return 'binds-tooltip-' + this.bindsDirection
    },
    tooltipStyles () {
      return `transition-delay: ${this.bindsDelay}ms`
    },
    popperSettings () {
      return {
        placement: this.bindsDirection,
        modifiers: {
          offset: {
            offset: '0, 16'
          }
        }
      }
    }
  },
  watch: {
    bindsActive () {
      this.shouldRender = this.bindsActive
    },
    shouldRender (shouldRender) {
      this.$emit('update:bindsActive', shouldRender)
    }
  },
  methods: {
    show () {
      this.shouldRender = true
    },
    hide () {
      this.shouldRender = false
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.shouldRender = this.bindsActive
      this.targetEl = this._vnode.componentInstance.originalParentEl

      if (this.targetEl) {
        this.targetEl.addEventListener('mouseenter', this.show, false)
        this.targetEl.addEventListener('mouseleave', this.hide, false)
      }
    })
  },
  beforeDestroy () {
    if (this.targetEl) {
      this.targetEl.removeEventListener('mouseenter', this.show)
      this.targetEl.removeEventListener('mouseleave', this.hide)
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsLayout/mixins";

  $binds-tooltip-height: 22px;
  $binds-tooltip-height-mobile: 32px;

  .binds-tooltip {
    height: $binds-tooltip-height;
    padding: 0 8px;
    position: fixed;
    z-index: 111;
    pointer-events: none;
    border-radius: 2px;
    transition: .15s $binds-transition-enter-timing;
    transition-property: opacity, transform;
    will-change: opacity, transform, top, left !important;
    font-size: 10px;
    line-height: $binds-tooltip-height;
    text-transform: none;
    white-space: nowrap;

    @include binds-layout-small {
      height: $binds-tooltip-height-mobile;
      font-size: 14px;
      line-height: $binds-tooltip-height-mobile;
    }

    &.binds-tooltip-leave-active {
      transition-timing-function: $binds-transition-leave-timing;
    }

    &.binds-tooltip-enter,
    &.binds-tooltip-leave-active {
      opacity: 0;

      &.binds-tooltip-top {
        transform: translate3d(0, 4px, 0) scale(.95);
      }

      &.binds-tooltip-right {
        transform: translate3d(-4px, 0, 0) scale(.95);
      }

      &.binds-tooltip-bottom {
        transform: translate3d(0, -4px, 0) scale(.95);
      }

      &.binds-tooltip-left {
        transform: translate3d(4px, 0, 0) scale(.95);
      }
    }
  }
</style>
