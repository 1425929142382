<template>
  <div class="wid-card">
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric,
      'height': data.height + data.heightMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">
      <binds-card-content>
        <!-- UI ynm -->
        <div class="wid-buttons binds-alignment-horizontal-center" v-if="data.question.ui === 'ynm'">
          <div>
            <binds-button class="binds-raised yes" v-on:click="postResponse(ynm.options[0])">{{$t('op1')}}</binds-button>
          </div>
          <div>
            <binds-button class="binds-raised maybe" v-on:click="postResponse(ynm.options[1])">{{$t('op4')}}</binds-button>
          </div>
          <div>
            <binds-button class="binds-raised no" v-on:click="postResponse(ynm.options[2])">{{$t('op2')}}</binds-button>
          </div>
        </div>
       <!-- UI 10num -->
        <div v-if="data.question.ui === '10num'">
          <div class="binds-layout align-buttons">
            <div class="responsiveAlign content-buttons-num" v-for="(label, index) in options" v-bind:key="index">
              <binds-button :binds-ripple="false" v-on:click="postResponse(options[index])" :style="returnStyle(index)" 
                style="max-width: 90%"
                :value="options[index].rating">
                {{options[index].label}}
                </binds-button>
            </div>
          </div>
          <div class="binds-layout">
            <div class="binds-layout-item">
              <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.startLabel}}</span>
            </div>
            <div class="binds-layout-item alignSpecialLabels">
            <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.endLabel}}</span>
            </div>
          </div>
        </div>
      </binds-card-content>
    </binds-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsContent, BindsTabs, BindsButton, BindsCard } from '../bindsMaterial/components'
import { widgetService } from '@/_services'
import { formatButton } from '@/_helpers'

Vue.use(BindsContent)
Vue.use(BindsTabs)
Vue.use(BindsButton)
Vue.use(BindsCard)

export default {
  props: ['data'],
  data () {
    return {
      ynm: {
        options: [
          { rating: 95 },
          { rating: 75 },
          { rating: 5 }
        ]
      },
      options: [
        { rating: 0, label: '0' },
        { rating: 10, label: '1' },
        { rating: 20, label: '2' },
        { rating: 30, label: '3' },
        { rating: 40, label: '4' },
        { rating: 50, label: '5' },
        { rating: 60, label: '6' },
        { rating: 70, label: '7' },
        { rating: 80, label: '8' },
        { rating: 90, label: '9' },
        { rating: 100, label: '10' }
      ]
    }
  },
  methods: {
    async postResponse (options) {
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, options)
        this.$root.$emit('questionUpdated', questionUpdated, options.rating)
      }
    },
    returnStyle (index) {
      this.data.question.options = this.options
      return formatButton.returnStyleWithGradient(this.data, index)
    }
  },
  mounted () {
    this.$root.$emit('setLoading', false)
  }
}
</script>

<style lang="scss">
  .elevation-demo {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  .binds-content {
    width: 100px;
    height: 100px;
    margin: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wid-buttons {
    margin-left: 30%;
    & .binds-button {
      min-width: 150px;
    }
    & .binds-button-content {
      color: #fff;
    }
  }
  .wid-card {
    & .binds-card {
      width: 600px;
    }
  }
  .yes {
    background-color: #03B771 !important;
  }
  .maybe {
    background-color: rgb(155, 153, 153) !important;
  }
  .no {
    background-color: #FC6C5D !important;
  }
  .mt30 {
    margin-top: 30px;
  }

  .content-buttons-num .binds-button{
    min-width: 10px;
    margin: 0;
  }
</style>
