import ptBR from './pt-BR'
import enUS from './en-US'
import esCL from './es-CL'
const initial = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-CL': esCL,
  'pt-br': ptBR,
  'en-us': enUS,
  'es-cl': esCL
}
export default initial
