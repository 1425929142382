<template>
  <div class="binds-list-item-expand" :class="expandClasses">
    <binds-list-item-content :binds-disabled="isDisabled" @click.native="toggleExpand">
      <slot />

      <binds-arrow-down-icon class="binds-list-expand-icon" />
    </binds-list-item-content>

    <div class="binds-list-expand" ref="listExpand" :style="expandStyles">
      <slot name="binds-expand" />
    </div>
  </div>
</template>

<script>
import raf from 'raf'
import BindsArrowDownIcon from '../../../core/icons/BindsArrowDownIcon'
import BindsListItemMixin from './BindsListItemMixin'

export default {
  name: 'BindsListItemExpand',
  components: {
    BindsArrowDownIcon
  },
  mixins: [BindsListItemMixin],
  inject: ['BindsList'],
  data: () => ({
    expandStyles: {},
    showContent: false
  }),
  props: {
    bindsExpanded: Boolean
  },
  computed: {
    expandClasses () {
      return {
        'binds-active': this.showContent
      }
    }
  },
  methods: {
    getChildrenSize () {
      const expandEl = this.$refs.listExpand
      let size = 0

      Array.from(expandEl.children).forEach(child => {
        size += child.offsetHeight
      })

      return size
    },
    fetchStyle () {
      return new Promise(resolve => {
        raf(() => {
          let fullHeight = 0

          if (!this.showContent) {
            fullHeight = 'auto' // this.getChildrenSize() + 'px'
          }

          this.expandStyles = { height: fullHeight }
          resolve()
        })
      })
    },
    toggleExpand () {
      this.fetchStyle().then(() => {
        this.showContent = !this.showContent
      })
    },
    open () {
      if (this.showContent) {
        return false
      }

      this.fetchStyle().then(() => [
        this.showContent = true
      ])
    },
    close () {
      if (!this.showContent) {
        return false
      }

      this.fetchStyle().then(() => {
        this.showContent = false
      })
    }
  },
  watch: {
    bindsExpanded () {
      if (this.bindsExpanded) {
        this.open()
      } else {
        this.close()
      }
    },
    showContent () {
      let expanded = this.showContent
      this.$emit('update:bindsExpanded', expanded)
      this.$nextTick(() => this.$emit(expanded ? 'binds-expanded' : 'binds-collapsed'))

      if (expanded) {
        this.BindsList.expandATab(this)
      }
    }
  },
  created () {
    this.BindsList.pushExpandable(this)
  },
  mounted () {
    if (this.bindsExpanded) {
      this.open()
    }
  },
  beforeDestroy () {
    this.BindsList.removeExpandable(this)
  }
}
</script>

<style lang="scss">
  @import "../../BindsAnimation/variables.scss";

  .binds-list-item-expand {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: border .4s $binds-transition-stand-timing;
    will-change: border;

    &.binds-active {
      > .binds-list-item-content > .binds-list-expand-icon {
        perspective: 1000px;
        perspective-origin: 50% 50%;
        transform: rotateX(180deg);
      }

      .binds-list-expand {
        opacity: 1;
        transform: translate3D(0, 0, 0);
      }
    }

    .binds-list-expand {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translate3D(0, -24px, 0);
      transition: .4s $binds-transition-stand-timing;
      transition-property: transform, opacity;
      will-change: transform, opacity;
    }

    .binds-list-expand-icon {
      transition: transform .4s $binds-transition-stand-timing;
      will-change: transform;
    }
  }
</style>
