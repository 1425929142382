<template>
  <transition name="binds-progress-bar" appear>
    <div class="binds-progress-bar" :class="[progressClasses, $bindsActiveTheme]">
      <div class="binds-progress-bar-track" :style="progressTrackStyle"></div>
      <div class="binds-progress-bar-fill" :style="progressValueStyle"></div>
      <div class="binds-progress-bar-buffer" :Style="progressBufferStyle"></div>
    </div>
  </transition>
</template>

<script>
import BindsComponent from '../../../core/BindsComponent'
import BindsPropValidator from '../../../core/utils/BindsPropValidator'

export default new BindsComponent({
  name: 'BindsProgressBar',
  props: {
    bindsValue: {
      type: Number,
      default: 0
    },
    bindsBuffer: {
      type: Number,
      default: 0
    },
    bindsMode: {
      type: String,
      default: 'determinate',
      ...BindsPropValidator('binds-mode', [
        'determinate',
        'indeterminate',
        'query',
        'buffer'
      ])
    }
  },
  computed: {
    isDeterminate () {
      return this.bindsMode === 'determinate'
    },
    isBuffer () {
      return this.bindsMode === 'buffer'
    },
    hasAmountFill () {
      return this.isBuffer || this.isDeterminate
    },
    progressClasses () {
      return 'binds-' + this.bindsMode
    },
    progressValueStyle () {
      if (this.hasAmountFill) {
        return `width: ${this.bindsValue}%`
      }
    },
    progressTrackStyle () {
      if (this.hasAmountFill) {
        return `width: ${this.bindsBuffer}%`
      }
    },
    progressBufferStyle () {
      if (this.hasAmountFill) {
        return `left: calc(${this.bindsBuffer}% + 8px)`
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../../BindsAnimation/variables";

  @keyframes binds-progress-bar-indeterminate-track {
    0% {
      transform: translateX(0)
    }

    20% {
      animation-timing-function: cubic-bezier(.5, 0, .70, .5);
      transform: translateX(0)
    }

    60% {
      animation-timing-function: cubic-bezier(.30, .38, .55, .96);
      transform: translateX(83.67%)
    }

    100% {
      transform: translateX(200.61%)
    }
  }

  @keyframes binds-progress-bar-indeterminate-track-alternate {
    0% {
      transform: scaleX(.08)
    }

    35% {
      animation-timing-function: cubic-bezier(.33, .12, .79, 1);
      transform: scaleX(.08)
    }

    70% {
      animation-timing-function: cubic-bezier(.06, .11, .6, 1);
      transform: scaleX(.66)
    }

    100% {
      transform: scaleX(.08)
    }
  }

  @keyframes binds-progress-bar-indeterminate-fill {
    0% {
      animation-timing-function: cubic-bezier(.15, 0, .52, .41);
      transform: translateX(0)
    }

    25% {
      animation-timing-function: cubic-bezier(.31, .28, .8, .73);
      transform: translateX(37.65%)
    }

    50% {
      animation-timing-function: cubic-bezier(.4, .63, .6, .90);
      transform: translateX(84.39%)
    }

    100% {
      transform: translateX(160.28%)
    }
  }

  @keyframes binds-progress-bar-indeterminate-fill-alternate {
    0% {
      animation-timing-function: cubic-bezier(.15, 0, .52, .41);
      transform: scaleX(.08)
    }

    20% {
      animation-timing-function: cubic-bezier(.31, .28, .8, .73);
      transform: scaleX(.46)
    }

    45% {
      animation-timing-function: cubic-bezier(.4, .63, .6, .90);
      transform: scaleX(.73)
    }

    100% {
      transform: scaleX(.08)
    }
  }

  @keyframes binds-progress-bar-buffer {
    to {
      transform: translate3D(-8px, 0, 0);
    }
  }

  .binds-progress-bar {
    height: 5px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0) scaleY(1);
    transform-origin: center center;
    transition: opacity .3s $binds-transition-default-timing,
                transform .4s $binds-transition-default-timing;
    will-change: opacity, transform;

    &.binds-indeterminate,
    &.binds-query {
      .binds-progress-bar-track {
        left: -150%;
        animation: binds-progress-bar-indeterminate-track 2s infinite linear;

        &:after {
          animation: binds-progress-bar-indeterminate-track-alternate 2s infinite linear;
        }
      }

      .binds-progress-bar-fill {
        left: -55%;
        animation: binds-progress-bar-indeterminate-fill 2s infinite linear;

        &:after {
          animation: binds-progress-bar-indeterminate-fill-alternate 2s infinite linear;
        }
      }
    }

    &.binds-determinate,
    &.binds-buffer {
      .binds-progress-bar-fill,
      .binds-progress-bar-track,
      .binds-progress-bar-buffer {
        transition: .25s $binds-transition-stand-timing;
      }
    }

    &.binds-determinate {
      .binds-progress-bar-track {
        display: none;
      }
    }

    &.binds-buffer {
      .binds-progress-bar-buffer {
        border-top: 4px dotted;
        animation: binds-progress-bar-buffer .25s infinite linear;
      }
    }

    &.binds-query {
      transform: rotateZ(180deg);
    }
  }

  .binds-progress-bar-enter,
  .binds-progress-bar-leave-active {
    opacity: .5;
    transform: translateZ(0) scaleY(0);
  }

  .binds-progress-bar-buffer,
  .binds-progress-bar-track,
  .binds-progress-bar-fill {
    transform-origin: top left;

    &,
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      will-change: transform;
    }

    &:after {
      display: inline-block;
      left: 0;
      content: " "
    }
  }
</style>
