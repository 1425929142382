<template>
  <li class="binds-subheader" :class="[$bindsActiveTheme]" v-if="insideList">
    <slot />
  </li>

  <div class="binds-subheader" :class="[$bindsActiveTheme]" v-else>
    <slot />
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'

export default new BindsComponent({
  name: 'BindsSubheader',
  computed: {
    insideList () {
      return this.$parent.$options._componentTag === 'binds-list'
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-subheader {
    min-height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    font-size: 14px;
    font-weight: 500;
  }
</style>
