<template>
  <binds-table-head class="binds-table-cell-selection" v-if="selectableCount">
    <div class="binds-table-cell-container">
      <binds-checkbox :model="allSelected" :disabled="isDisabled" @change="onChange" />
    </div>
  </binds-table-head>
</template>

<script>
  import BindsTableHead from './BindsTableHead'

  export default {
    name: 'BindsTableHeadSelection',
    components: {
      BindsTableHead
    },
    inject: ['BindsTable'],
    computed: {
      selectableCount () {
        return Object.keys(this.selectable).length
      },
      isDisabled () {
        return !this.selectableCount
      },
      selectable () {
        return this.BindsTable.selectable
      },
      selectedItems () {
        return this.BindsTable.selectedItems
      },
      allSelected () {
        if (this.selectableCount === 0) {
          return false
        }

        return this.selectable.every(item => this.selectedItems.includes(item))
      }
    },
    methods: {
      onChange (val) {
        if (val) {
          this.BindsTable.selectedItems = this.selectedItems.concat(this.selectable.filter(item => !this.selectedItems.includes(item)))
        } else {
          this.BindsTable.selectedItems = this.selectedItems.filter(item => !this.selectable.includes(item))
        }
      }
    }
  }
</script>
