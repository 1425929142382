<script>
  export default {
    name: 'BindsTableRowGhost',
    props: {
      bindsIndex: [String, Number],
      bindsId: [String, Number],
      bindsItem: [Array, Object]
    },
    render () {
      this.$slots.default[0].componentOptions.propsData.bindsIndex = this.bindsIndex
      this.$slots.default[0].componentOptions.propsData.bindsId = this.bindsId
      this.$slots.default[0].componentOptions.propsData.bindsItem = this.bindsItem

      return this.$slots.default[0]
    }
  }
</script>
