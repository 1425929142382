<template>
  <thead>
    <tr>
      <binds-table-head-selection />
      <binds-table-head v-for="(item, index) in BindsTable.items" :key="index" v-bind="item" />
    </tr>
  </thead>
</template>

<script>
  import BindsTableHead from './BindsTableHead'
  import BindsTableHeadSelection from './BindsTableHeadSelection'

  export default {
    name: 'BindsTableThead',
    inject: ['BindsTable'],
    components: {
      BindsTableHead,
      BindsTableHeadSelection
    }
  }
</script>
