<template>
  <div class="binds-dialog-actions">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsDialogActions'
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-dialog-actions {
    min-height: 52px;
    padding: 8px 8px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    &:before {
      height: 1px;
      position: absolute;
      top: -1px;
      right: 0;
      left: 0;
      content: " ";
    }

    .binds-button {
      min-width: 64px;
      margin: 0;

      + .binds-button {
        margin-left: 8px;
      }
    }
  }
</style>
