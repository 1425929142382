var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"binds-tabs",class:[_vm.tabsClasses, _vm.$bindsActiveTheme]},[_c('div',{ref:"navigation",staticClass:"binds-tabs-navigation",class:_vm.navigationClasses},[_vm._l((_vm.BindsTabs.items),function(ref,index){
var label = ref.label;
var props = ref.props;
var icon = ref.icon;
var disabled = ref.disabled;
var data = ref.data;
var events = ref.events;
return _c('binds-button',_vm._g(_vm._b({key:index,staticClass:"binds-tab-nav-button",class:{
        'binds-active': (!_vm.bindsSyncRoute && index === _vm.activeTab),
        'binds-icon-label': icon && label
      },attrs:{"disabled":disabled},nativeOn:{"click":function($event){return _vm.setActiveTab(index)}}},'binds-button',props,false),events),[(_vm.$scopedSlots['binds-tab'])?_vm._t("binds-tab",null,{"tab":{ label: label, icon: icon, data: data }}):[(!icon)?[_vm._v(_vm._s(label))]:[(_vm.isAssetIcon(icon))?_c('binds-icon',{staticClass:"binds-tab-icon",attrs:{"binds-src":icon}}):_c('binds-icon',{staticClass:"binds-tab-icon"},[_vm._v(_vm._s(icon))]),_c('span',{staticClass:"binds-tab-label"},[_vm._v(_vm._s(label))])]]],2)}),_c('span',{ref:"indicator",staticClass:"binds-tabs-indicator",class:_vm.indicatorClass,style:(_vm.indicatorStyles)})],2),_c('binds-content',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasContent),expression:"hasContent"}],ref:"tabsContent",staticClass:"binds-tabs-content",style:(_vm.contentStyles)},[_c('div',{staticClass:"binds-tabs-container",style:(_vm.containerStyles)},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }