<template>
  <a class="binds-list-item-link" v-bind="$props">
    <binds-list-item-content :binds-disabled="isDisabled">
      <slot />
    </binds-list-item-content>
  </a>
</template>

<script>
import BindsListItemMixin from './BindsListItemMixin'

export default {
  name: 'BindsListItemLink',
  mixins: [BindsListItemMixin],
  props: {
    download: String,
    href: String,
    hreflang: String,
    ping: String,
    rel: String,
    target: String,
    type: String
  }
}
</script>
