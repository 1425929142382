<template>
  <binds-field
    :class="['binds-datepicker', { 'binds-native': !this.bindsOverrideNative }]"
    binds-clearable
  >
    <binds-date-icon class="binds-date-icon" @click.native="toggleDialog" />
    <binds-input
      :type="type"
      ref="input"
      v-model="inputDate"
      @focus.native="onFocus"
      :pattern="pattern"
    />

    <slot />

    <keep-alive>
      <binds-datepicker-dialog
        v-if="showDialog"
        :binds-date.sync="localDate"
        :binds-disabled-dates="bindsDisabledDates"
        :bindsImmediately="bindsImmediately"
        @binds-closed="toggleDialog"
      />
    </keep-alive>

    <binds-overlay
      class="binds-datepicker-overlay"
      binds-fixed
      :binds-active="showDialog"
      @click="toggleDialog"
    />
  </binds-field>
</template>

<script>
import Vue from "vue";
import isFirefox from "is-firefox";
import format from "date-fns/format";
import parse from "date-fns/parse";
import isValid from "date-fns/isValid";
import BindsPropValidator from "../../core/utils/BindsPropValidator";
import BindsOverlay from "../BindsOverlay/BindsOverlay";
import BindsDatepickerDialog from "./BindsDatepickerDialog";
import BindsDateIcon from "../../core/icons/BindsDateIcon";
import BindsDebounce from "../../core/utils/BindsDebounce";
import BindsField from "../BindsField/BindsField";
import BindsInput from "../BindsField/BindsInput/BindsInput";

export default {
  name: "BindsDatepicker",
  components: {
    BindsOverlay,
    BindsDateIcon,
    BindsField,
    BindsInput,
    BindsDatepickerDialog
  },
  props: {
    value: [String, Number, Date],
    bindsDisabledDates: [Array, Function],
    bindsOpenOnFocus: {
      type: Boolean,
      default: true
    },
    bindsOverrideNative: {
      type: Boolean,
      default: true
    },
    bindsImmediately: {
      type: Boolean,
      default: false
    },
    bindsModelType: {
      type: Function,
      default: Date,
      ...BindsPropValidator("binds-model-type", [Date, String, Number])
    },
    BindsDebounce: {
      type: Number,
      default: 1000
    }
  },
  data: () => ({
    showDialog: false,
    // String for input
    inputDate: "",
    // Date for real value
    localDate: null
  }),
  computed: {
    locale() {
      return this.$material.locale;
    },
    type() {
      return this.bindsOverrideNative ? "text" : "date";
    },
    dateFormat() {
      return this.locale.dateFormat || "yyyy-MM-dd";
    },
    modelType() {
      if (this.isModelTypeString) {
        return String;
      } else if (this.isModelTypeNumber) {
        return Number;
      } else if (this.isModelTypeDate) {
        return Date;
      } else {
        return this.bindsModelType;
      }
    },
    isModelNull() {
      return this.value === null || this.value === undefined;
    },
    isModelTypeString() {
      return typeof this.value === "string";
    },
    isModelTypeNumber() {
      return Number.isInteger(this.value) && this.value >= 0;
    },
    isModelTypeDate() {
      return (
        typeof this.value === "object" &&
        this.value instanceof Date &&
        isValid(this.value)
      );
    },
    localString() {
      return this.localDate && format(this.localDate, this.dateFormat);
    },
    localNumber() {
      return this.localDate && Number(this.localDate);
    },
    parsedInputDate() {
      const parsedDate = parse(this.inputDate, this.dateFormat, new Date());
      return parsedDate && isValid(parsedDate) ? parsedDate : null;
    },
    pattern() {
      return this.dateFormat.replace(/yyyy|MM|dd/g, match => {
        switch (match) {
          case "yyyy":
            return "[0-9]{4}";
          case "MM":
          case "dd":
            return "[0-9]{2}";
        }
      });
    }
  },
  watch: {
    inputDate(value) {
      this.inputDateToLocalDate();
    },
    localDate() {
      this.inputDate = this.localString;
      if (this.modelType === Date) {
        this.$emit("input", this.localDate);
      }
    },
    localString() {
      if (this.modelType === String) {
        this.$emit("input", this.localString);
      }
    },
    localNumber() {
      if (this.modelType === Number) {
        this.$emit("input", this.localNumber);
      }
    },
    value: {
      immediate: true,
      handler() {
        this.valueDateToLocalDate();
      }
    },
    bindsModelType(type) {
      switch (type) {
        case Date:
          this.$emit("input", this.localDate);
          break;
        case String:
          this.$emit("input", this.localString);
          break;
        case Number:
          this.$emit("input", this.localNumber);
          break;
      }
    },
    dateFormat() {
      if (this.localDate) {
        this.inputDate = format(this.localDate, this.dateFormat);
      }
    }
  },
  methods: {
    toggleDialog() {
      if (!isFirefox || this.bindsOverrideNative) {
        this.showDialog = !this.showDialog;
        if (this.showDialog) {
          this.$emit("binds-opened");
        } else {
          this.$emit("binds-closed");
        }
      } else {
        this.$refs.input.$el.click();
      }
    },
    onFocus() {
      if (this.bindsOpenOnFocus) {
        this.toggleDialog();
      }
    },
    inputDateToLocalDate() {
      if (this.inputDate) {
        if (this.parsedInputDate) {
          this.localDate = this.parsedInputDate;
        }
      } else {
        this.localDate = null;
      }
    },
    valueDateToLocalDate() {
      if (this.isModelNull) {
        this.localDate = null;
      } else if (this.isModelTypeNumber) {
        this.localDate = new Date(this.value);
      } else if (this.isModelTypeDate) {
        this.localDate = this.value;
      } else if (this.isModelTypeString) {
        let parsedDate = parse(this.value, this.dateFormat, new Date());

        if (isValid(parsedDate)) {
          this.localDate = parse(this.value, this.dateFormat, new Date());
        } else {
          Vue.util.warn(
            `The datepicker value is not a valid date. Given value: ${this.value}, format: ${this.dateFormat}`
          );
        }
      } else {
        Vue.util.warn(
          `The datepicker value is not a valid date. Given value: ${this.value}`
        );
      }
    }
  },
  created() {
    this.inputDateToLocalDate = BindsDebounce(
      this.inputDateToLocalDate,
      this.BindsDebounce
    );
  }
};
</script>

<style lang="scss">
@import "../BindsAnimation/variables";
@import "../BindsLayout/mixins";

.binds-datepicker-overlay {
  opacity: 0;

  @include binds-layout-xsmall {
    opacity: 1;
  }
}

.binds-datepicker {
  &.binds-native {
    label {
      top: 0 !important;
    }
  }

  .binds-date-icon {
    cursor: pointer;
  }

  input[type="date"]::-webkit-clear-button,
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}
</style>
