<template>
  <div class="binds-card-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsCardContent'
}
</script>

<style lang="scss">
  .binds-card-content {
    padding: 16px;
    font-size: 14px;
    line-height: 22px;

    &:last-of-type {
      padding-bottom: 24px;
    }
  }
</style>
