import i18n from '../translations/i18n'

const mappedOptions = {
  nps: {
    ynm: [95, 75, 5],
    '10num': [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  },
  csat: {
    '5emo': [100, 75, 50, 25, 0],
    '10num': [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    '5num': [100, 75, 50, 25, 0]
  },
  nes: {
    '10num': [100, 75, 50, 25, 0]
  },
  ces: {
    '1to7button': [0, 15, 30, 50, 65, 80, 100],
    '1to7': [0, 15, 30, 50, 65, 80, 100]
  },
  kpi: {
    yn: [0, 100],
    '5emo': [100, 75, 50, 25, 0],
    ynd: [100, 50, 0]
  },
  enum: {
    '10num': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  },
  nvs: {
    '5radio': [100, 75, 50, 25, 0]
  }
}

function getOptions (question) {
  const type = question.type
  const ui = question.ui
  let options = []

  // CSAT options
  if (type === 'csat') {
    if (ui === '5emo') {
      // Muito satisfeito, satisfeito, indiferente, insatisfeito, muito insatisfeito.
      options = [
        { label: i18n.t('vs'), icon: '\uE603', rating: '100' },
        { label: i18n.t('sat'), icon: '\uE600', rating: '75' },
        { label: i18n.t('ind'), icon: '\uE606', rating: '50' },
        { label: i18n.t('un'), icon: '\uE607', rating: '25' },
        { label: i18n.t('vu'), icon: '\uE608', rating: '0' }
      ]
    }
    if (ui === '10num') {
      options = [...Array(11).keys()].map(item => {
        return { label: item, rating: (item * 10).toString() }
      })
    }
    if (ui === '5num') {
      options = [...Array(5).keys()].map(item => {
        return { label: item + 1, rating: (item * 25).toString() }
      })
    }
  }
  // CSAT options - END

  // NPS options
  if (type === 'nps') {
    if (ui === 'ynm') {
      options = [
        { label: i18n.t('op1'), value: '95' },
        { label: i18n.t('op2'), value: '5' },
        { label: i18n.t('op4'), value: '75' }
      ]
    }
    if (ui === '10num') {
      options = [...Array(11).keys()].map(item => { return { label: item, rating: (item * 10).toString() } })
    }
  }
  // NPS options - END

  // NES ou CES options
  if (type === 'nes') {
    if (ui === '10num') {
      options = [...Array(5).keys()].map(item => { return { label: item + 1, rating: (item * 25).toString() } })
    }
  }
  if (type === 'ces') {
    if (ui === '1to7') {
      options = [
        { label: '1', rating: '0' },
        { label: '2', rating: '15' },
        { label: '3', rating: '30' },
        { label: '4', rating: '50' },
        { label: '5', rating: '65' },
        { label: '6', rating: '80' },
        { label: '7', rating: '100' }
      ]
    }
    if (ui === '1to7button') {
      options = [
        { label: i18n.t('ces.totally_disagree'), rating: '0' },
        { label: i18n.t('ces.disagree'), rating: '15' },
        { label: i18n.t('ces.partially_disagree'), rating: '30' },
        { label: i18n.t('ces.neutral'), rating: '50' },
        { label: i18n.t('ces.partially_agree'), rating: '65' },
        { label: i18n.t('ces.agree'), rating: '80' },
        { label: i18n.t('ces.totally_agree'), rating: '100' }
      ]
    }
  }
  // NES ou CES options - END

  // NVS options
  if (type === 'nvs') {
    options = [
      { label: i18n.t('nvs.nvs_op1'), rating: '100' },
      { label: i18n.t('nvs.nvs_op2'), rating: '75' },
      { label: i18n.t('nvs.nvs_op3'), rating: '50' },
      { label: i18n.t('nvs.nvs_op4'), rating: '25' },
      { label: i18n.t('nvs.nvs_op5'), rating: '0' }
    ]
  }
  // NVS options - END

  // KPI options
  if (type === 'kpi') {
    if (ui === 'yn') {
      options = [
        { label: i18n.t('op1'), value: '100' },
        { label: i18n.t('op2'), value: '0' }
      ]
    }
    if (ui === 'ynd') {
      options = [
        { label: i18n.t('op1'), value: '100' },
        { label: i18n.t('op2'), value: '0' },
        { label: i18n.t('op3'), value: '50' }
      ]
    }
    if (ui === '5emo') {
      options = [
        { label: i18n.t('ex'), icon: '\uE603', value: '100' },
        { label: i18n.t('vg'), icon: '\uE600', value: '75' },
        { label: i18n.t('satisf'), icon: '\uE606', value: '50' },
        { label: i18n.t('bd'), icon: '\uE607', value: '25' },
        { label: i18n.t('vb'), icon: '\uE608', value: '0' }
      ]
    }
  }
  if (type === 'enum') {
    options = question.options
  }
  // KPI options - END
  return options
}

function formatWhenIsAnsweredAutomaticWithUrlParam (question, response) {
  const body = { question: question._id, isOther: false }
  if (question.ui === 'text') {
    throw new Error('Esse tipo de UI não pode ser respondida dinamicamente via URL')
  }
  if (!checkIfResponseIsExpected(question, response)) {
    throw new Error('A resposta não condiz com o valor que essa UI espera')
  }
  if (question.type === 'nps') {
    body.rating = parseInt(response)
  }
  if (question.type === 'csat') {
    body.rating = parseInt(response)
    body.value = getResponseLabel(question, response, 'rating').label
  }
  if (question.type === 'nes') {
    body.rating = parseInt(response)
  }
  if (question.type === 'kpi') {
    body.rating = parseInt(response)
  }
  if (question.type === 'enum') {
    body.value = response
    body.text = ''
  }
  if (question.type === 'nvs') {
    body.rating = parseInt(response)
    body.value = getResponseLabel(question, response, 'rating').label
  }
  if (question.type === 'ces') {
    body.rating = parseInt(response)
    body.value = getResponseLabel(question, response, 'rating').label
  }
  if (question.ui === 'singleOther' && question.otherLabel === response) {
    body.value = ''
    body.isOther = true
  }
  return body
}

function checkIfResponseIsExpected (question, response) {
  try {
    if (question.type === 'enum' && question.ui !== '10num') {
      return true // não validamos respostas texto do tipo enum, quando for respondido automaticamente
    }
    return mappedOptions[question.type][question.ui].indexOf(parseInt(response)) > -1
  } catch (error) {
    return false
  }
}

/**
 * checa se a resposta está dentro do q se espera na pergunta
 * retorna o label caso passe no teste
 * @param {Object} question contém as questão que será respondida
 * @param {String || Int} response contém a resposta da questão
 * @param {String} whereToCheck onde validar se a resposta condiz com o valor existente na pergunta
 * @returns String
 */
function getResponseLabel (question, response, whereToCheck) {
  // obrigatoriamente o tipo enum de botões 1a10 etc devem vir com as options dentro da questão
  // por esse motivo ele não foi mapeado no getOptions
  // const normalizeOptions = question.options && question.options.length > 0 ? question.options : getOptions(question) comentei essa linha para forçar pegar o objeto correto sempre
  return getOptions(question).filter(option => { return parseInt(option[whereToCheck]) === parseInt(response) })[0]
}

export const responsesHelper = {
  formatWhenIsAnsweredAutomaticWithUrlParam,
  getOptions
}
