const state = []

const mutations = {
  setAnswer (state, payload) {
    const answered = state.findIndex(o => o.id === payload.id)
    answered > -1 ? state[answered] = payload : state.push(payload)
  }
}

const getters = {
  getAnswersList: state => state
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
