<template>
  <div class="binds-badge" :class="[$bindsActiveTheme]">
    <slot />
  </div>
</template>

<script>
  import BindsComponent from '../../core/BindsComponent'

  export default new BindsComponent({
    name: 'BindsBadgeStandalone'
  })
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-badge {
    position: absolute;
    transition: .3s $binds-transition-default-timing;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -4px;
    font-size: 10px;
    font-style: normal;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #fff;
    pointer-events: none;
    z-index: 11;
    .binds-list-item-content & {
      position: relative;
      top: 0;
      bottom: 0;
      right: 0;
    }
    &.binds-dense {
      width: 18px;
      height: 18px;
      font-size: 8px;
    }
    &.binds-square {
      width: auto;
      border-radius: 3px;
      height: 18px;
      padding: 0 4px;
    }
  }
</style>
