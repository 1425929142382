<template>
  <binds-field class="binds-chips" :class="[$bindsActiveTheme, chipsClasses]">
    <slot />

    <binds-chip
      v-for="(chip, key) in value"
      :key="chip"
      :binds-deletable="!bindsStatic"
      :binds-clickable="!bindsStatic"
      :binds-duplicated="duplicatedChip === chip"
      @keydown.enter="$emit('binds-click', chip, key)"
      @click.native="$emit('binds-click', chip, key)"
      @binds-delete.stop="removeChip(chip)">
      <slot name="binds-chip" :chip="chip" v-if="$scopedSlots['binds-chip']">{{ chip }}</slot>
      <template v-else>{{ chip }}</template>
    </binds-chip>

    <binds-input
      ref="input"
      v-model.trim="inputValue"
      v-if="!bindsStatic && modelRespectLimit"
      :type="bindsInputType"
      :id="id"
      :placeholder="bindsPlaceholder"
      @input="handleInput"
      @keydown.enter="insertChip"
      @keydown.8="handleBackRemove">
    </binds-input>
  </binds-field>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsField from '../BindsField/BindsField'
import BindsInput from '../BindsField/BindsInput/BindsInput'
import BindsUuid from '../../core/utils/BindsUuid'
import BindsPropValidator from '../../core/utils/BindsPropValidator'

export default new BindsComponent({
  name: 'BindsChips',
  components: {
    BindsField,
    BindsInput
  },
  props: {
    value: Array,
    id: {
      type: [String, Number],
      default: () => 'binds-chips-' + BindsUuid()
    },
    bindsInputType: {
      type: [String, Number],
      ...BindsPropValidator('binds-input-type', ['email', 'number', 'password', 'search', 'tel', 'text', 'url'])
    },
    bindsPlaceholder: [String, Number],
    bindsStatic: Boolean,
    bindsLimit: Number,
    bindsCheckDuplicated: {
      type: Boolean,
      default: false
    },
    bindsFormat: {
      type: Function
    }
  },
  data: () => ({
    inputValue: '',
    duplicatedChip: null
  }),
  computed: {
    chipsClasses () {
      return {
        'binds-has-value': this.value && this.value.length
      }
    },

    modelRespectLimit () {
      return !this.bindsLimit || this.value.length < this.bindsLimit
    },

    formattedInputValue () {
      if (!this.bindsFormat) {
        return this.inputValue
      }
      return this.bindsFormat(this.inputValue)
    }
  },
  methods: {
    insertChip ({ target }) {
      let inputValue = this.formattedInputValue

      if (!inputValue || !this.modelRespectLimit) {
        return
      }

      if (this.value.includes(inputValue)) {
        this.duplicatedChip = null
        // to trigger animate
        this.$nextTick(() => {
          this.duplicatedChip = inputValue
        })
        return
      }

      this.value.push(inputValue)
      this.$emit('input', this.value)
      this.$emit('binds-insert', inputValue)
      this.inputValue = ''
    },
    removeChip (chip) {
      const index = this.value.indexOf(chip)

      this.value.splice(index, 1)
      this.$emit('input', this.value)
      this.$emit('binds-delete', chip, index)
      this.$nextTick(() => this.$refs.input.$el.focus())
    },
    handleBackRemove () {
      if (!this.inputValue) {
        this.removeChip(this.value[this.value.length - 1])
      }
    },
    handleInput () {
      if (this.bindsCheckDuplicated) {
        this.checkDuplicated()
      } else {
        this.duplicatedChip = null
      }
    },
    checkDuplicated () {
      if (!this.value.includes(this.formattedInputValue)) {
        this.duplicatedChip = null
        return false
      }

      if (!this.bindsCheckDuplicated) {
        return false
      }

      this.duplicatedChip = this.formattedInputValue
    }
  },
  watch: {
    value () {
      this.checkDuplicated()
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-chips.binds-field {
    padding-top: 12px;
    flex-wrap: wrap;

    &.binds-has-value {
      label {
        top: -6px;
      }
    }

    .binds-chip {
      margin-bottom: 4px;

      &:last-of-type {
        margin-right: 8px;
      }
    }

    .binds-input {
      min-width: 128px;
    }
  }
</style>
