<template>
  <transition name="binds-empty-state" appear>
    <div
      class="binds-empty-state"
      :class="[emptyStateClasses, $bindsActiveTheme]"
      :style="emptyStateStyles"
    >
      <div class="binds-empty-state-container">
        <template v-if="bindsIcon">
          <binds-icon
            class="binds-empty-state-icon"
            v-if="isAssetIcon(bindsIcon)"
            :binds-src="bindsIcon"
          ></binds-icon>
          <binds-icon class="binds-empty-state-icon" v-else>{{ bindsIcon }}</binds-icon>
        </template>
        <strong class="binds-empty-state-label" v-if="bindsLabel">{{ bindsLabel }}</strong>
        <p class="binds-empty-state-description" v-if="bindsDescription">{{ bindsDescription }}</p>

        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import BindsComponent from "../../core/BindsComponent";
import BindsEmptyStateProps from "./BindsEmptyStateProps";
import BindsAssetIcon from "../../core/mixins/BindsAssetIcon/BindsAssetIcon";

export default new BindsComponent({
  name: "BindsEmptyState",
  mixins: [BindsAssetIcon],
  props: BindsEmptyStateProps,
  computed: {
    emptyStateClasses() {
      return {
        "binds-rounded": this.bindsRounded
      };
    },
    emptyStateStyles() {
      if (this.bindsRounded) {
        const size = this.bindsSize + "px";

        return {
          width: size,
          height: size
        };
      }
    }
  }
});
</script>

<style lang="scss">
@import "../BindsAnimation/variables";
@import "../BindsIcon/mixins";

@mixin binds-empty-state-base() {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.binds-empty-state {
  @include binds-empty-state-base;
  max-width: 420px;
  padding: 36px;
  margin: 0 auto;
  position: relative;
  transition: opacity 0.15s $binds-transition-enter-timing,
    transform 0.3s $binds-transition-enter-timing;
  will-change: transform, opacity;

  &.binds-rounded {
    max-width: auto;
    border-radius: 50%;

    .binds-empty-state-container {
      padding: 40px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .binds-button {
    margin: 0.5em 0 0;
  }
}

.binds-empty-state-enter {
  opacity: 0;
  transform: scale(0.87);

  .binds-empty-state-container {
    opacity: 0;
  }
}

.binds-empty-state-container {
  @include binds-empty-state-base;
  transition: opacity 0.4s $binds-transition-default-timing;
  will-change: opacity;
}

.binds-empty-state-icon {
  @include binds-icon-size(160px);
  margin: 0;
}

.binds-empty-state-label {
  font-size: 26px;
  font-weight: 500;
  line-height: 40px;
}

.binds-empty-state-description {
  margin: 1em 0;
  font-size: 16px;
  line-height: 24px;
}
</style>
