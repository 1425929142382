<template>
  <div id="ces-card">
    <binds-card :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">

      <binds-card-content>
        <!-- UI igual a 5 num -->
        <div v-if="data.question.ui === '1to7'"  class="animated-faster zoomIn">
          <div class="binds-layout binds-gutter">
            <div
              v-for="(label, index) in data.question.options" v-bind:key="index"
              :class="{'binds-size-20': data.question.options.length <= 5, 'binds-size-14': data.question.options.length > 5}"
              class="binds-layout-item responsiveAlign"
            >
              <binds-button class="binds-raised" :binds-ripple="false" v-on:click="postResponse(data.question.options[index])" 
                style="min-width: 80%"
                :style="returnStyle(index)"
                :value="data.question.options[index].rating">
                {{data.question.options[index].label}}
                </binds-button>
            </div>
          </div>
          <div class="binds-layout binds-gutter">
            <div class="binds-layout-item">
              <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.startLabel}}</span>
            </div>
            <div class="binds-layout-item alignSpecialLabels">
            <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.endLabel}}</span>
            </div>
          </div>
        </div>
        <div v-if="data.question.ui === '1to7button'" class="content-5num ">
          <binds-card  :style="returnStyle(index)" class="animated-faster zoomIn" binds-with-hover v-for="(item,index) in data.question.options" v-on:click.native="postResponse(item)" v-bind:key="index">
            <binds-card-content style="padding: 12px;text-align: center;">
              <div>
                <span>{{item.label}}</span>
              </div>
            </binds-card-content>
          </binds-card>
        </div>
      </binds-card-content>

    </binds-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsContent, BindsTabs, BindsButton, BindsCard } from '../bindsMaterial/components'
import { widgetService } from '@/_services'
import { formatButton } from '@/_helpers'

Vue.use(BindsContent)
Vue.use(BindsTabs)
Vue.use(BindsButton)
Vue.use(BindsCard)

export default {
  props: ['data'],
  methods: {
    async postResponse (options) {
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, options)
        this.$root.$emit('questionUpdated', questionUpdated, options.rating)
      }
    },
    returnStyle (index) {
      return formatButton.returnStyleWithGradient(this.data, index)
    }
  },
  mounted () {
    this.$root.$emit('setLoading', false)
  }
}
</script>
<style scoped>
#ces-card {
  font-weight: bold;
  font-family: Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif;
}
.binds-size-14 {
  max-width: 14%;
  min-width: 14%;
}
</style>
