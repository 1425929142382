<template>
  <div class="binds-switch" :class="[$bindsActiveTheme, checkClasses]">
    <div class="binds-switch-container" @click.stop="toggleCheck">
      <div class="binds-switch-thumb">
        <binds-ripple binds-centered :binds-active.sync="rippleActive" :binds-disabled="disabled">
          <input :id="id" type="checkbox" v-bind="{ id, name, disabled, required, value }">
        </binds-ripple>
      </div>
    </div>

    <label :for="id" class="binds-switch-label" v-if="$slots.default" @click.prevent="toggleCheck">
      <slot />
    </label>
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsCheckboxMixin from '../BindsCheckbox/BindsCheckboxMixin'
import BindsUuid from '../../core/utils/BindsUuid'

export default new BindsComponent({
  name: 'BindsSwitch',
  mixins: [BindsCheckboxMixin],
  props: {
    id: {
      type: String,
      default: () => 'binds-switch-' + BindsUuid()
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";

  $binds-switch-width: 34px;
  $binds-switch-height: 14px;
  $binds-switch-size: 20px;
  $binds-switch-touch-size: 48px;

  .binds-switch {
    width: auto;
    margin: 16px 16px 16px 0;
    display: inline-flex;
    position: relative;

    &:not(.binds-disabled) {
      cursor: pointer;

      .binds-switch-label {
        cursor: pointer;
      }
    }

    .binds-switch-container {
      width: $binds-switch-width;
      min-width: $binds-switch-width;
      height: $binds-switch-height;
      margin: 3px 0;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: $binds-switch-height;
      transition: $binds-transition-stand;
    }

    .binds-switch-thumb {
      @include binds-elevation(1);
      width: $binds-switch-size;
      height: $binds-switch-size;
      position: relative;
      border-radius: 50%;
      transition: $binds-transition-stand;

      &:before {
        width: $binds-switch-touch-size;
        height: $binds-switch-touch-size;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 11;
        transform: translate(-50%, -50%);
        content: " ";
      }

      .binds-ripple {
        width: $binds-switch-touch-size !important;
        height: $binds-switch-touch-size !important;
        top: 50% !important;
        left: 50% !important;
        position: absolute;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }

      input {
        position: absolute;
        left: -999em;
      }
    }

    .binds-switch-label {
      height: $binds-switch-size;
      padding-left: 16px;
      position: relative;
      line-height: $binds-switch-size;
    }
  }

  .binds-switch.binds-checked {
    .binds-switch-thumb {
      transform: translate3d(15px, 0, 0);
    }
  }

  .binds-switch.binds-required {
    label:after {
      position: absolute;
      top: 2px;
      right: 0;
      transform: translateX(calc(100% + 2px));
      content: "*";
      line-height: 1em;
      vertical-align: top;
    }
  }
</style>
