<template>
  <transition name="binds-ripple" @after-enter="end" appear>
    <span v-if="animating" />
  </transition>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
export default new BindsComponent({
  name: 'BindsWave',
  data () {
    return {
      animating: false
    }
  },
  props: {
    waveClasses: null,
    waveStyles: null
  },
  mounted: function () {
    this.animating = true
  },
  methods: {
    end () {
      this.animating = false
      this.$emit('binds-end')
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-ripple-enter-active {
    transition: .8s $binds-transition-stand-timing;
    transition-property: opacity, transform;
    will-change: opacity, transform;
    &.binds-centered {
      transition-duration: 1.2s;
    }
  }

  .binds-ripple-enter {
    opacity: .26;
    transform: scale(.26) translateZ(0);
  }
</style>
