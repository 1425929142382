<template>
  <div class="binds-card-actions" :class="`binds-alignment-${bindsAlignment}`">
    <slot />
  </div>
</template>

<script>
import BindsPropValidator from '../../../core/utils/BindsPropValidator'

const alignments = ['left', 'right', 'space-between']

export default {
  name: 'BindsCardActions',
  props: {
    bindsAlignment: {
      type: String,
      default: 'right',
      ...BindsPropValidator('binds-alignment', alignments)
    }
  }
}
</script>

<style lang="scss">
  .binds-card-actions {
    padding: 8px;
    display: flex;
    align-items: center;

    &.binds-alignment-right {
      justify-content: flex-end;
    }

    &.binds-alignment-left {
      justify-content: flex-start;
    }

    &.binds-alignment-space-between {
      justify-content: space-between;
    }

    .binds-button {
      margin: 0;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      + .binds-button {
        margin-left: 4px;
      }
    }
  }
</style>
