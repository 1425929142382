export const formatButton = {
    returnStyleWithGradient
  }

function returnStyleWithGradient (data, index) {
    if (!data.question.gradient) {
      return {
          'background-color': data.buttons,
          color: data.textButtons
        }
    }
    const colors = {
      '5': [
        '#f22020',
        '#f25b20',
        '#f4b622',
        '#c2d924',
        '#26bb07'
      ],
      '7': [
        '#26bb07',
        '#26bb07',
        '#c2d924',
        '#f4b622',
        '#f25b20',
        '#f22020',
        '#f22020'
      ],
      '11': [
        '#f22020',
        '#f22020',
        '#f22020',
        '#f25b20', /* laranja */
        '#f79c27',
        '#f4b622', /* malélu */
        '#c2d924',
        '#a0ec31', /* vedinhu */
        '#6cdf2b',
        '#26bb07',
        '#26bb07'
      ],
      '10': [
        '#f22020',
        '#f22020',
        '#f22020',
        '#f25b20', /* laranja */
        '#f79c27',
        '#f4b622', /* malélu */
        '#c2d924',
        '#a0ec31', /* vedinhu */
        '#6cdf2b',
        '#26bb07'
      ],
      '6': [
        '#f22020',
        '#f79c27',
        '#f4b622',
        '#a0ec31',
        '#6cdf2b',
        '#26bb07'
      ]
    }
    let color = ''
    const parsedLength = data.question.options.length
    try {
      color = colors[parsedLength][index]
      if ((data.question.type === 'nes' && data.question.ui === '10num') || data.question.type === 'ces') {
        color = colors[parsedLength].reverse()[index]
      }
    } catch (error) {
      return color
    }
    return {
      'color': 'white',
      'background-color': color
    }
}