<script>
import BindsUuid from '../../core/utils/BindsUuid'
import BindsRouterLink from '../../core/mixins/BindsRouterLink/BindsRouterLink'
import BindsObserveElement from '../../core/utils/BindsObserveElement'
import BindsRouterLinkProps from '../../core/utils/BindsRouterLinkProps'

export default {
  name: 'BindsTab',
  mixins: [BindsRouterLink],
  props: {
    id: {
      type: String,
      default: () => 'binds-tab-' + BindsUuid()
    },
    href: [String, Number],
    bindsDisabled: Boolean,
    bindsLabel: [String, Number],
    bindsIcon: String,
    bindsTemplateData: {
      type: Object,
      default: () => ({})
    }
  },
  inject: ['BindsTabs'],
  data: () => ({
    observer: null
  }),
  watch: {
    $props: {
      deep: true,
      handler () {
        this.setTabData()
      }
    },
    $attrs: {
      deep: true,
      handler () {
        this.setTabData()
      }
    }
  },
  methods: {
    setTabContent () {
      this.$set(this.BindsTabs.items[this.id], 'hasContent', !!this.$slots.default)
    },
    setupObserver () {
      this.observer = BindsObserveElement(this.$el, {
        childList: true
      }, this.setTabContent)
    },
    setTabData () {
      this.$set(this.BindsTabs.items, this.id, {
        hasContent: !!this.$slots.default,
        label: this.bindsLabel,
        icon: this.bindsIcon,
        disabled: this.bindsDisabled,
        data: this.bindsTemplateData,
        props: this.getPropValues(),
        events: this.$listeners
      })
    },
    getPropValues () {
      const propNames = Object.keys(this.$options.props)
      const ignoredProps = ['id', 'bindsLabel', 'bindsDisabled', 'bindsTemplateData']
      let values = {}

      propNames.forEach(prop => {
        if (!ignoredProps.includes(prop)) {
          if (this[prop]) {
            values[prop] = this[prop]
          } else if (this.$attrs.hasOwnProperty(prop)) {
            if (prop) {
              values[prop] = this.$attrs[prop]
            } else {
              values[prop] = true
            }
          }
        }
      })

      return values
    }
  },
  mounted () {
    this.setupObserver()
    this.setTabData()
  },
  beforeDestroy () {
    if (this.observer) {
      this.observer.disconnect()
    }

    this.$delete(this.BindsTabs.items, this.id)
  },
  render (createElement) {
    let tabAttrs = {
      staticClass: 'binds-tab',
      attrs: {
        ...this.$attrs,
        id: this.id
      },
      on: this.$listeners
    }

    if (this.href) {
      this.buttonProps = this.$options.props
    } else if (this.$router && this.to) {
      this.$options.props = BindsRouterLinkProps(this, this.$options.props)

      tabAttrs.props = this.$props
    }

    return createElement('div', tabAttrs, this.$slots.default)
  }
}
</script>
