<template>
  <binds-dialog v-bind="$attrs" v-on="$listeners" :binds-fullscreen="false">
    <binds-dialog-title v-if="bindsTitle">{{ bindsTitle }}</binds-dialog-title>
    <binds-dialog-content v-if="bindsContent" v-html="bindsContent" />

    <binds-dialog-actions>
      <binds-button @click="onCancel">{{ bindsCancelText }}</binds-button>
      <binds-button class="binds-primary" @click="onConfirm">{{ bindsConfirmText }}</binds-button>
    </binds-dialog-actions>
  </binds-dialog>
</template>

<script>
export default {
  name: 'BindsDialogConfirm',
  props: {
    bindsTitle: String,
    bindsContent: String,
    bindsConfirmText: {
      type: String,
      default: 'Ok'
    },
    bindsCancelText: {
      type: String,
      default: 'Cancel'
    }
  },
  methods: {
    onCancel () {
      this.$emit('binds-cancel')
      this.$emit('update:bindsActive', false)
    },
    onConfirm () {
      this.$emit('binds-confirm')
      this.$emit('update:bindsActive', false)
    }
  }
}
</script>
