<template>
  <div class="binds-list-item-default" @click="toggleControl">
    <binds-list-item-content binds-disabled>
      <slot />
    </binds-list-item-content>
  </div>
</template>

<script>
import BindsListItemMixin from './BindsListItemMixin'

export default {
  name: 'BindsListItemDefault',
  mixins: [BindsListItemMixin],
  methods: {
    toggleControl () {
      const control = this.$el.querySelector('.binds-checkbox-container, .binds-switch-container, .binds-radio-container')

      if (control) {
        control.click()
      }
    }
  }
}
</script>
