<template>
  <div class="binds-radio" :class="[$bindsActiveTheme, radioClasses]">
    <div class="binds-radio-container" @click.stop="toggleCheck">
      <binds-ripple binds-centered :binds-active.sync="rippleActive" :binds-disabled="disabled">
        <input type="radio" v-bind="{ id, name, disabled, required, value }">
      </binds-ripple>
    </div>

    <label :for="id" class="binds-radio-label" v-if="$slots.default" @click.prevent="toggleCheck">
      <slot />
    </label>
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsUuid from '../../core/utils/BindsUuid'
import BindsRipple from '../BindsRipple/BindsRipple'

export default new BindsComponent({
  name: 'BindsRadio',
  components: {
    BindsRipple
  },
  props: {
    model: [String, Number, Boolean, Object],
    value: {
      type: [String, Number, Boolean, Object],
      default: 'on'
    },
    id: {
      type: String,
      default: () => 'binds-radio-' + BindsUuid()
    },
    name: [String, Number],
    required: Boolean,
    disabled: Boolean
  },
  model: {
    prop: 'model',
    event: 'change'
  },
  data: () => ({
    rippleActive: false
  }),
  computed: {
    isSelected () {
      return this.model === this.value
    },
    radioClasses () {
      return {
        'binds-checked': this.isSelected,
        'binds-disabled': this.disabled,
        'binds-required': this.required
      }
    }
  },
  methods: {
    toggleCheck () {
      if (!this.disabled) {
        this.rippleActive = true
        this.$emit('change', this.value)
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  $binds-radio-size: 20px;
  $binds-radio-touch-size: 48px;

  .binds-radio {
    width: auto;
    margin: 16px 16px 16px 0;
    display: inline-flex;
    position: relative;

    &:not(.binds-disabled) {
      cursor: pointer;

      .binds-radio-label {
        cursor: pointer;
      }
    }

    .binds-radio-container {
      width: $binds-radio-size;
      min-width: $binds-radio-size;
      height: $binds-radio-size;
      position: relative;
      border: 2px solid transparent;
      border-radius: 50%;
      transition: $binds-transition-stand;

      &:focus {
        outline: none;
      }

      &:before,
      &:after {
        position: absolute;
        transition: $binds-transition-drop;
        content: " ";
      }

      &:before {
        width: $binds-radio-touch-size;
        height: $binds-radio-touch-size;
        top: 50%;
        left: 50%;
        z-index: 11;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        border-radius: 50%;
        opacity: 0;
        transform: scale3D(.38, .38, 1);
        content: " ";
      }

      .binds-ripple {
        width: $binds-radio-touch-size !important;
        height: $binds-radio-touch-size !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }

      input {
        position: absolute;
        left: -999em;
      }
    }

    .binds-radio-label {
      height: 100%;
      padding-left: 16px;
      position: relative;
      line-height: $binds-radio-size;
    }
  }

  .binds-radio.binds-checked {
    .binds-radio-container {
      &:after {
        opacity: 1;
        transform: scale3D(1, 1, 1);
        transition: $binds-transition-stand;
      }
    }
  }

  .binds-radio.binds-required {
    label:after {
      position: absolute;
      top: 2px;
      right: 0;
      transform: translateX(calc(100% + 2px));
      content: "*";
      line-height: 1em;
      vertical-align: top;
    }
  }
</style>
