<template>
  <tr class="binds-table-row" :class="rowClasses" @click="onClick" v-on="$listeners">
    <binds-table-cell-selection
      :value="isMultipleSelected"
      @input="selected => selected ? addSelection() : removeSelection()"
      :binds-disabled="bindsDisabled"
      :binds-selectable="bindsSelectable === 'multiple'"
      :binds-row-id="bindsIndex"
      v-if="selectableCount" />
    <slot />
  </tr>
</template>

<script>
  import BindsPropValidator from '../../core/utils/BindsPropValidator'
  import BindsTableCellSelection from './BindsTableCellSelection'

  export default {
    name: 'BindsTableRow',
    components: {
      BindsTableCellSelection
    },
    props: {
      bindsIndex: [Number, String],
      bindsId: [Number, String],
      bindsSelectable: {
        type: [String],
        ...BindsPropValidator('binds-selectable', ['multiple', 'single'])
      },
      bindsDisabled: Boolean,
      bindsAutoSelect: Boolean,
      bindsItem: [Array, Object]
    },
    inject: ['BindsTable'],
    data: () => ({
      index: null
    }),
    computed: {
      selectableCount () {
        return this.BindsTable.selectable.length
      },
      isMultipleSelected () {
        return this.BindsTable.selectedItems.includes(this.bindsItem)
      },
      isSingleSelected () {
        return this.BindsTable.singleSelection === this.bindsItem
      },
      hasMultipleSelection () {
        return this.BindsTable.hasValue && this.bindsSelectable === 'multiple'
      },
      hasSingleSelection () {
        return this.BindsTable.hasValue && this.bindsSelectable === 'single'
      },
      rowClasses () {
        if (this.BindsTable.hasValue) {
          return {
            'binds-has-selection': !this.bindsDisabled && (this.bindsAutoSelect || this.hasSingleSelection),
            'binds-selected': this.isMultipleSelected,
            'binds-selected-single': this.isSingleSelected
          }
        }
      },
      isInSelectedItems () {
        return this.BindsTable.selectedItems.includes(this.bindsItem)
      }
    },
    watch: {
      bindsDisabled () {
        if (this.bindsDisabled) {
          this.removeSelectableItem()
        } else {
          this.addSelectableItem()
        }
      },
      bindsSelectable () {
        this.BindsTable.selectingMode = this.bindsSelectable
      },
      bindsItem (after, before) {
        this.removeSelectableItem(before)
        this.$nextTick(this.addSelectableItem)
      }
    },
    methods: {
      onClick () {
        if (this.BindsTable.hasValue && !this.bindsDisabled) {
          if (this.hasMultipleSelection) {
            this.selectRowIfMultiple()
          } else if (this.hasSingleSelection) {
            this.selectRowIfSingle()
          }
        }
      },
      toggleSelection () {
        this.BindsTable.manageItemSelection(this.bindsItem)
      },
      addSelection () {
        if (!this.isMultipleSelected) {
          this.BindsTable.selectedItems = this.BindsTable.selectedItems.concat([this.bindsItem])
        }
      },
      removeSelection () {
        if (this.isMultipleSelected) {
          this.BindsTable.selectedItems = this.BindsTable.selectedItems.filter(target => target !== this.bindsItem)
        }
      },
      selectRowIfSingle () {
        if (this.BindsTable.singleSelection === this.bindsItem) {
          this.BindsTable.singleSelection = null
        } else {
          this.BindsTable.singleSelection = this.bindsItem
        }
      },
      selectRowIfMultiple () {
        if (this.bindsAutoSelect) {
          this.toggleSelection()
        }
      },
      addSelectableItem () {
        if (!this.hasMultipleSelection || this.bindsDisabled) {
          return false
        }

        if (this.BindsTable.selectable.includes(this.bindsItem)) {
          return false
        }

        this.BindsTable.selectable = this.BindsTable.selectable.concat([this.bindsItem])
      },
      removeSelectableItem (target = this.bindsItem) {
        if (this.bindsSelectable === 'multiple') {
          this.BindsTable.selectable = this.BindsTable.selectable.filter(item => item !== target)
        }
      }
    },
    created () {
      this.$nextTick(() => {
        this.addSelectableItem()
        this.BindsTable.selectingMode = this.bindsSelectable
      })
    },
    beforeDestroy () {
      this.removeSelectableItem()
    }
  }
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-table-row {
    transition: .3s $binds-transition-default-timing;
    transition-property: background-color, font-weight;
    will-change: background-color, font-weight;

    &.binds-has-selection {
      cursor: pointer;
    }

    &.binds-selected-single {
      font-weight: 500;
    }

    tbody & td {
      border-top: 1px solid;
    }
  }
</style>
