<template>
  <div class="binds-speed-dial" :class="[$bindsActiveTheme, speedDialClasses]">
    <slot />
  </div>
</template>

<script>
import BindsComponent from '../../core/BindsComponent'
import BindsPropValidator from '../../core/utils/BindsPropValidator'

export default new BindsComponent({
  name: 'BindsSpeedDial',
  props: {
    bindsEvent: {
      type: String,
      default: 'hover',
      ...BindsPropValidator('binds-event', ['click', 'hover'])
    },
    bindsDirection: {
      type: String,
      default: 'top',
      ...BindsPropValidator('binds-direction', ['top', 'bottom'])
    },
    bindsEffect: {
      type: String,
      default: 'fling',
      ...BindsPropValidator('binds-effect', ['fling', 'scale', 'opacity'])
    }
  },
  data () {
    return {
      BindsSpeedDial: {
        active: false,
        event: this.bindsEvent,
        direction: this.bindsDirection
      }
    }
  },
  provide () {
    return {
      BindsSpeedDial: this.BindsSpeedDial
    }
  },
  computed: {
    speedDialClasses () {
      return {
        'binds-active': this.BindsSpeedDial.active,
        'binds-with-hover': this.bindsEvent === 'hover',
        ['binds-direction-' + this.bindsDirection]: true,
        ['binds-effect-' + this.bindsEffect]: true
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-speed-dial {
    display: inline-flex;
    flex-direction: column;

    &.binds-top-right,
    &.binds-top-left {
      position: absolute;
      top: 24px;
    }

    &.binds-bottom-right,
    &.binds-bottom-left {
      position: absolute;
      bottom: 24px;
    }

    &.binds-top-center,
    &.binds-bottom-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &.binds-top-center {
      top: 24px;
    }

    &.binds-bottom-center {
      bottom: 24px;
    }

    &.binds-top-right,
    &.binds-bottom-right {
      right: 24px;
    }

    &.binds-top-left,
    &.binds-bottom-left {
      left: 24px;
    }

    &.binds-fixed {
      position: fixed;
    }

    &.binds-direction-top {
      &.binds-effect-fling {
        .binds-speed-dial-content .binds-button {
          transform: translate3d(0, 50%, 0) scale(.8);
        }
      }

      .binds-speed-dial-target {
        order: 2;
        margin-bottom: 0 !important;
      }

      .binds-speed-dial-content {
        order: 1;

        .binds-button:first-child {
          margin-top: 0;
        }
      }
    }

    &.binds-direction-bottom {
      &.binds-effect-fling {
        .binds-speed-dial-content .binds-button {
          transform: translate3d(0, -50%, 0) scale(.8);
        }
      }

      .binds-speed-dial-target {
        order: 1;
        margin-top: 0 !important;
      }

      .binds-speed-dial-content {
        order: 2;

        .binds-button:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.binds-effect-scale {
      .binds-speed-dial-content .binds-button {
        transform: scale(.3);
      }
    }

    &.binds-active,
    &.binds-with-hover:hover {
      .binds-morph-initial {
        opacity: 0;
        transform: translate3D(-50%, -50%, 0) rotate(90deg) scale(.7);
      }

      .binds-morph-final {
        opacity: 1;
        transform: translate3D(-50%, -50%, 0) rotate(0deg) scale(1);
      }

      .binds-speed-dial-content .binds-button {
        pointer-events: auto;
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1) !important;
        transition: opacity .2s $binds-transition-default-timing,
                    transform .3s $binds-transition-stand-timing;

        @for $index from 0 through 5 {
          &[binds-button-index="#{$index}"] {
            transition-delay: .1s * $index
          }
        }
      }
    }

    .binds-button {
      margin: 6px 0;
    }

    .binds-speed-dial-content .binds-button {
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s $binds-transition-default-timing,
                  transform 0s .3s $binds-transition-default-timing;
      will-change: opacity, transform;
    }

    .binds-morph-initial,
    .binds-morph-final {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3D(-50%, -50%, 0);
      transition: .3s $binds-transition-stand-timing;
      transition-property: opacity, transform;
      will-change: opacity, transform;
    }

    .binds-morph-final {
      opacity: 0;
      transform: translate3D(-50%, -50%, 0) scale(.7) rotate(-90deg);
    }
  }
</style>
