<script>
export default {
  name: 'BindsCardExpandTrigger',
  inject: ['BindsCard'],
  render (createElement) {
    const [trigger] = this.$slots.default
    const staticClass = ' binds-card-expand-trigger'
    let listeners = {
      click: () => {
        this.BindsCard.expand = !this.BindsCard.expand
      }
    }

    if (trigger) {
      trigger.componentOptions.listeners = {
        ...trigger.componentOptions.listeners,
        ...listeners
      }
      trigger.data.staticClass += staticClass

      return trigger
    }

    return createElement('div', {
      staticClass,
      on: listeners
    })
  }
}
</script>

<style lang="scss">
  @import "../../BindsAnimation/variables";

  .binds-card-expand-trigger.binds-icon-button {
    transition: transform .4s $binds-transition-stand-timing;
    will-change: transform;
  }
</style>
