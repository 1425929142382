<template>
  <div class="binds-card-media" :class="mediaClasses">
    <slot />
  </div>
</template>

<script>
import BindsPropValidator from '../../../core/utils/BindsPropValidator'

export default {
  name: 'BindsCardMedia',
  props: {
    bindsRatio: {
      type: String,
      ...BindsPropValidator('binds-ratio', [
        '16-9',
        '16/9',
        '16:9',
        '4-3',
        '4/3',
        '4:3',
        '1-1',
        '1/1',
        '1:1'
      ])
    },
    bindsMedium: Boolean,
    bindsBig: Boolean
  },
  computed: {
    mediaClasses () {
      let classes = {}

      if (this.bindsRatio) {
        const ratio = this.getAspectRatio()

        if (ratio) {
          const [horiz, vert] = ratio

          classes[`binds-ratio-${horiz}-${vert}`] = true
        }
      }

      if (this.bindsMedium || this.bindsBig) {
        classes = {
          'binds-medium': this.bindsMedium,
          'binds-big': this.bindsBig
        }
      }

      return classes
    }
  },
  methods: {
    getAspectRatio () {
      let ratio = []

      if (this.bindsRatio.indexOf(':') !== -1) {
        ratio = this.bindsRatio.split(':')
      } else if (this.bindsRatio.indexOf('/') !== -1) {
        ratio = this.bindsRatio.split('/')
      } else if (this.bindsRatio.indexOf('-') !== -1) {
        ratio = this.bindsRatio.split('-')
      }

      return ratio.length === 2 ? ratio : null
    }
  }
}
</script>

<style lang="scss">
  @mixin binds-image-aspect-ratio($width, $height) {
    overflow: hidden;

    &:before{
      width: 100%;
      padding-top: ($height / $width) * 100%;
      display: block;
      content: " ";
    }

    img {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .binds-card-media {
    position: relative;

    &.binds-ratio-16-9 {
      @include binds-image-aspect-ratio(16, 9);
    }

    &.binds-ratio-4-3 {
      @include binds-image-aspect-ratio(4, 3);
    }

    &.binds-ratio-1-1 {
      @include binds-image-aspect-ratio(1, 1);
    }

    + .binds-card-header {
      padding-top: 24px;
    }

    + .binds-card-content:last-child {
      padding-bottom: 16px;
    }

    img {
      width: 100%;
    }
  }
</style>
