<template>
  <div v-if="isToShow && !isLoadingFonts">
      <div class="binds-content-widget"
        :class="{
          'binds-right': data.widgetPosition === 'right',
          'binds-left': data.widgetPosition === 'left',
          'binds-lower-left': data.widgetPosition === 'lowerLeft',
          'binds-lower-right': data.widgetPosition === 'lowerRight'
        }">
      <button
      class="bindsOpenWidget binds-elevation-5"
      v-on:click="validateClickAndRules()"
      :style="{'background-color': background}"
      :class="{
        'binds-animated': bindsOpenWidget,
        'binds-button-right' : data.widgetPosition === 'right' || data.widgetPosition === 'lowerRight',
        'binds-button-left' : data.widgetPosition === 'left' || data.widgetPosition === 'lowerLeft',
        }">
        <span :style="{'color':texts}" :class="{ 'binds-widget-label-block': bindsOpenWidget }">{{data.widgetTitle}}</span>
        <i :style="{'color':texts}" class="binds-material-icons binds-close-widget" v-if="bindsOpenWidget">close</i>
        <i :style="{'color':texts}" class="binds-material-icons binds-arrow-down"  v-if="!bindsOpenWidget">arrow_drop_up</i>
      </button>
      <div v-on:questionUpdated="questionUpdated($event.target.value)" v-if="bindsOpenWidget" :class="{
        'binds-animated': bindsOpenWidget,
        'bindsFadeInLeft' : data.widgetPosition === 'left' || data.widgetPosition === 'lowerLeft',
        'fadeInRight' : data.widgetPosition === 'right' || data.widgetPosition === 'lowerRight',
        }">
        <div class="binds-header-widget binds-elevation-10" :style="{'background-color': background,  'width': data.width + data.widthMetric,}" v-if="!data.endMessage">
            <div class="binds-title" :style="{color: texts}" v-html="replaceMeta(data.question.label)"></div>
            <div class="binds-caption" :style="{color: texts}" v-html="replaceMeta(data.question.question)"></div>
        </div>

        <div class="answers-type" :key="updateAllComponents" style="background-color: #fff;">
        <div class="binds-loading-widget" v-if="loadingWidget">
          <binds-progress-bar binds-mode="indeterminate"></binds-progress-bar>
        </div>
          <!-- declare all response components -->
          <Csat v-if="data.question.type === 'csat' && data.question" :data="data"/>
          <Nvs v-if="data.question.type === 'nvs' && data.question" :data="data"/>
          <Matrix v-if="data.question.type === 'matrix' && data.question.subjects" :data="data" />
          <Enum v-if="data.question.type === 'enum' && data.question" :data="data" />
          <Nes v-if="data.question.type === 'nes'&& data.question" :data="data"/>
          <Ces v-if="data.question.type === 'ces'&& data.question" :data="data"/>
          <Nps v-if="data.question.type === 'nps' && data.question" :data="data"/>
          <Kpi v-if="data.question.type === 'kpi' && data.question" :data="data"/>
          <TextInput v-if="data.question.type === 'text' && data.question" :data="data"/>
          <EndMessage v-if="data.endMessage" :data="data"/>
          <Referral v-if="data.question.type === 'referral' && data.question" :data="data"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Csat from './responseUi/Csat'
import Enum from './responseUi/Enum'
import Matrix from './responseUi/Matrix'
import Nes from './responseUi/Nes'
import Ces from './responseUi/Ces'
import Nps from './responseUi/Nps'
import Kpi from './responseUi/Kpi'
import Nvs from './responseUi/Nvs'
import TextInput from './responseUi/TextInput'
import EndMessage from './responseUi/EndMessage'
import Referral from './responseUi/Referral'
import './bindsMaterial/theme/prebuilt/default.scss'

import { BindsProgress } from './bindsMaterial/components'
import { widgetService } from '@/_services'
import { responsesHelper } from '@/_helpers'
import WebFont from 'webfontloader'

Vue.use(BindsProgress)

export default {
  props: [
    'widget_position', 'widget_title', 'width', 'background',
    'buttons', 'texts', 'timeout', 'width_metric',
    'survey_id', 'metadata', 'text_buttons', 'is_to_post', 'from', 'hidde_after_answer', 'hidde_when_click_to_close', 'automatic_answer_first_question'
  ],
  components: {
    Csat, Enum, Matrix, Nes, Nps, Kpi, TextInput, EndMessage, Referral, Nvs, Ces
  },
  data () {
    return {
      isToShow: true,
      bindsOpenWidget: true,
      loadingWidget: false,
      updateAllComponents: 0,
      isLoadingFonts: false,
      keycode: {
        em: {
          init: '*|q:',
          end: '|*',
          rxstart: '\\*\\|q\\:',
          rxmid: '(.*?)',
          rxend: '\\|\\*'
        },
        span: {
          init: '*|',
          end: '|*',
          rxstart: '\\*\\|',
          rxmid: '(.*?)',
          rxend: '\\|\\*'
        },
        b: {
          init: '**',
          end: '**',
          rxstart: '\\*{2}',
          rxmid: '(.*?)',
          rxend: '\\*{2}'
        },
        i: {
          init: '*',
          end: '*',
          rxstart: '\\*',
          rxmid: '(.*?)',
          rxend: '\\*'
        },
        s: {
          init: '~~',
          end: '~~',
          rxstart: '\\~{2}',
          rxmid: '(.*?)',
          rxend: '\\~{2}'
        },
        u: {
          init: '__',
          end: '__',
          rxstart: '\\_{2}',
          rxmid: '(.*?)',
          rxend: '\\_{2}'
        }
      },
      data: {
        account: {},
        survey: {},
        surveyId: this.survey_id,
        metadata: this.metadata,
        buttons: this.buttons,
        textButtons: this.text_buttons,
        texts: this.texts,
        endMessage: false,
        background: this.background,
        widthMetric: this.width_metric,
        width: this.width,
        timeout: this.timeout,
        widgetPosition: this.widget_position,
        responseFirstQuestion: this.automatic_answer_first_question,
        widgetTitle: this.widget_title,
        isToPost: this.is_to_post,
        from: this.from,
        hidde_after_answer: this.hidde_after_answer, // esconde o widget depois de responder a pesquisa
        hidde_when_click_to_close: this.hidde_when_click_to_close, // esconde o widget quando clicar no X
        question: {
          title: 'First question',
          label: 'first question label'
        }
      }
    }
  },
  created () {
    // update question object, check if has more question or end message
    this.$root.$on('questionUpdated', (response, rating) => {
      // add answer in a list to print on title
      if (['single', 'singleOther', 'multiple', 'multipleOther'].indexOf(this.data.question.ui) > -1) {
        this.insertAnswer(rating)
      } else if (this.data.question.ui === 'text') {
          if (rating.length > 100) {
            rating = rating.slice(0, 200) + '...'
          }
          this.insertAnswer(rating)
      } else if (rating === '') {
        this.insertAnswer('')
      } else if (rating !== undefined) {
        const questionOptions = responsesHelper.getOptions(this.data.question)
        const labelSelected = questionOptions.find(option => {
          let answer = rating.toString()
          if (answer) {
            if ((option.value || option.rating || option.label) === answer) {
              return option
            }
          } else {
            return {
              label: ''
            }
          }
        })
        this.insertAnswer(labelSelected.label)
      } else {
        this.insertAnswer('')
      }
      this.data.question.type = undefined
      this.data.question.ui = undefined
      if (response.account) {
        this.data.account = response.account
      }

      if (response.survey) {
        this.data.survey = response.survey
      }

      if (response.nextQuestion && response.nextQuestion.type) {
        this.data.widgetType = response.nextQuestion.type
        this.data.question = response.nextQuestion
        this.data.endMessage = false
      }

      if (response.endMessage) {
        this.data.endMessage = response.endMessage
        if (!this.data.endMessage.logo.link) {
          this.data.endMessage.logo.link = (this.data.survey.images[0].value) ? this.data.survey.images[0].value : response.account.logoUrl
        }
      }
      if (this.metadata.length > 2) {
        this.data.question.label = this.replaceMeta(this.data.question.label)
        this.data.question.question = this.replaceMeta(this.data.question.question)
      }
      if (this.data.question.language) {
        this.$i18n.locale = this.data.question.language
      }
      if (response.sending) {
        this.data.sending = response.sending
      }
      // force update on enum component, to reset the variables when the next question is a enum type, like the last
      // and force update to set new language of the question
      this.updateAllComponents += 1
      this.$root.$emit('setLoading', false)
    })
    // set loading to view of the current user
    this.$root.$on('setLoading', (value) => {
      this.loadingWidget = value
    })

    this.$root.$on('hiddeAfterAnswer', () => {
      this.isToShow = false
    })
  },
  computed: {
    answersList () {
      const answers = this.$store.getters['answerList/getAnswersList']
      return answers
    }
  },
  // get first question to the widget and site timeout to load then
  async mounted () {
    const vm = this
    WebFont.load({
      custom: {
        families: ['Material Icons']
      },
      inactive: function () { vm.isLoadingFonts = false },
      active: function () { vm.isLoadingFonts = false },
      timeout: 30
    })
    this.isToShow = false
    let firstQuestion = ''
    this.data.timeout = this.data.timeout * 1000
    setTimeout(async () => {
      if (!this.data.sending) {
        this.data.metadata = this.checkValue(this.data.metadata) ? this.data.metadata : '{}'
        const metadata = JSON.parse(this.data.metadata)
        this.data.from = this.checkValue(this.data.from) ? this.data.from : '{}'
        Object.keys(metadata).forEach(item => {
          metadata[item] = String(metadata[item])
        })
        const parsedFrom = JSON.parse(this.data.from)
        if (parsedFrom.email && typeof parsedFrom.email === 'string') {
          parsedFrom.email = parsedFrom.email.toLowerCase()
        }
        firstQuestion = await widgetService.getFirstQuestion(this.data.surveyId, { 'metadata': metadata, 'from': parsedFrom })
        this.data.question = firstQuestion
        if (!this.data.question || !this.data.question._id) {
          this.isToShow = false
          return
        }

        if (this.data.question.language) {
          this.$i18n.locale = this.data.question.language
        }
        if (this.data.responseFirstQuestion !== undefined) {
          const formatedResponse = responsesHelper.formatWhenIsAnsweredAutomaticWithUrlParam(firstQuestion, this.data.responseFirstQuestion)
          const result = await widgetService.checkIfNextOrFirstQuestion(this.data, formatedResponse)
          const valueToSend = this.data.responseFirstQuestion
          this.$root.$emit('questionUpdated', result, valueToSend)
        }
      }
      this.isToShow = true
    }, this.data.timeout)
  },
  methods: {
    checkValue (string) {
      return (string !== '')
    },
    insertAnswer (value) {
      this.$store.commit('answerList/setAnswer', { id: this.data.question.id, value: value })
    },
    validateClickAndRules () {
      this.bindsOpenWidget = !this.bindsOpenWidget
      if (this.hidde_when_click_to_close && this.bindsOpenWidget === false) {
        this.isToShow = false
      }
    },
    replaceMeta (str) {
      if (!str) {
        return ''
      }
      const metadata = this.metadata
      let newString = ''
      if (Object.keys(metadata).length > 0) {
        newString = str.replace(/\*\|((?:(?!\|\*).)+)\|\*/g, (match, f) => {
          return metadata[f] || match
        })
      }
      const stripTags = function (string, allowed) {
        return string.replace(/<(?:.)*?>/gim, '')
      }
      let nhtml = stripTags(newString || str).replace(/\n/gi, '<br>')
      const Keycode = this.keycode
      const self = this
      Object.keys(Keycode || {}).forEach(function (key) {
        var regex = new RegExp(
          [Keycode[key].rxstart, Keycode[key].rxmid, Keycode[key].rxend].join(''),
          'gmi'
        )
        nhtml = nhtml.replace(regex, function (word) {
          if (key === 'br') {
            return word.replace(
              new RegExp(Keycode[key].rxstart + '' + Keycode[key].rxend, 'gim'),
              ['<', key, '>'].join('')
            )
          } else if (key === 'em') {
            const questionId = word.replace(new RegExp([Keycode[key].rxstart, Keycode[key].rxend].join('|'), 'gi'), '')
            const response = self.answersList.find(item => item.id === questionId)
            if (response) {
              return response.value
            }
          } else {
            var tag = word
              .replace(new RegExp(Keycode[key].rxstart), ['<', key, '>'].join(''))
              .replace(new RegExp(Keycode[key].rxend), ['</', key, '>'].join(''))
            return tag
          }
        })
      })
      return nhtml || str
    }
  }
}
</script>
<style>
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v120/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.binds-material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.binds-loading-widget {
  position: fixed;
  width: 100%;
  padding: 0 3px;
  height: 78%;
  z-index: 100;
}
.binds-loading-widget .binds-progress-bar {
  background: transparent !important;
}
.binds-loading-widget .binds-progress-bar-fill {
  background-color: darkgray !important;
}
.binds-loading-widget .binds-progress-bar-fill:after {
  background-color: darkgray !important;
}
.binds-content-widget {
  position: fixed !important;
  -webkit-transition: width .4s; /* Safari prior 6.1 */
  transition: width .4s;
  z-index: 2147483647; /** max z-index of current browsers */
}
.bindsOpenWidget {
  border:none;
  cursor: pointer;
  width: auto;
  max-width: 350px;
  display: flex;
  display: -webkit-flex;
  padding: 0px 10px;
  font-weight: bold;
  font-family: Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif;
}
.bindsOpenWidget i {
  font-size: 45px;
  margin: auto;
}
.bindsOpenWidget span {
  font-size: 1.2em;
  width: 0px;
  display: block;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bindsOpenWidget:hover  > span {
  width: 140px;
}
.binds-widget-label-block {
  height: 25px;
  width: 100% !important;
  min-width: 140px !important;
  -webkit-transition: width .8s; /* Safari prior 6.1 */
  transition: width .8s;
}
.binds-button-right {
  float: right;
  border-top-left-radius: 8px;
}
.binds-button-left {
  border-top-right-radius: 8px;
}
.binds-left {
  top: 25%;
  left: 0px;
}

.binds-left .binds-arrow-down {
  transform: rotate(90deg);
}
.binds-right {
  top: 8%;
  right: 0px;
}
.fadeInRight .binds-header-widget {
  border-top-left-radius: 8px;
}
.bindsFadeInLeft .binds-header-widget {
  border-top-right-radius: 8px;
}
.binds-right .binds-arrow-down{
    transform: rotate(-90deg);
}
.binds-close-widget{
  font-size: 15px !important;
  margin-top: 5px;
  margin-right: 5px;
  padding-left: 10px;;
}

.binds-lower-right{
  bottom:0px;
  right: 0px;
}
.binds-lower-left{
  bottom: 0px;
  left: 0px;
}
.binds-header-widget{
  padding: 20px;
}

.binds-animated {
  -webkit-animation-duration: .4s;
  animation-duration: .4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  clear: both;
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes bindsFadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bindsFadeInLeft {
  -webkit-animation-name: bindsFadeInLeft;
  animation-name: bindsFadeInLeft;
}
#bindsApp {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, 'Noto Sans', -apple-system, BlinkMacSystemFont, sans-serif;
}
</style>
