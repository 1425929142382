<template>
  <binds-field class="binds-autocomplete" :class="fieldClasses" binds-clearable :binds-inline="isBoxLayout">
    <binds-menu binds-direction="bottom-start" :binds-dense="bindsDense" binds-align-trigger binds-full-width :binds-active.sync="showMenu">
      <binds-input
        v-model="searchTerm"
        v-bind="$attrs"
        :id="bindsInputId"
        :name="bindsInputName"
        :maxlength="bindsInputMaxlength"
        :placeholder="bindsInputPlaceholder"
        @focus.stop="openOnFocus"
        @blur="hideOptions"
        @input="onInput"
        @click.stop.prevent="openOnFocus" />

      <binds-menu-content :class="contentClasses" v-show="hasScopedEmptySlot || hasFilteredItems">
        <div class="binds-autocomplete-loading" v-if="isPromisePending">
          <binds-progress-spinner :binds-diameter="40" :binds-stroke="4" binds-mode="indeterminate" />
        </div>

        <div class="binds-autocomplete-items" v-if="hasFilteredItems">
          <binds-menu-item v-for="(item, index) in getOptions()" :key="index" @click="selectItem(item, $event)">
            <slot name="binds-autocomplete-item" :item="item" :term="searchTerm" v-if="$scopedSlots['binds-autocomplete-item']" />
            <template v-else>{{ item }}</template>
          </binds-menu-item>
        </div>

        <binds-menu-item v-else-if="hasScopedEmptySlot">
          <div class="binds-autocomplete-empty">
            <slot name="binds-autocomplete-empty" :term="searchTerm" />
          </div>
        </binds-menu-item>
      </binds-menu-content>
    </binds-menu>

    <slot />
  </binds-field>
</template>

<script>
import fuzzy from 'fuzzysearch'
import isPromise from 'is-promise'
import BindsPropValidator from '../../core/utils/BindsPropValidator'

export default {
  name: 'BindsAutocomplete',
  props: {
    value: {
      type: null,
      required: true
    },
    bindsDense: Boolean,
    bindsLayout: {
      type: String,
      default: 'floating',
      ...BindsPropValidator('binds-layout', [
        'floating',
        'box'
      ])
    },
    bindsOpenOnFocus: {
      type: Boolean,
      default: true
    },
    bindsFuzzySearch: {
      type: Boolean,
      default: true
    },
    bindsOptions: {
      type: [Array, Promise],
      required: true
    },
    bindsInputName: String,
    bindsInputId: String,
    bindsInputMaxlength: [String, Number],
    bindsInputPlaceholder: [String, Number]
  },
  data () {
    return {
      searchTerm: this.value,
      showMenu: false,
      triggerPopover: false,
      isPromisePending: false,
      filteredAsyncOptions: []
    }
  },
  computed: {
    isBoxLayout () {
      return this.bindsLayout === 'box'
    },
    fieldClasses () {
      if (this.isBoxLayout) {
        return 'binds-autocomplete-box'
      }
    },
    contentClasses () {
      if (this.isBoxLayout) {
        return 'binds-autocomplete-box-content'
      }
    },
    shouldFilter () {
      return this.bindsOptions[0] && this.searchTerm
    },
    filteredStaticOptions () {
      if (this.isPromise(this.bindsOptions)) {
        return false
      }

      const firstItem = this.bindsOptions[0]

      if (this.shouldFilter) {
        if (typeof firstItem === 'string') {
          return this.filterByString()
        } else if (typeof firstItem === 'object') {
          return this.filterByObject()
        }
      }

      return this.bindsOptions
    },
    hasFilteredItems () {
      return this.filteredStaticOptions.length > 0 || this.filteredAsyncOptions.length > 0
    },
    hasScopedEmptySlot () {
      return this.$scopedSlots['binds-autocomplete-empty']
    }
  },
  watch: {
    bindsOptions: {
      deep: true,
      immediate: true,
      handler () {
        if (this.isPromise(this.bindsOptions)) {
          this.isPromisePending = true
          this.bindsOptions.then(options => {
            this.filteredAsyncOptions = options
            this.isPromisePending = false
          })
        }
      }
    },

    value (val) {
      this.searchTerm = val
    }
  },
  methods: {
    getOptions () {
      if (this.isPromise(this.bindsOptions)) {
        return this.filteredAsyncOptions
      }

      return this.filteredStaticOptions
    },
    isPromise (obj) {
      return isPromise(obj)
    },
    matchText (item) {
      const target = item.toLowerCase()
      const search = this.searchTerm.toLowerCase()

      if (this.bindsFuzzySearch) {
        return fuzzy(search, target)
      }

      return target.includes(search)
    },
    filterByString () {
      return this.bindsOptions.filter(item => this.matchText(item))
    },
    filterByObject () {
      return this.bindsOptions.filter(item => {
        const values = Object.values(item)
        const valuesCount = values.length

        for (let i = 0; i <= valuesCount; i++) {
          if (typeof values[i] === 'string' && this.matchText(values[i])) {
            return true
          }
        }
      })
    },
    openOnFocus () {
      if (this.bindsOpenOnFocus) {
        this.showOptions()
      }
    },
    onInput (value) {
      this.$emit('input', value)

      if (!this.bindsOpenOnFocus) {
        this.showOptions()
      }

      if (this.searchTerm.constructor.toString().match(/function (\w*)/)[1].toLowerCase() !== 'inputevent') {
        this.$emit('binds-changed', this.searchTerm)
      }
    },
    showOptions () {
      if (this.showMenu) {
        return false
      }

      this.showMenu = true
      this.$nextTick(() => {
        this.triggerPopover = true
        this.$emit('binds-opened')
      })
    },
    hideOptions () {
      this.$nextTick(() => {
        this.triggerPopover = false
        this.$emit('binds-closed')
      })
    },
    selectItem (item, $event) {
      const content = $event.target.textContent.trim()

      this.searchTerm = content
      this.$emit('input', item)
      this.$emit('binds-selected', item)
      this.hideOptions()
    }
  }
}
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";
  @import "../BindsLayout/mixins";

  .binds-autocomplete {
    .binds-menu {
      width: 100%;
      display: flex;
    }
  }

  .binds-autocomplete-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .binds-field.binds-inline.binds-autocomplete-box {
    @include binds-elevation(2);
    padding-top: 2px;
    border-radius: 2px;

    &.binds-focused {
      z-index: 120;
    }

    &:before,
    &:after {
      display: none;
    }

    .binds-toolbar & {
      min-height: 40px;
      height: 40px;
      margin: 0;
      box-shadow: none;
    }

    .binds-menu {
      align-items: center;
    }

    .binds-input {
      padding-left: 16px;
    }

    &.binds-focused label,
    label,
    .binds-input-action {
      top: 50%;
      transform: translateY(-50%);
    }

    .binds-input-action {
      right: 8px;
    }

    &.binds-focused label,
    label {
      margin-top: 2px;
      left: 16px;
    }
  }

  .binds-autocomplete-box-content:after {
    height: 6px;
    position: absolute;
    top: -6px;
    right: 0;
    left: 0;
    z-index: 120;
    border-bottom: 1px solid;
    content: "";
  }
</style>
