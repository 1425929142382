<template>
  <transition name="binds-progress-spinner" appear>
    <div class="binds-progress-spinner" :class="[progressClasses, $bindsActiveTheme]">
      <svg
        class="binds-progress-spinner-draw"
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        :viewBox="`0 0 ${bindsDiameter} ${bindsDiameter}`"
        ref="binds-progress-spinner-draw">
        <circle class="binds-progress-spinner-circle" cx="50%" cy="50%" :r="circleRadius" ref="binds-progress-spinner-circle"></circle>
      </svg>
    </div>
  </transition>
</template>

<script>
import BindsComponent from '../../../core/BindsComponent'
import BindsPropValidator from '../../../core/utils/BindsPropValidator'

const BindsProgressSpinner = {
  styleTag: null,
  diameters: new Set()
}

export default new BindsComponent({
  name: 'BindsProgressSpinner',
  props: {
    bindsValue: {
      type: Number,
      default: 0
    },
    bindsDiameter: {
      type: Number,
      default: 60
    },
    bindsStroke: {
      type: Number,
      default: 6
    },
    bindsMode: {
      type: String,
      default: 'determinate',
      ...BindsPropValidator('binds-mode', [
        'determinate',
        'indeterminate'
      ])
    }
  },
  computed: {
    isDeterminate () {
      return this.bindsMode === 'determinate'
    },
    isIndeterminate () {
      return this.bindsMode === 'indeterminate'
    },
    isIE () {
      if (!this.$isServer) {
        return navigator.userAgent.toLowerCase().includes('trident')
      }
      return false
    },
    progressClasses () {
      let animationClass = 'binds-progress-spinner-indeterminate'

      if (this.isIE) {
        animationClass += '-fallback'
      }

      return {
        [animationClass]: true,
        ['binds-' + this.bindsMode]: true
      }
    },
    circleRadius () {
      return (this.bindsDiameter - this.bindsStroke) / 2
    },
    circleStrokeWidth () {
      return this.bindsStroke + 'px'
    },
    circleCircumference () {
      return 2 * Math.PI * this.circleRadius
    },
    circleStrokeDashArray () {
      return this.circleCircumference + 'px'
    },
    circleStrokeDashOffset () {
      if (this.isDeterminate) {
        return this.circleCircumference * (100 - this.bindsValue) / 100 + 'px'
      }

      if (this.isIndeterminate && this.isIE) {
        return this.circleCircumference * 0.2 + 'px'
      }

      return null
    }
  },
  watch: {
    bindsValue () {
      this.attachCircleStyle()
    },
    bindsDiameter () {
      this.attachSvgStyle()
      this.attachCircleStyle()
    },
    bindsStroke () {
      this.attachCircleStyle()
    }
  },
  methods: {
    attachSvgStyle () {
      const svg = this.$refs['binds-progress-spinner-draw']
      const size = `${this.bindsDiameter}px`
      svg.style.width = size
      svg.style.height = size
    },
    attachCircleStyle () {
      const circle = this.$refs['binds-progress-spinner-circle']
      circle.style.strokeDashoffset = this.circleStrokeDashOffset
      circle.style.strokeDasharray = this.circleStrokeDashArray
      circle.style.strokeWidth = this.circleStrokeWidth
      circle.style.setProperty('--binds-progress-spinner-start-value', 0.95 * this.circleCircumference)
      circle.style.setProperty('--binds-progress-spinner-end-value', 0.2 * this.circleCircumference)
    }
  },
  mounted () {
    this.attachSvgStyle()
    this.attachCircleStyle()
  }
})
</script>

<style lang="scss">
  @import "../../BindsAnimation/variables";

  @keyframes binds-progress-spinner-rotate {
    0% {
      transform: rotate(0)
    }

    100% {
      transform: rotate(360deg)
    }
  }

  @keyframes binds-progress-spinner-initial-rotate {
    0% {
      opacity: 0;
      transform: rotate(-90deg) translateZ(0);
    }

    20% {
      opacity: 1;
    }

    100% {
      transform: rotate(270deg) translateZ(0);
    }
  }

  @keyframes binds-progress-spinner-stroke-rotate-fallback {
    0% {
      transform: rotate(0)
    }
    25% {
      transform: rotate(1170deg)
    }
    50% {
      transform: rotate(2340deg)
    }
    75% {
      transform: rotate(3510deg)
    }
    100% {
      transform: rotate(4680deg)
    }
  }

  @keyframes binds-progress-spinner-stroke-rotate {
    0% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotate(0);
    }

    12.5% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotate(0);
    }

    12.51% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(72.5deg);
    }

    25% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(72.5deg);
    }

    25.1% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotate(270deg);
    }

    37.5% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotate(270deg);
    }

    37.51% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(161.5deg);
    }

    50% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(161.5deg);
    }

    50.01% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotate(180deg);
    }

    62.5% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotate(180deg);
    }

    62.51% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(251.5deg);
    }

    75% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(251.5deg);
    }

    75.01% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotate(90deg);
    }

    87.5% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotate(90deg);
    }

    87.51% {
      stroke-dashoffset: var(--binds-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(341.5deg);
    }

    100% {
      stroke-dashoffset: var(--binds-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(341.5deg);
    }
  }

  .binds-progress-spinner {
    display: inline-flex;
    position: relative;

    &.binds-indeterminate {
      animation: binds-progress-spinner-rotate 2s linear infinite;

      &.binds-progress-spinner-enter,
      &.binds-progress-spinner-leave-to {
        .binds-progress-spinner-draw {
          opacity: 0;
          transform: scale(.1);
        }
      }

      &.binds-progress-spinner-enter-active,
      &.binds-progress-spinner-leave-active {
        transition-duration: .4s;
        animation: none;
      }

      .binds-progress-spinner-circle {
        animation: 4s infinite $binds-transition-stand-timing;
        animation-name: binds-progress-spinner-stroke-rotate;
      }
    }

    &.binds-determinate {
      &.binds-progress-spinner-enter-active {
        transition-duration: 2s;

        .binds-progress-spinner-draw {
          animation: binds-progress-spinner-initial-rotate 1.98s $binds-transition-stand-timing forwards;
        }
      }

      &.binds-progress-spinner-leave-active {
        transition-duration: 2s;

        .binds-progress-spinner-draw {
          animation: binds-progress-spinner-initial-rotate reverse 1.98s $binds-transition-stand-timing forwards;
        }
      }

      .binds-progress-spinner-draw {
        transition: none;
      }
    }
  }

  .binds-progress-spinner-draw {
    overflow: visible;
    transform: scale(1) rotate(-90deg);
    transform-origin: center;
    transition: .4s $binds-transition-stand-timing;
    will-change: opacity, transform;
  }

  .binds-progress-spinner-circle {
    fill: none;
    transform-origin: center;
    transition: stroke-dashoffset .25s $binds-transition-stand-timing;
    will-change: stroke-dashoffset, stroke-dasharray, stroke-width, animation-name, r;
  }
</style>
