<template>
  <div class="text-component">
    <div :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget" >
        <div class="animated-faster zoomIn">
        <div class="content-input-text" v-if="data.question.mask === null">
        <binds-field>
            <label>{{data.question.placeholder}}</label>
            <binds-textarea v-model="message"></binds-textarea>
        </binds-field>
        </div>

        <div class="content-input-text" v-if="data.question.mask !== null">
            <binds-field>
            <label>{{data.question.placeholder}}</label>
            <binds-input v-model="message" required></binds-input>
            </binds-field>
        </div>
        </div>
        <div class="submit-options-matrix">
        <binds-button
            v-if="(data.question.required && message) || (!data.question.required)"
            :binds-ripple="false"
            class="binds-raised binds-primary"
            :style="{'background-color': data.background, 'color': data.texts}"
            v-on:click="save(message)">{{data.question.nextLabel}}</binds-button>
        </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { BindsRadio, BindsCard, BindsContent, BindsButton, BindsField } from '../bindsMaterial/components'
import { widgetService } from '@/_services'

Vue.use(BindsRadio)
Vue.use(BindsCard)
Vue.use(BindsContent)
Vue.use(BindsButton)
Vue.use(BindsField)

export default {
  props: ['data'],
  data () {
    return {
      message: ''
    }
  },
  methods: {
    async save (message) {
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, { 'text': message, rating: 100 })
        this.$root.$emit('questionUpdated', questionUpdated, message)
        this.$root.$emit('setLoading', false)
        this.message = ''
      }
    }
  }
}
</script>

<style>
.content-input-text{
    padding: 15px;
}
.content-input-text .binds-input{
  text-indent: 12px;
}

</style>
