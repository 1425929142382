import material from '../../../material.js'
import BindsSelect from './BindsSelect'
import BindsOption from './BindsOption'
import BindsOptgroup from './BindsOptgroup'

export default Vue => {
  material(Vue)
  Vue.component(BindsSelect.name, BindsSelect)
  Vue.component(BindsOption.name, BindsOption)
  Vue.component(BindsOptgroup.name, BindsOptgroup)
}
