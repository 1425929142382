<template>
  <div class="binds-card-header-text">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsCardHeaderText',
  data: () => ({
    parentClasses: null
  }),
  mounted () {
    this.parentClasses = this.$parent.$el.classList

    if (this.parentClasses.contains('binds-card-header')) {
      this.parentClasses.add('binds-card-header-flex')
    }
  },
  beforeDestroy () {
    this.parentClasses.remove('binds-card-header-flex')
  }
}
</script>
