<template>
<div>
    <div :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget">
      <!-- UI 5emo -->
      <div v-if="data.question.ui === '5emo'" class="content-5num " >
          <binds-card binds-with-hover v-for="(item,index) in data5emo" v-on:click.native="postResponse(item.rating)" class="animated-faster zoomIn" v-bind:key="index">

              <binds-card-content>
                <div class="data5emo" >
                  <i :class="item.icon" :style="{'color':item.color}"></i>
                  <span>{{item.label}}</span>
                </div>
              </binds-card-content>

          </binds-card>
      </div>

      <!-- UI ynm -->
        <div class="wid-buttons binds-alignment-horizontal-center animated-faster zoomIn" v-if="data.question.ui === 'yn'">
          <div>
            <binds-button :binds-ripple="false" class="binds-raised yes" v-on:click.native="postResponse(100)">{{$t('op1')}}</binds-button>
          </div>
          <div>
            <binds-button :binds-ripple="false" class="binds-raised no" v-on:click.native="postResponse(0)">{{$t('op2')}}</binds-button>
          </div>
        </div>

      <!-- UI ynm -->
        <div class="wid-buttons binds-alignment-horizontal-center animated-faster zoomIn" v-if="data.question.ui === 'ynd'">
          <div>
            <binds-button :binds-ripple="false" class="binds-raised yes" v-on:click.native="postResponse(100)">{{$t('op1')}}</binds-button>
          </div>
          <div>
            <binds-button :binds-ripple="false" class="binds-raised maybe" v-on:click.native="postResponse(50)">{{$t('op3')}}</binds-button>
          </div>
          <div>
            <binds-button :binds-ripple="false" class="binds-raised no" v-on:click.native="postResponse(0)">{{$t('op2')}}</binds-button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BindsRadio, BindsCard, BindsContent, BindsButton, BindsRipple } from '../bindsMaterial/components'

import { widgetService } from '@/_services'

Vue.use(BindsRadio)
Vue.use(BindsCard)
Vue.use(BindsContent)
Vue.use(BindsButton)
Vue.use(BindsRipple)
export default {
  props: ['data'],
  data () {
    return {
      buttonEnabled: true,
      data5emo: [
        { icon: 'icon-joy-gee', label: this.$t('ex'), rating: 100, color: '#49BB59' },
        { icon: 'icon-happy-gee', label: this.$t('vg'), rating: 75, color: '#37B0E4' },
        { icon: 'icon-neutral-sleep', label: this.$t('satisf'), rating: 50, color: '#9E9E9E' },
        { icon: 'icon-unhappy-sleep', label: this.$t('bd'), rating: 25, color: '#F26C3C' },
        { icon: 'icon-wow-open', label: this.$t('vb'), rating: 0, color: '#EE2E35' }
      ]
    }
  },
  methods: {
    async postResponse (rating) {
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, { 'label': rating, 'rating': rating })
        this.$root.$emit('questionUpdated', questionUpdated, rating)
      }
    }
  },
  mounted () {
    if (this.data.question.required === true) {
      this.buttonEnabled = false
    }
    this.$root.$emit('setLoading', false)
  }
}
</script>
<style>
  .content-5num {
    padding: 20px;
  }
  .data5emo {
    width: 52%;
    margin: 0 auto;
    display: flex;
  }
  .data5emo i {
    display: inline-block;
    font-size: 20px;
    float: left;
  }
  .data5emo span {
    margin-left: 4%;
    display: inline-block;
    font-size: 16px;
  }
  .content-5num .binds-card {
    margin-bottom: 10px;
  }
</style>
