<template>
  <div class="text-component">
    <div :style="{
      'border-color': data.background,
      'width': data.width + data.widthMetric
      }" style="border-style: solid; border-width: 4px" class="responsive-widget" >

      <div class="animated-faster zoomIn">
        <form  @submit.prevent="saveReferral($event, entry)" v-if="data.question.ui === 'referral'">
          <div class="content-input-text" v-if="data.question.referral.fields.name.active">
              <binds-field>
              <label>{{$t('name')}}</label>
              <binds-input v-model="entry.name"
              :placeholder="data.question.referral.fields.name.placeholder"
              :required="data.question.referral.fields.name.required"></binds-input>
              </binds-field>
          </div>
          <div class="content-input-text" v-if="data.question.referral.fields.email.active">
              <binds-field>
              <label>{{$t('email')}}</label>
              <binds-input
              v-model="entry.email"
              :placeholder="data.question.referral.fields.email.placeholder"
              :required="data.question.referral.fields.email.required"></binds-input>
              </binds-field>
          </div>
          <div class="content-input-text"  v-if="data.question.referral.fields.phone.active">
              <binds-field>
              <label>{{$t('phone')}}</label>
              <binds-input
              v-model="entry.phone"
              :placeholder="data.question.referral.fields.phone.placeholder"
              :required="data.question.referral.fields.phone.required"></binds-input>
              </binds-field>
          </div>
          <div class="submit-options-matrix">
            <binds-button
                :binds-ripple="false"
                class="binds-raised binds-primary"
                type="submit"
                :style="{'background-color': data.background, 'color': data.texts}"
              >{{data.question.nextLabel}}</binds-button>
          </div>
        </form>
        <form  v-if="data.question.ui === 'referralDynamic'" v-on:submit="saveReferral($event, null)">
          <div class="content-input-text" v-for="(field, index) in data.question.referral.dynamicFields" v-bind:key="index">
            <binds-field v-if="field.active">
              <label>{{field.placeholder}}</label>
              <binds-input v-model="field.label"
              :required="field.required"></binds-input>
              </binds-field>
          </div>
          <div class="submit-options-matrix">
            <binds-button
                :binds-ripple="false"
                class="binds-raised binds-primary"
                type="submit"
                :style="{'background-color': data.background, 'color': data.texts}"
              >{{data.question.nextLabel}}</binds-button>
          </div>
        </form>
        <form v-if="!~['referralDynamic', 'referral'].indexOf(data.question.ui)" @submit.prevent="saveReferral($event, entrySingle)">
          <div class="content-input-text">
              <binds-field>
              <label>Nome</label>
              <binds-input v-model="entrySingle" required></binds-input>
              </binds-field>
          </div>
          <div class="submit-options-matrix">
            <binds-button
                :binds-ripple="false"
                class="binds-raised binds-primary"
                type="submit"
                :style="{'background-color': data.background, 'color': data.texts}"
              >{{data.question.nextLabel}}</binds-button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { BindsRadio, BindsCard, BindsContent, BindsButton, BindsField } from '../bindsMaterial/components'
import { widgetService } from '@/_services'

Vue.use(BindsRadio)
Vue.use(BindsCard)
Vue.use(BindsContent)
Vue.use(BindsButton)
Vue.use(BindsField)

export default {
  props: ['data'],
  data () {
    return {
      entry: {},
      entrySingle: '',
      entryDynamic: {}
    }
  },
  methods: {
    validateRequired (question, referralEntries) {
      if (question.referral.dynamicFields) {
        question.referral.dynamicFields.forEach(field => {
          referralEntries[field.key] = field.label
        })
      }
      return referralEntries
    },
    async saveReferral (event, message) {
      event.preventDefault()
      let referralEntries = {
        0: message
      }
      this.validateRequired(this.data.question, referralEntries)
      if (this.data.isToPost) {
        this.$root.$emit('setLoading', true)
        if (this.data.question.ui === 'referral') {
          referralEntries = Object.keys(message).length ? [message] : []
        }
        let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, { 'referralEntries': referralEntries, rating: 100, question: this.data.question._id })

        this.$root.$emit('questionUpdated', questionUpdated)
        this.$root.$emit('setLoading', false)
        this.entry = {}
        this.message = ''
      }
    }
  },
  created () {
    console.log(this.data.question)
  }
}
</script>

<style>
.content-input-text{
  padding: 5px 15px 0px 15px;
}
.content-input-text .binds-input{
  text-indent: 12px;
}

</style>
