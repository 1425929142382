<template>
  <div class="binds-card-area" :class="areaClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BindsCardArea',
  props: {
    bindsInset: Boolean
  },
  computed: {
    areaClasses () {
      return {
        'binds-inset': this.bindsInset
      }
    }
  }
}
</script>

<style lang="scss">
  .binds-card-area {
    position: relative;
  }

  .binds-card {
    > .binds-card-area:not(:last-child) {
      position: relative;

      &:after {
        height: 1px;
        position: absolute;
        bottom: 0;
        content: " ";
      }

      &:not(.binds-inset):after {
        right: 0;
        left: 0;
      }

      &.binds-inset:after {
        right: 16px;
        left: 16px;
      }
    }
  }
</style>
