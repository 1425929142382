<template>
  <div class="binds-bottom-bar" :class="[$bindsActiveTheme, barClasses]">
    <binds-ripple :binds-disabled="bindsType === 'fixed'" :binds-active="BindsBottomBar.mouseEvent">
      <slot />
    </binds-ripple>
  </div>
</template>

<script>
  import BindsComponent from '../../core/BindsComponent'
  import BindsPropValidator from '../../core/utils/BindsPropValidator'
  import BindsRipple from '../BindsRipple/BindsRipple'

  export default new BindsComponent({
    name: 'BindsBottomBar',
    components: {
      BindsRipple
    },
    props: {
      bindsSyncRoute: Boolean,
      bindsActiveItem: [String, Number],
      bindsType: {
        type: String,
        default: 'fixed',
        ...BindsPropValidator('binds-type', ['fixed', 'shift'])
      }
    },
    data () {
      return {
        BindsBottomBar: {
          mouseEvent: null,
          activeItem: null,
          items: {},
          syncRoute: this.bindsSyncRoute
        }
      }
    },
    provide () {
      return {
        BindsBottomBar: this.BindsBottomBar
      }
    },
    computed: {
      activeItem () {
        return this.BindsBottomBar.activeItem
      },
      barClasses () {
        return {
          ['binds-type-' + this.bindsType]: true
        }
      }
    },
    watch: {
      activeItem () {
        this.$emit('binds-changed', this.activeItem)
      },
      bindsSyncRoute () {
        this.BindsBottomBar.syncRoute = bindsSyncRoute
      }
    },
    methods: {
      hasActiveItem () {
        return this.BindsBottomBar.activeItem || this.bindsActiveItem
      },
      getItemsAndKeys () {
        const items = this.BindsBottomBar.items

        return {
          items,
          keys: Object.keys(items)
        }
      },
      setActiveItemByIndex (index) {
        const { keys } = this.getItemsAndKeys()

        if (!this.bindsActiveItem) {
          this.BindsBottomBar.activeItem = keys[index]
        } else {
          this.BindsBottomBar.activeItem = this.bindsActiveItem
        }
      }
    },
    created () {
      this.BindsBottomBar.type = this.bindsType
    },
    mounted () {
      this.$nextTick().then(() => {
        if (!this.bindsSyncRoute) {
          this.setActiveItemByIndex(0)
        }
      })

    }
  })
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";
  @import "../BindsElevation/mixins";

  .binds-bottom-bar {
    @include binds-elevation(8);
    width: 100%;
    transition: background-color .5s $binds-transition-default-timing;

    > .binds-ripple {
      display: flex;
      flex-wrap: wrap;
    }

    &.binds-type-fixed {
      justify-content: center;

      .binds-bottom-bar-item {
        min-width: 80px;
        max-width: 168px;
        transition: $binds-transition-default;
        transition-property: color;
        will-change: color;

        .binds-bottom-bar-label {
          transform: scale(.8571) translate3D(0, 4px, 0);
        }

        &.binds-active {
          .binds-ripple {
            padding-top: 6px;
          }

          .binds-bottom-bar-icon {
            transform: translate3d(0, -2px, 0);
          }

          .binds-bottom-bar-label {
            transform: translate3D(0, 3px, 0);
          }
        }
      }
    }

    &.binds-type-shift {
      justify-content: center;

      > .binds-ripple {
        .binds-ripple-enter-active {
          transition-duration: 1.1s !important;
        }

        .binds-ripple-enter {
          opacity: 1;
        }
      }

      .binds-bottom-bar-item {
        min-width: 56px;
        max-width: 96px;
        flex: 1 1 32px;
        transition: .3s $binds-transition-default-timing;
        transition-property: padding, min-width, max-width, flex, color;
        will-change: padding, min-width, max-width, flex, color;

        .binds-ripple {
          padding: 16px;
        }

        .binds-bottom-bar-icon {
          transform: translate3d(0, 8px, 0);
        }

        .binds-bottom-bar-label {
          opacity: 0;
          transform: scale(.7) translate3d(0, 6px, 0);
        }

        &.binds-active {
          min-width: 96px;
          max-width: 168px;
          flex: 1 1 72px;

          .binds-ripple {
            padding: 6px 0 10px;
          }

          .binds-bottom-bar-icon {
            transform: translate3d(0, 0, 0);
          }

          .binds-bottom-bar-label {
            opacity: 1;
            transform: translate3d(0, 3px, 0);
          }
        }
      }
    }

    .binds-bottom-bar-item {
      height: 56px;
      margin: 0;
      flex: 1;
      cursor: pointer;
      border-radius: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1em;
      text-transform: none;

      .binds-ripple {
        padding: 8px 12px 10px;
        transition: padding .3s $binds-transition-stand-timing;
        will-change: padding;
      }

      .binds-button-content {
        position: static;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .binds-bottom-bar-icon,
      .binds-bottom-bar-label {
        transition: .3s $binds-transition-default-timing;
        transition-property: transform, opacity;
        will-change: transform, opacity;
      }
    }
  }
</style>
