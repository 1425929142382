<template>
      <binds-card-content>
        <div class="animated-faster zoomIn">
          <div class="binds-layout align-buttons">
            <div class="responsiveAlign" v-for="(item, index) in data.question.options" v-bind:key="index">
              <binds-button :binds-ripple="false" v-on:click="postResponse(item)" :style="returnStyle(index)"
                :value="item.rating ? item.rating : item.label">
                {{item.label}}
                </binds-button>
            </div>
          </div>
          <div class="binds-layout binds-gutter">
            <div class="binds-layout-item">
              <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.startLabel}}</span>
            </div>
            <div class="binds-layout-item alignSpecialLabels">
            <span class="binds-caption" :style="{color: data.question.gradient ? 'black' : data.background}">{{data.question.endLabel}}</span>
            </div>
          </div>
        </div>
      </binds-card-content>
</template>

<script>
import Vue from 'vue'
import { BindsContent, BindsTabs, BindsButton, BindsCard } from './bindsMaterial/components'
import { widgetService } from '@/_services'
import { formatButton } from '@/_helpers'

Vue.use(BindsContent)
Vue.use(BindsTabs)
Vue.use(BindsButton)
Vue.use(BindsCard)

export default {
  props: ['data'],
  methods: {
    async postResponse (item) {
      this.$root.$emit('setLoading', true)
      let objectToSend = item
      if (this.data.question.type === 'enum') {
        objectToSend = { value: item.label, text: '' }
      }
      let questionUpdated = await widgetService.checkIfNextOrFirstQuestion(this.data, objectToSend)
      this.$root.$emit('questionUpdated', questionUpdated, item.label)
      this.$root.$emit('setLoading', false)
    },
    returnStyle (index) {
      return formatButton.returnStyleWithGradient(this.data, index)
    }
  },
  created () {
    this.$root.$emit('setLoading', false)
  }
}
</script>
