var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isToShow && !_vm.isLoadingFonts)?_c('div',[_c('div',{staticClass:"binds-content-widget",class:{
        'binds-right': _vm.data.widgetPosition === 'right',
        'binds-left': _vm.data.widgetPosition === 'left',
        'binds-lower-left': _vm.data.widgetPosition === 'lowerLeft',
        'binds-lower-right': _vm.data.widgetPosition === 'lowerRight'
      }},[_c('button',{staticClass:"bindsOpenWidget binds-elevation-5",class:{
      'binds-animated': _vm.bindsOpenWidget,
      'binds-button-right' : _vm.data.widgetPosition === 'right' || _vm.data.widgetPosition === 'lowerRight',
      'binds-button-left' : _vm.data.widgetPosition === 'left' || _vm.data.widgetPosition === 'lowerLeft',
      },style:({'background-color': _vm.background}),on:{"click":function($event){return _vm.validateClickAndRules()}}},[_c('span',{class:{ 'binds-widget-label-block': _vm.bindsOpenWidget },style:({'color':_vm.texts})},[_vm._v(_vm._s(_vm.data.widgetTitle))]),(_vm.bindsOpenWidget)?_c('i',{staticClass:"binds-material-icons binds-close-widget",style:({'color':_vm.texts})},[_vm._v("close")]):_vm._e(),(!_vm.bindsOpenWidget)?_c('i',{staticClass:"binds-material-icons binds-arrow-down",style:({'color':_vm.texts})},[_vm._v("arrow_drop_up")]):_vm._e()]),(_vm.bindsOpenWidget)?_c('div',{class:{
      'binds-animated': _vm.bindsOpenWidget,
      'bindsFadeInLeft' : _vm.data.widgetPosition === 'left' || _vm.data.widgetPosition === 'lowerLeft',
      'fadeInRight' : _vm.data.widgetPosition === 'right' || _vm.data.widgetPosition === 'lowerRight',
      },on:{"questionUpdated":function($event){return _vm.questionUpdated($event.target.value)}}},[(!_vm.data.endMessage)?_c('div',{staticClass:"binds-header-widget binds-elevation-10",style:({'background-color': _vm.background,  'width': _vm.data.width + _vm.data.widthMetric,})},[_c('div',{staticClass:"binds-title",style:({color: _vm.texts}),domProps:{"innerHTML":_vm._s(_vm.replaceMeta(_vm.data.question.label))}}),_c('div',{staticClass:"binds-caption",style:({color: _vm.texts}),domProps:{"innerHTML":_vm._s(_vm.replaceMeta(_vm.data.question.question))}})]):_vm._e(),_c('div',{key:_vm.updateAllComponents,staticClass:"answers-type",staticStyle:{"background-color":"#fff"}},[(_vm.loadingWidget)?_c('div',{staticClass:"binds-loading-widget"},[_c('binds-progress-bar',{attrs:{"binds-mode":"indeterminate"}})],1):_vm._e(),(_vm.data.question.type === 'csat' && _vm.data.question)?_c('Csat',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'nvs' && _vm.data.question)?_c('Nvs',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'matrix' && _vm.data.question.subjects)?_c('Matrix',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'enum' && _vm.data.question)?_c('Enum',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'nes'&& _vm.data.question)?_c('Nes',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'ces'&& _vm.data.question)?_c('Ces',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'nps' && _vm.data.question)?_c('Nps',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'kpi' && _vm.data.question)?_c('Kpi',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'text' && _vm.data.question)?_c('TextInput',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.endMessage)?_c('EndMessage',{attrs:{"data":_vm.data}}):_vm._e(),(_vm.data.question.type === 'referral' && _vm.data.question)?_c('Referral',{attrs:{"data":_vm.data}}):_vm._e()],1)]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }