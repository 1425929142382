<template>
  <div
    :class="['binds-ripple', rippleClasses]"
    @touchstart.passive="event => bindsEventTrigger && touchStartCheck(event)"
    @touchmove.passive="event => bindsEventTrigger && touchMoveCheck(event)"
    @mousedown.passive="event => bindsEventTrigger && startRipple(event)">
    <slot />
    <div v-if="!isDisabled">
      <binds-wave v-for="ripple in ripples" :key="ripple.uuid" :class="['binds-ripple-wave', waveClasses]" :style="ripple.waveStyles" @binds-end="clearWave(ripple.uuid)"/>
    </div>
  </div>
</template>

<script>
import raf from 'raf'
import BindsComponent from '../../core/BindsComponent'
import uuid from '../../core/utils/BindsUuid'
import BindsWave from './BindsWave'

export default new BindsComponent({
  name: 'BindsRipple',
  components: {
    BindsWave
  },
  props: {
    bindsActive: null,
    bindsDisabled: Boolean,
    bindsCentered: Boolean,
    bindsEventTrigger: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    ripples: [],
    touchTimeout: null,
    eventType: null
  }),
  computed: {
    isDisabled () {
      return !this.$material.ripple || this.bindsDisabled
    },
    rippleClasses () {
      return {
        'binds-disabled': this.isDisabled
      }
    },
    waveClasses () {
      return {
        'binds-centered': this.bindsCentered
      }
    }
  },
  watch: {
    bindsActive (active) {
      const isBoolean = typeof active === 'boolean'
      const isEvent = active.constructor.toString().match(/function (\w*)/)[1].toLowerCase() === 'mouseevent'

      if (isBoolean && this.bindsCentered && active) {
        this.startRipple({
          type: 'mousedown'
        })
      } else if (isEvent) {
        this.startRipple(active)
      }

      this.$emit('update:bindsActive', false)
    }
  },
  methods: {
    touchMoveCheck () {
      window.clearTimeout(this.touchTimeout)
    },
    touchStartCheck ($event) {
      this.touchTimeout = window.setTimeout(() => {
        this.startRipple($event)
      }, 100)
    },
    startRipple ($event) {
      raf(() => {
        const { eventType, isDisabled, bindsCentered } = this

        if (!isDisabled && (!eventType || eventType === $event.type)) {
          let size = this.getSize()
          let position = null

          if (bindsCentered) {
            position = this.getCenteredPosition(size)
          } else {
            position = this.getHitPosition($event, size)
          }

          this.eventType = $event.type
          this.ripples.push({
            waveStyles: this.applyStyles(position, size),
            uuid: uuid()
          })
        }
      })
    },
    applyStyles (position, size) {
      size += 'px'

      return {
        ...position,
        width: size,
        height: size
      }
    },
    clearWave (uuid) {
      uuid ? this.ripples = this.ripples.filter(ripple => ripple.uuid !== uuid) : this.ripples = []
    },
    getSize () {
      const { offsetWidth, offsetHeight } = this.$el

      return Math.round(Math.max(offsetWidth, offsetHeight))
    },
    getCenteredPosition (size) {
      const halfSize = -size / 2 + 'px'

      return {
        'margin-top': halfSize,
        'margin-left': halfSize
      }
    },
    getHitPosition ($event, elementSize) {
      const rect = this.$el.getBoundingClientRect()
      let top = $event.pageY
      let left = $event.pageX

      if ($event.type === 'touchstart') {
        top = $event.changedTouches[0].pageY
        left = $event.changedTouches[0].pageX
      }

      return {
        top: top - rect.top - elementSize / 2 - document.documentElement.scrollTop + 'px',
        left: left - rect.left - elementSize / 2 - document.documentElement.scrollLeft + 'px'
      }
    }
  }
})
</script>

<style lang="scss">
  @import "../BindsAnimation/variables";

  .binds-ripple {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    overflow: hidden;
    mask-image: radial-gradient(circle, #fff 100%, #000 100%);
    -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 100%);
  }

  .binds-ripple-wave {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    background: currentColor;
    border-radius: 50%;
    opacity: 0;
    transform: scale(2) translateZ(0);

    &.binds-centered {
      animation-duration: 1.2s;
      top: 50%;
      left: 50%;
    }
    ~ *:not(.binds-ripple-wave) {
      position: relative;
      z-index: 2;
    }
  }
</style>
