import BindsRipple from '../../../core/mixins/BindsRipple/BindsRipple'
import BindsListItemContent from './BindsListItemContent'

export default {
  mixins: [BindsRipple],
  components: {
    BindsListItemContent
  },
  props: {
    disabled: Boolean
  },
  computed: {
    isDisabled () {
      return !this.bindsRipple || this.disabled
    }
  }
}
