<template>
  <binds-dialog v-bind="$attrs" :binds-fullscreen="false" @binds-opened="setInputFocus">
    <binds-dialog-title v-if="bindsTitle">{{ bindsTitle }}</binds-dialog-title>
    <binds-dialog-content v-if="bindsContent" v-html="bindsContent" />

    <binds-dialog-content>
      <binds-field>
        <binds-input
          ref="input"
          v-model="inputValue"
          :id="bindsInputId"
          :name="bindsInputName"
          :maxlength="bindsInputMaxlength"
          :placeholder="bindsInputPlaceholder"
          @keydown.enter.native="onConfirm" />
      </binds-field>
    </binds-dialog-content>

    <binds-dialog-actions>
      <binds-button class="binds-primary" @click="onCancel">{{ bindsCancelText }}</binds-button>
      <binds-button class="binds-primary" @click="onConfirm">{{ bindsConfirmText }}</binds-button>
    </binds-dialog-actions>
  </binds-dialog>
</template>

<script>
export default {
  name: 'BindsDialogPrompt',
  props: {
    value: {},
    bindsTitle: String,
    bindsInputName: String,
    bindsInputId: String,
    bindsInputMaxlength: [String, Number],
    bindsInputPlaceholder: [String, Number],
    bindsContent: String,
    bindsConfirmText: {
      type: String,
      default: 'Ok'
    },
    bindsCancelText: {
      type: String,
      default: 'Cancel'
    }
  },
  data: () => ({
    inputValue: null
  }),
  watch: {
    value () {
      this.inputValue = this.value
    }
  },
  methods: {
    onCancel () {
      this.$emit('binds-cancel')
      this.$emit('update:bindsActive', false)
    },
    onConfirm () {
      this.$emit('input', this.inputValue)
      this.$emit('binds-confirm', this.inputValue)
      this.$emit('update:bindsActive', false)
    },
    setInputFocus () {
      window.setTimeout(() => {
        this.$refs.input.$el.focus()
      }, 50)
    }
  },
  created () {
    this.inputValue = this.value
  }
}
</script>
