<template>
  <input
    class="binds-input"
    v-model="model"
    v-bind="attributes"
    v-on="listeners"
    :placeholder="placeholder"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script>
import BindsComponent from '../../../core/BindsComponent'
import BindsUuid from '../../../core/utils/BindsUuid'
import BindsFieldMixin from '../BindsFieldMixin'

export default new BindsComponent({
  name: 'BindsInput',
  mixins: [BindsFieldMixin],
  inject: ['BindsField'],
  props: {
    id: {
      type: String,
      default: () => 'binds-input-' + BindsUuid()
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    toggleType () {
      return this.BindsField.togglePassword
    },
    isPassword () {
      return this.type === 'password'
    },
    listeners () {
      var l = { ...this.$listeners }
      delete l.input
      return l
    }
  },
  watch: {
    type (type) {
      this.setPassword(this.isPassword)
    },
    toggleType (toggle) {
      if (toggle) {
        this.setTypeText()
      } else {
        this.setTypePassword()
      }
    }
  },
  methods: {
    setPassword (state) {
      this.BindsField.password = state
      this.BindsField.togglePassword = false
    },
    setTypePassword () {
      this.$el.type = 'password'
    },
    setTypeText () {
      this.$el.type = 'text'
    }
  },
  created () {
    this.setPassword(this.isPassword)
  },
  beforeDestroy () {
    this.setPassword(false)
  }
})
</script>
