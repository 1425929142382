<template>
  <li class="binds-divider" :class="[$bindsActiveTheme]" v-if="insideList"></li>
  <hr class="binds-divider" :class="[$bindsActiveTheme]" v-else />
</template>

<script>
import BindsComponent from "../../core/BindsComponent";

export default new BindsComponent({
  name: "BindsDivider",
  computed: {
    insideList() {
      return this.$parent.$options._componentTag === "binds-list";
    }
  }
});
</script>

<style lang="scss">
@import "../BindsAnimation/variables";

.binds-divider {
  height: 1px;
  margin: 0;
  padding: 0;
  display: block;
  border: 0;
  transition: margin-left 0.3s $binds-transition-default-timing;
  will-change: margin-left;

  &.binds-inset {
    margin-left: 72px;
  }
}
</style>
